import { Alert, Box, Button, Card, Collapse, Container, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { useNavigate } from 'react-router';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CustomModal from '../Utils/CustomModal';

export default function MFASignIn() {
    const axios = AxiosCustomConfig();
    const navigate = useNavigate();
    const accessToken = localStorage.getItem('accessToken');
    const mfaToken = localStorage.getItem('mfaToken');
    

    const [showProgress, setShowProgress] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [openReSendOTPModal, setOpenReSendOTPModal] = useState(false);
    const [modalReSendMessage, setModalReSendMessage] = useState('');

    const handleCloseDialog = () => {
        setIsDialogOpen(false);

        if (modalMessage === "OTP is Expired") {
            navigate('/');

            localStorage.removeItem('accessToken');
            localStorage.removeItem('mfaToken');
            window.location.reload();
        }
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
        const otp = e.currentTarget.mfa_code.value;

        if (otp === "") {
            setModalMessage("Please Enter Verification Code");
            setIsDialogOpen(true);
            return false;
        }

        setShowProgress(true);

        axios({
            url: 'ApiUsers/validateMFA.json',
            method: 'POST',
            data: {
                otp: otp,
                accessToken: accessToken,
                mfaToken: mfaToken
            },
        }).then((res) => {
            const response = res.data;
            const is_success = response.is_success;
            const accessToken = response.token;
            const message = response.message;

            setShowProgress(false);

            if (is_success)  {
                localStorage.setItem('token', accessToken);
                localStorage.removeItem('accessToken');
                localStorage.removeItem('mfaToken');

                navigate('/dashboard');
            } else {
                if (message === "OTP is Expired") {
                    setModalReSendMessage(response.message);
                    setOpenReSendOTPModal(true);
                } else {
                    setModalMessage(response.message);
                    setIsDialogOpen(true);
                }
            }
        }).catch((err) => {
            setShowProgress(false);

            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        setModalMessage(err.response.data.message);
                        setIsDialogOpen(true);                        
                    }
                }
            }
        })
    }

    const handleResendOTP = () => {
        localStorage.removeItem('mfaToken');

        setShowProgress(true);

        axios({
            url: 'ApiUsers/reSendOTP.json',
            method: 'POST',
        }).then((res) => {
            const response = res.data;
            const mfaToken = response.mfa_token.token;
            
            localStorage.setItem('mfaToken', mfaToken);

            setModalMessage(response.message);
            setIsDialogOpen(true);
            setShowProgress(false);
            setOpenReSendOTPModal(false);
        }).catch((err) => {
            setShowProgress(false);

            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        setModalMessage(err.response.data.message);
                        setIsDialogOpen(true);                        
                    }
                }
            }
        })
    }

    const handleCloseReSendOTPModal = () => {
        setOpenReSendOTPModal(false);
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
                <Card 
                    variant="outlined"
                    sx={{p:5, textAlign: 'center'}}>
                    <Typography component="h4" variant='h4'>OTP Verification</Typography>
                    <form noValidate onSubmit={onFormSubmit}>
                        <TextField 
                            id="mfa_code"
                            label="Verification Code:"
                            name="mfa_code"
                            fullWidth
                            margin="normal"
                            required
                            placeholder="Please enter Verification Code" 
                        />
                        <Button
                            type="submit"
                            fullWidth
                            color="primary"
                            variant="contained"
                        >
                            Submit
                        </Button>
                    </form>
                </Card>
            </Box>
            <ProcessDialog
                setShowProgress={showProgress}
                ContentText="Checking Credentials...."
            />
            <MessageDialog
                open={isDialogOpen}
                message={modalMessage}
                handleClose={handleCloseDialog}
            />
            <CustomModal
                open={openReSendOTPModal}
                handleClose={handleCloseReSendOTPModal}
                contentText={modalReSendMessage}
                isForm={false}
                onSubmit={handleResendOTP}
                submitText="Resend OTP"
                maxWidth={'lg'}
            />
        </Container>
    )
}
