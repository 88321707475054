import { Button, useTheme } from '@mui/material'
import React from 'react'

function ViewAssetBtn({assetsData}) {
  const theme = useTheme();
  
  return (
    <div>
      <Button 
        variant="outlined" 
        size="small" 
        sx={{color: theme.palette.secondary.dark, marginRight: '10px'}} 
        onClick={() => { window.location.href = `/asset/${assetsData.id}`; }}
      >
      View
      </Button>
    </div>
  )
}

export default ViewAssetBtn