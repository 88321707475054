import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Unauthorized = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <Container style={{ textAlign: 'center', marginTop: '50px' }}>
      <Typography variant="h2" component="h1" gutterBottom>
        Unauthorized
      </Typography>
      <Typography variant="body1" gutterBottom>
        You do not have access to the requested page.
      </Typography>
      <Button variant="contained" color="secondary" onClick={goBack}>
        Go Back
      </Button>
    </Container>
  );
};

export default Unauthorized;