import React, {useState }  from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import useAuth from '../../hooks/UseAuth'

const defaultTheme = createTheme();

export default function SignIn() {
    const {setAuth} = useAuth();
    const axios = AxiosCustomConfig();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/dashboard";
    const [user,setUser] = useState({username:'', password:''})
    const [showProgress,setShowProgress] = useState(false)
    const [openMessageDialog,setOpenMessageDialog] = useState(false)
    const [loginMessage,setLoginMessage] = useState("")
    const objectToFormData = (object) => {
      const formData = new FormData();
      for (const key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
          formData.append(key, object[key]);
        }
      }

      return formData;
    };

    const inputOnChanged = (event) => {
      setUser((prevUser) => ({
        ...prevUser,
        [event.target.name]: event.target.value,
      }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!user.username || !user.password) {
          setOpenMessageDialog(true);
          setLoginMessage('Username and password are required.');
          return
        }

        setShowProgress(true)

        try {
            // Login request
            const loginOptions = {
              method: 'POST',
              url: '/ApiUsers/login.json',
              data: objectToFormData(user),
            };
      
            const loginResponse = await axios(loginOptions);
            const token = loginResponse.data.token;
            const role = loginResponse.data.role_id;
            const user_name = loginResponse.data.user_name;
            const mfaRequired = loginResponse.data.has_mfa_required;
            const is_success = loginResponse.data.is_success;

            if (mfaRequired) {
              // redirect to MFA
              const accessToken = loginResponse.data.access_token;
              const mfaToken = loginResponse.data.mfa_token.token;
              
              if (is_success)  {
                  localStorage.setItem('token', accessToken);
                  localStorage.setItem('mfaToken', mfaToken);
                  localStorage.setItem('role', role);
                  setAuth({role: role, token: accessToken, username: user_name, fullName: loginResponse.data.full_name, companyId: loginResponse.data.company_id})
                  navigate('/mfa');
              } else {
                setOpenMessageDialog(true);
                setLoginMessage(loginResponse.message);
              }
          } else {
            
              if (is_success)  {
                  // Save the token to localStorage
                  localStorage.setItem('token', token);
                  localStorage.setItem('role', role);
                  setAuth({role: role, token: token, username: user_name, fullName: loginResponse.data.full_name, companyId: loginResponse.data.company_id})
                  navigate('/dashboard');
              } else {
                setOpenMessageDialog(true);
                setLoginMessage(loginResponse.message);
              }
          }
          } catch (error) {
            setShowProgress(false);
            if (error.response && error.response.status === 401) {
              const errorMessage = error.response.data.message || 'Invalid username or password';
              setOpenMessageDialog(true);
              setLoginMessage(errorMessage);
            } else {
              // Handle other errors
              console.error('Other error:', error);
            }
          }
    };
    

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              onChange={inputOnChanged}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={inputOnChanged}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
        <ProcessDialog 
          showProgress={showProgress}
          ContentText="Verifying Credentials Please Wait..."
          />
        <StyledEngineProvider injectFirst>
            <MessageDialog 
              open={openMessageDialog}
              handleClose ={() => setOpenMessageDialog(false)}
              message={loginMessage}
            />
        </StyledEngineProvider>
      </Container>
    </ThemeProvider>
  );
}