import { FileDownload } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';

const CSVTemplateDownloadButton = ({ csvContent, filename }) => {
  const handleDownload = () => {
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  return (
    <Button 
        variant="contained"
        color="primary"
        onClick={handleDownload}
    ><FileDownload />
      Download Template
    </Button>
  );
};

export default CSVTemplateDownloadButton;