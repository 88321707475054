import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { ListItemIcon, useTheme } from '@mui/material';
import { Construction, DoNotDisturb, Edit, ExpandMore } from '@mui/icons-material';
import ChangeStatus from './ChangeStatus';



export default function MoreOptionBtn({repairDetails, setonRejectOrApprove}) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openChangeDepType, setOpenChangeDepType] = useState(false);
  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [openCancelRequest, setOpenCancelRequest] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const hanldeOpenDeployementType = () => {
    setOpenChangeDepType(true);
    setAnchorEl(null);
  }


  const handleOpenChangeStatus = () => {
    setOpenChangeStatus(true);
    setAnchorEl(null);
  }
  const handleOpenCancelRequest = () => {
    setOpenCancelRequest(true);
    setAnchorEl(null);
  }

  return (
    <div>
      <Button
        variant="outlined" 
        size="small" 
        sx={{color: theme.palette.secondary.dark}} 
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        More actions <ExpandMore fontSize='small'/>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleOpenChangeStatus}>
          <ListItemIcon>
            <Construction fontSize="small"/>
          </ListItemIcon>Change Status
        </MenuItem>
        {/* <MenuItem sx={{ color: '#f6bf26' }} onClick={handleOpenCancelRequest} disabled={repairDetails.status_code === 1}>
          <ListItemIcon>
            <DoNotDisturb fontSize="small" sx={{ color: '#f6bf26' }} />
          </ListItemIcon>Cancel Request
        </MenuItem> */}
      </Menu>

      {openChangeStatus && <ChangeStatus open={openChangeStatus} setOpen={setOpenChangeStatus} repairDetails={repairDetails} setonRejectOrApprove={setonRejectOrApprove}/>}
      {/* {openCancelRequest && <CancelRequest open={openCancelRequest} setOpen={setOpenCancelRequest} repairDetails={repairDetails} setOnEditOrDelete={setOnEditOrDelete}/>} */}
    </div>
  );
}