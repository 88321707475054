import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { Edit } from '@mui/icons-material';
import useJobOrdersContext from '../../hooks/UseJobOrdersContext';

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);
function EditJobOrder({jobOrderDetails, setDoneUpdateRequest}) {
  const axios = AxiosCustomConfig();
  const {setDoneUpdate} = useJobOrdersContext();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [editData, setEditData] = useState({
    trip_date: new Date().toISOString().substr(0, 10),
    deployment_request: [],
    pullout_request: [],
    hauling_rates: [],
    service_request: [],
    warehouse: [],
    assigned_hauler: [],
    remarks: '',
    job_order_id: '',
  });

  const [deploymentRequests, setDeploymentRequests] = useState([]);
  const [openDepRequestAutoComplete, setOpenDepRequestAutoComplete] = useState(false);
  const [isDeploymentLoading, setIsDeploymentLoading] = useState(false);
  const [pulloutRequests, setPulloutRequests] = useState([]);
  const [openPullRequestAutoComplete, setOpenPullRequestAutoComplete] = useState(false);
  const [isPulloutLoading, setIsPulloutLoading] = useState(false);
  const [haulingRates, setHaulingRates] = useState([]);
  const [openHaulingRate, setOpenHaulingRate] = useState(false);
  const haulingLoading = openHaulingRate && haulingRates.length === 0;
  const [warehouses, setWarehouses] = useState([]);
  const [openWarehouse, setOpenWarehouse] = useState(false);
  const warehouseLoading = openWarehouse && warehouses.length === 0;
  const [assignedHauler, setAssignedHauler] = useState([]);
  const [isLoadingAssignedHauler, setIsLoadingAssignedHauler] = useState(false);
  const [openAssignedhauler, setOpenAssignedhauler] = useState(false);
  const openUserHaulerEffect = openAssignedhauler && assignedHauler.length === 0;
  const [serviceCallRequests, setServiceCallRequests] = useState([]);
  const [openServiceCallRequest, setOpenServiceCallRequest] = useState(false);
  const [loadingServiceCall, setLoadingServiceCall] = useState(false);

  const handleOpen = () => {
    setOpen(true)

    const {deployment, trip_date, pullout, service_request, remarks, warehouse_unique_code, centigen_branch, assigned_hauler, hauling_rate, hauling_rate_name, hauling_unique_code, job_order_id} = jobOrderDetails

    const deploymentArray = deployment
        ? deployment.split(',').map(item => {
            const trimmedItem = item.trim();
            return {
                label: trimmedItem,
                request_code: trimmedItem,
            };
        })
        : [];

    const pulloutArray = pullout
        ? pullout.split(',').map(item => {
            const trimmedItem = item.trim();
            return {
                label: trimmedItem,
                request_code: trimmedItem,
            };
        })
        : [];

    const serviceRequestArray = service_request
        ? service_request.split(',').map(item => {
            const trimmedItem = item.trim();
            return {
                label: trimmedItem,
                request_code: trimmedItem,
            };
        })
        : [];

    setEditData(prevData => ({
      ...prevData,
      trip_date: trip_date,
      remarks: remarks,
      warehouse: {'label': `${centigen_branch} - (${warehouse_unique_code})`, 'warehouse_name': centigen_branch, warehouse_unique_code: warehouse_unique_code}, 
      assigned_hauler: {'label': assigned_hauler, 'assigned_hauler': assigned_hauler,}, 
      hauling_rates: {'label': hauling_rate_name, 'unique_code': hauling_unique_code, 'rate': hauling_rate},
      deployment_request: deploymentArray,
      pullout_request: pulloutArray,
      service_request: serviceRequestArray,
      warehouse_unique_code: warehouse_unique_code,
      job_order_id: job_order_id,
    }))
    
  }


  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateRequiredFields()) return;

    if (!isValidSpecialCharacter(editData.remarks)) {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in remarks.')
      return
    }

    if (editData.deployment_request.length === 0 && editData.pullout_request.length === 0) {
      setOpenMessageDialog(true);
      setMessage('Please select atleast one request.')
      return
    }

    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/JobOrders/editJobOrder.json',
              data: editData,
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          resetForm();
          setDoneUpdateRequest((prev) => !prev)
          setDoneUpdate((prev) => !prev)
          setOpen(false)
          setOpenMessageDialog(true);
          setMessage(response.data.message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Please try again and make sure you have internet connection.`);
    }
  }

  const resetForm = () => {
    setEditData({
      trip_date: new Date().toISOString().substr(0, 10),
      deployment_request: [],
      pullout_request: [],
      hauling_rates: [],
      warehouse: [],
      assigned_hauler: [],
      service_request: [],
      remarks: '',
      job_order_id: '',
    });
  };


  const validateRequiredFields = () => {
    const requiredFields = ['trip_date',  'hauling_rates', 'warehouse', 'assigned_hauler'];

    for (const field of requiredFields) {
      if (!editData[field] || (Array.isArray(editData[field]) && editData[field].length === 0)) {
        setOpenMessageDialog(true);
        setMessage('All Fields with asterisk (*) are required');
        return false;
      }
    }

    return true;
  };

  const getDeploymentRequests = async (active) => {
    try {
      setIsDeploymentLoading(true)
        const options = {
            method: 'POST',
            url: '/DeploymentRequests/getDeploymentRequestsForAutocomplete.json',
            'data': {warehouse_unique_code: editData.warehouse.warehouse_unique_code}
        }
        const response = await axios(options);
        if (active) {
          setDeploymentRequests(response.data);
        }
        setIsDeploymentLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getPulloutRequests = async (active) => {
    try {
      setIsPulloutLoading(true)
        const options = {
            method: 'POST',
            url: '/PulloutRequests/getPulloutRequestsForAutocomplete.json',
            'data': {warehouse_unique_code: editData.warehouse.warehouse_unique_code}
        }
        const response = await axios(options);
        if (active) {
          setPulloutRequests(response.data);
        }
        setIsPulloutLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getHaulingRates = async (active) => {
    try {
        const options = {
            method: 'POST',
            url: '/HaulingRates/getHaulingRatesForAutocomplete.json',
        }
        const response = await axios(options);
        if (active) {
          setHaulingRates(response.data);
        }
        
    } catch (error) {
      console.log('Error info: ', error);
    }
  }
  const getWarehouses = async (active) => {
    try {
        const options = {
            method: 'POST',
            url: '/Warehouses/getWarehousesForAutocomplete.json',
        }
        const response = await axios(options);
        if (active) {
          setWarehouses(response.data);
        }
        
    } catch (error) {
      console.log('Error info: ', error);
    }
  }
  const getAssignedHauler = async (active) => {
    try {
        const options = {
            method: 'POST',
            url: '/HaulerWarehouses/getUsersWarehouseForAutocomplete.json',
            'data': {warehouse_unique_code: editData.warehouse.warehouse_unique_code}
        }
        const response = await axios(options);
        if (active) {
          setAssignedHauler(response.data);
        }
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getServiceCarllRequests = async (active) => {
    try {
      setLoadingServiceCall(true)
        const options = {
            method: 'POST',
            url: '/ServiceRequests/getServiceRequestsForAutocomplete.json',
            'data': {warehouse_unique_code: editData.warehouse.warehouse_unique_code}
        }
        const response = await axios(options);
        if (active) {
          setServiceCallRequests(response.data);
        }
        setLoadingServiceCall(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(() => {
    let active = true;

    if (!openServiceCallRequest) {
      return undefined;
    }

    getServiceCarllRequests(active);

    return () => {
      active = false;
    };

  }, [openServiceCallRequest]);

  useEffect(() => {
    let active = true;

    if (!openDepRequestAutoComplete) {
      return undefined;
    }

    getDeploymentRequests(active);

    return () => {
      active = false;
    };

  }, [openDepRequestAutoComplete]);

  useEffect(() => {
    if (!openDepRequestAutoComplete) {
      setDeploymentRequests([]);
    }
  }, [openDepRequestAutoComplete]);

  useEffect(() => {
    let active = true;
    if(!openPullRequestAutoComplete) {
      return undefined
    }
    getPulloutRequests(active);

    return () => {
      active = false;
    };

  }, [openPullRequestAutoComplete]);

  useEffect(() => {
    if (!openPullRequestAutoComplete) {
      setPulloutRequests([]);
    }
  }, [openPullRequestAutoComplete]);

  useEffect(() => {
    let active = true;

    if (!haulingLoading) {
      return undefined;
    }

    getHaulingRates(active);

    return () => {
      active = false;
    };

  }, [haulingLoading]);

  useEffect(() => {
    if (!openHaulingRate) {
      setHaulingRates([]);
    }
  }, [openHaulingRate]);

  useEffect(() => {
    let active = true;

    if (!warehouseLoading) {
      return undefined;
    }

    getWarehouses(active);

    return () => {
      active = false;
    };

  }, [warehouseLoading]);

  useEffect(() => {
    if (!openWarehouse) {
      setWarehouses([]);
    }
  }, [openWarehouse]);

  useEffect(() => {
    let active = true;

    if (!openUserHaulerEffect) {
      return undefined;
    }

    getAssignedHauler(active);

    return () => {
      active = false;
    };

  }, [openUserHaulerEffect]);

  useEffect(() => {
    if (!openAssignedhauler) {
      setAssignedHauler([]);
    }
  }, [openAssignedhauler]);

  const hanldeDeploymentRequestChange = (e, selectValue) => {
    if (selectValue) {
      setEditData((prevAccount) => ({ ...prevAccount, deployment_request: selectValue}))
    }
  }
  const hanldePulloutRequestsChange = (e, selectValue) => {
    if (selectValue) {
      setEditData((prevAccount) => ({ ...prevAccount, pullout_request: selectValue}))
    }
  }

  const hanldeHaulingRequestsChange = (e, selectValue) => {
    if (selectValue) {
      setEditData((prevAccount) => ({ ...prevAccount, hauling_rates: selectValue}))
    }
  }

  const hanldeAssignedHaulerChange = (e, selectValue) => {
    if (selectValue) {
      setEditData((prevAccount) => ({ ...prevAccount, assigned_hauler: selectValue}))
    }
  }
  const hanldeWarehouseChange = (e, selectValue) => {
    if (selectValue) {
      setEditData((prevAccount) => ({ ...prevAccount, warehouse: selectValue}))
    } else {
      setEditData((prevAccount) => ({ ...prevAccount, warehouse: []}))
    }
  }

  const hanldeServiceCallRequestsChange = (e, selectValue) => {
    if (selectValue) {

      setEditData((prevAccount) => ({ ...prevAccount, service_request: selectValue}))
    }
  }

  return (
    <div>
    <Button 
      variant='outlined' 
      size="small" 
      color='info' 
      startIcon={<Edit />}  
      onClick={handleOpen}>Edit</Button>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Add New Job order</DialogTitle>
      <DialogContent>
          <Grid container spacing={2}>
          <Grid item xs={12} mt={1}>
                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                            label="Trip Date*"
                            value={editData.trip_date ? dayjs(editData.trip_date) : null}
                            onChange={(newValue) => setEditData({...editData, trip_date: dayjs(newValue).format('YYYY-MM-DD')})}
                            minDate={dayjs()}
                        />
                    </LocalizationProvider>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Autocomplete
                      id="combo-box-demo"
                      open={openWarehouse}
                      onOpen={() => {setOpenWarehouse(true);}}
                      onClose={() => {setOpenWarehouse(false);}}
                      onChange={hanldeWarehouseChange}
                      options={warehouses}
                      value={editData.warehouse}
                      loading={warehouseLoading}
                      getOptionLabel={(option) => option.label || ''}
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Warhouse*"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {warehouseLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  disabled={!editData.warehouse.warehouse_unique_code}
                  id="combo-box-demo"
                  disableCloseOnSelect
                  multiple
                  open={openDepRequestAutoComplete}
                  onOpen={() => {setOpenDepRequestAutoComplete(true);}}
                  onClose={() => {setOpenDepRequestAutoComplete(false);}}
                  onChange={hanldeDeploymentRequestChange}
                  options={deploymentRequests}
                  value={editData.deployment_request}
                  loading={isDeploymentLoading}
                  noOptionsText={'No data'}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Deployment request"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isDeploymentLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  disabled={!editData.warehouse.warehouse_unique_code}
                  id="combo-box-demo"
                  disableCloseOnSelect
                  multiple
                  open={openPullRequestAutoComplete}
                  onOpen={() => {setOpenPullRequestAutoComplete(true);}}
                  onClose={() => {setOpenPullRequestAutoComplete(false);}}
                  onChange={hanldePulloutRequestsChange}
                  options={pulloutRequests}
                  value={editData.pullout_request}
                  loading={isPulloutLoading}
                  noOptionsText={'No data'}
                  getOptionLabel={(option) => option?.label || ''}
                  isOptionEqualToValue={(option, value) => option?.label === value?.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Pullout request"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isPulloutLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  disabled={!editData.warehouse.warehouse_unique_code}
                  id="combo-box-demo"
                  disableCloseOnSelect
                  multiple
                  open={openServiceCallRequest}
                  onOpen={() => {setOpenServiceCallRequest(true);}}
                  onClose={() => {setOpenServiceCallRequest(false);}}
                  onChange={hanldeServiceCallRequestsChange}
                  options={serviceCallRequests}
                  value={editData.service_request}
                  loading={loadingServiceCall}
                  noOptionsText={'No data'}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Service Call"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingServiceCall ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Autocomplete
                      id="combo-box-demo"
                      open={openHaulingRate}
                      onOpen={() => {setOpenHaulingRate(true);}}
                      onClose={() => {setOpenHaulingRate(false);}}
                      onChange={hanldeHaulingRequestsChange}
                      options={haulingRates}
                      value={editData.hauling_rates}
                      loading={haulingLoading}
                      getOptionLabel={(option) => option.label || ''}
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Hauling Rate*"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {haulingLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Autocomplete
                      id="combo-box-demo"
                      open={openAssignedhauler}
                      onOpen={() => {setOpenAssignedhauler(true);}}
                      onClose={() => {setOpenAssignedhauler(false);}}
                      onChange={hanldeAssignedHaulerChange}
                      options={assignedHauler}
                      value={editData.assigned_hauler}
                      loading={isLoadingAssignedHauler}
                      getOptionLabel={(option) => option.label || ''}
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Assigned hauler*"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {isLoadingAssignedHauler ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography>Remarks:</Typography>
                <Textarea 
                  aria-label="minimum height" 
                  minRows={3} 
                  placeholder="Remarks" 
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, remarks: e.target.value }))}
                />
              </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default EditJobOrder