import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, StyledEngineProvider } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import CSVTemplateDownloadButton from '../CSVTemplateDownloadButton';
import CsvStatusDialog from '../Utils/CsvStatusDialog';
import MessageDialog from '../Utils/MessageDialog';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { objectToFormData } from '../GenericFunctions/GenericFunctions';
import { FileUpload } from '@mui/icons-material';
import Papa from 'papaparse';

function AddAssetsViaCsv({setOnEditOrDelete}) {
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const fileInputRef = useRef(null);
  const [csvData, setCSVData] = useState(null);
  const [csvUploadPercent, setCsvUploadPercent] = useState(0);
  const [uploadStatusArray, setUploadStatusArray] = useState([]);
  const [doneUploadingProduct, setDoneUploadingProduct] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [showCsvStatusDialog, setShowCsvStatusDialog] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setDoneUploadingProduct(false);
    setOpenMessageDialog(false);
    setOnEditOrDelete(prev => !prev);
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Reset the file input
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setCSVData(null)
  };

  const onInputChange = (event) =>{
    let file = event.target.files;
    setCSVData(file);
  };

  const generateCSVContent = () => {
    // Generate CSV content with default header
    const header = "Equipment no.*,Asset Model*,Asset Type Size*,Asset Status,Warehouse Branch,Store/Business name,Acquisition Date,Acquisition Value,Net Book Value,Serial No,Asset No - Asset Model,JDE System status,Channel,Contact No,Contact Person,Distributor/Customer Chain,Customer type,JDE/Syspro Customer #,Store address,Region,Date scrapped,Scrap date,Audit period,Audit remarks,Latest asset update,Audit comment,Remarks,TAM remarks";
    return header;
  };


  const processUploadProductsCsv = async (csvData) => {
    if (csvData && csvData.length > 0) {
      try {
        const file = csvData[0];
        if( file.type !== "text/csv"){
          setOpenMessageDialog(true);
          setMessage('File error. The file must be a csv.');
          return;
        }

        const assetList =  await readCsv(file);
        
        if(assetList.length <=0){
          setOpenMessageDialog(true);
          setMessage("CSV must not empty.");
          return;
        }

        if(assetList.success !== true){
          setOpenMessageDialog(true);
          setMessage(assetList.message);
          return;
        }

        const chunkedData = await chunkUploadData(assetList.data);
        const totalData = Math.ceil(assetList.data.length / 200);

        setModalTitle('Upload Assets');
        setShowCsvStatusDialog(true)
        insertProducts(0,chunkedData,totalData);

      } catch(error){
        setOpenMessageDialog(true);
        setMessage(error);
      }   
    } else {
      setMessage('File error. Please select csv file.')
      setOpenMessageDialog(true);
    }
  };

  function readCsv(csvFile) {
    return new Promise((resolve, reject) => {
      var listData = [];
      let has_error = 0;
      let return_message = {
        success: false,
        message: '',
        data: []
      }
          Papa.parse(csvFile, {
            header: true,
            skipEmptyLines: false,
            encoding: "ISO-8859-3",
            step:function(row, parser){
              let headers = generateCSVContent().split(',');

              let mismatchedHeaders = [];
              if (headers.length === row.meta.fields?.length) {
                for (var h = 0; h < row.meta.fields?.length; h++) {
                  let csv_header = row.meta.fields[h].trim();

                  if (!headers.includes(csv_header)) {
                    has_error++;
                    mismatchedHeaders.push({ incorrect: csv_header, correct: headers[h] });
                  }
                }

                if (mismatchedHeaders.length > 0) {
                  return_message.message = 'Invalid Headers:\n';
                  mismatchedHeaders.forEach(header => {
                    return_message.message += `Expected "${header.correct}", but found "${header.incorrect}".\n`;
                  });
                  parser.abort();
                }

                if(row.errors.length === 0){
                  var assetData = {};
                  assetData = {
                    code: row.data.hasOwnProperty('Equipment no.*') ? row.data['Equipment no.*'].trim() : row.data['Equipment no.*'],
                    asset_model: row.data.hasOwnProperty('Asset Model*') ? row.data['Asset Model*'].trim() : row.data['Asset Model*'],
                    asset_type_size: row.data.hasOwnProperty('Asset Type Size*') ? row.data['Asset Type Size*'] : row.data['Asset Type Size*'],
                    status_name: row.data.hasOwnProperty('Asset Status') ? row.data['Asset Status'].trim() : row.data['Asset Status'],
                    warehouse_branch: row.data.hasOwnProperty('Warehouse Branch') ? row.data['Warehouse Branch'] : row.data['Warehouse Branch'],
                    store_name: row.data.hasOwnProperty('Store/Business name') ? row.data['Store/Business name'].trim() : row.data['Store/Business name'],
                    acquisition_date: row.data.hasOwnProperty('Acquisition Date') ? row.data['Acquisition Date'].trim() : row.data['Acquisition Date'],
                    acquisition_value: row.data.hasOwnProperty('Acquisition Value') ? row.data['Acquisition Value'].trim() : row.data['Acquisition Value'],
                    net_book_value: row.data.hasOwnProperty('Net Book Value') ? row.data['Net Book Value'] : row.data['Net Book Value'],
                    serial_no: row.data.hasOwnProperty('Serial No') ? row.data['Serial No'].trim() : row.data['Serial No'],
                    asset_no_asset_model: row.data.hasOwnProperty('Asset No - Asset Model') ? row.data['Asset No - Asset Model'].trim() : row.data['Asset No - Asset Model'],
                    system_status: row.data.hasOwnProperty('JDE System status') ? row.data['JDE System status'].trim() : row.data['JDE System status'],
                    channel: row.data.hasOwnProperty('Channel') ? row.data['Channel'].trim() : row.data['Channel'],
                    contact_no: row.data.hasOwnProperty('Contact No') ? row.data['Contact No'].trim() : row.data['Contact No'],
                    contact_person: row.data.hasOwnProperty('Contact Person') ? row.data['Contact Person'].trim() : row.data['Contact Person'],
                    distributor_customer_chain: row.data.hasOwnProperty('Distributor/Customer Chain') ? row.data['Distributor/Customer Chain'].trim() : row.data['Distributor/Customer Chain'],
                    customer_type: row.data.hasOwnProperty('Customer type') ? row.data['Customer type'].trim() : row.data['Customer type'],
                    jde_syspro_customer_no: row.data.hasOwnProperty('JDE/Syspro Customer #') ? row.data['JDE/Syspro Customer #'].trim() : row.data['JDE/Syspro Customer #'],
                    store_address: row.data.hasOwnProperty('Store address') ? row.data['Store address'].trim() : row.data['Store address'],
                    region: row.data.hasOwnProperty('Region') ? row.data['Region'].trim() : row.data['Region'],
                    date_scrapped: row.data.hasOwnProperty('Date scrapped') ? row.data['Date scrapped'].trim() : row.data['Date scrapped'],
                    scrap_date: row.data.hasOwnProperty('Scrap date') ? row.data['Scrap date'].trim() : row.data['Scrap date'],
                    audit_period: row.data.hasOwnProperty('Audit period') ? row.data['Audit period'].trim() : row.data['Audit period'],
                    audit_remarks: row.data.hasOwnProperty('Audit remarks') ? row.data['Audit remarks'].trim() : row.data['Audit remarks'],
                    latest_asset_update: row.data.hasOwnProperty('Latest asset update') ? row.data['Latest asset update'].trim() : row.data['Latest asset update'],
                    audit_comment: row.data.hasOwnProperty('Audit comment') ? row.data['Audit comment'].trim() : row.data['Audit comment'],
                    remarks: row.data.hasOwnProperty('Remarks') ? row.data['Remarks'].trim() : row.data['Remarks'],
                    tam_remarks: row.data.hasOwnProperty('TAM remarks') ? row.data['TAM remarks'].trim() : row.data['TAM remarks'],

                  };

                  listData.push(assetData);
                  parser.resume()
                }
              } else {
                has_error++;
                return_message.message = 'Header Count are not match';
                parser.abort();
              }
            },
            complete: function (results) {
              if (has_error === 0) {
                return_message.success = true;
                return_message.data = listData;
              } else {
                return_message.success = false;
              }
              resolve(return_message)
            },
            error: function (error) {
              reject(error);
            },
          });
        });
  };

  function chunkUploadData(results){
    let chunkedData = [];
    let dataArray = [];
    for(let i = 0; i < results.length; i++){
      if(dataArray.length === 200){
        chunkedData.push(dataArray);
        dataArray = [];
      }

      dataArray.push(results[i]);
   
      if ( i === results.length - 1 )
      {
        chunkedData.push(dataArray);
        dataArray = [];
      }
    }
    return chunkedData;
  };

  async function insertProducts(index, chunkedData, totalData){
    let percent = (index / totalData) * 100;
    setCsvUploadPercent((prevProgress) => (prevProgress >= 100 ? percent : percent));
    
    if(index < chunkedData.length){
      var data = JSON.stringify(chunkedData[index]);
      await axios({
        method: 'POST',
        url: '/AssetsRegistry/uploadAssetsMasterFile.json',
        data: objectToFormData({index:index, data:data}),
        dataType: 'json' // Add this line
      }).then((res) =>{
          let res_status = res.data;

          if (Array.isArray(res_status) && res_status.length !== 0) {
            setUploadStatusArray(uploadStatusArray => [...uploadStatusArray, ...res_status]);
          }

          insertProducts(index+1, chunkedData, totalData);
        
      }).catch(error=>{
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. There is something wrong with the server, Please contact admin.`);

      });
    }else{
      setDoneUploadingProduct(prev => !prev);
      setOpen(false)
    }
  }

  useEffect(()=>{
    if(doneUploadingProduct === true && uploadStatusArray.length <=0){
        setMessage("Assets uploaded successfully.")
        setOpenMessageDialog(true);
        setOnEditOrDelete(prev => !prev)
        closeUploadStatusDialog();
    }
  },[doneUploadingProduct])

  function closeUploadStatusDialog() {
    setShowCsvStatusDialog(false)
    setOpen(false);
    setCSVData(null)
  }

  return (
    <div>
      <Button variant="contained" color="success" size="small" onClick={handleOpen}>Add asset via CSV</Button>
      
      <Dialog fullWidth={true} maxWidth={'md'} open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle>Upload Assets</DialogTitle>
        <DialogContent>
        <Box p={1} display="flex" justifyContent="left"  flexDirection="column"><i>
          1. All fields with asterisk are required <br/></i> <br/>
          
          <input accept=".csv" type="file" ref={fileInputRef} onChange={onInputChange}/>
        </Box>
        </DialogContent>
        <DialogActions>
        <CSVTemplateDownloadButton csvContent={generateCSVContent()} filename="Assets Master File.csv" />
        <Button variant="contained" color='success' component="label" onClick={() => {processUploadProductsCsv(csvData)}}><FileUpload /> Upload
        </Button>
        <Button color="primary" onClick={handleCancel} autoFocus>Close</Button>
        </DialogActions>
      </Dialog>
      <CsvStatusDialog 
        modalTitle={modalTitle} 
        showCsvStatusDialog={showCsvStatusDialog}
        csvUploadPercent={csvUploadPercent}
        uploadStatusArray={uploadStatusArray}
        handleClose ={() => {
          setCSVData(null)
          setShowCsvStatusDialog(false)
          setUploadStatusArray([])
          }}
      />
      <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={handleClose}
            message={message}
          />
      </StyledEngineProvider>
    </div>
  )
}

export default AddAssetsViaCsv