import { createContext, useEffect, useState } from "react";
import { AxiosCustomConfig } from "../config/AxiosCustomConfig";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const axios = AxiosCustomConfig();
    const [auth, setAuth] = useState({
        role: localStorage.getItem('role'),
        token: localStorage.getItem("token"),
        username: '',
        fullName: '',
        companyId: '',
    });

    useEffect(() => {
        const fetchUsername = async () => {
            try {
                const options = {
                    method: 'POST',
                    url: '/ApiUsers/getUsername.json',
                }
                const response = await axios(options)
                if (response.data.is_success) {
                    setAuth((prev) => ({...prev, username: response.data.username, fullName: response.data.fullname, companyId: response.data.company_id}))
                }
            } catch (error) {
            console.log(error);
            }
        }
        fetchUsername();
    }, [])

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;