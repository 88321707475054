import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField, Typography } from '@mui/material';
import React, {useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';
import styled from '@emotion/styled';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import useAuth from '../../hooks/UseAuth';
import useViewAssetContext from '../../hooks/UseViewAssetContext';
import useCommentsContext from '../../hooks/UseCommentsContext';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';


const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

function ChangeAssetSpecs({open, setOpen, assetsData}) {
  const {setDoneUpdating} = useViewAssetContext();
  const {setDoneUpdate} = useCommentsContext();
  const axios = AxiosCustomConfig();
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [assetModels, setAssetModels] = useState([]);
  const [openAssetModels, setOpenAssetModels] = useState(false);
  const [loadingAssetModels, setLoadingAssetModels] = useState(false);
  const [assetSize, setAssetSize] = useState([]);
  const [openAssetSize, setOpenAssetSize] = useState(false);
  const [loadingAssetSize, setLoadingAssetSize] = useState(false);
  const [warehouseData, setWarehouseData] = useState({
    asset_code: '',
    asset_models_array: [],
    asset_model: '',
    acquisition_date: '',
    asset_size: [],
    asset_size_code: '',
  });

  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    setShowProgress(true);
    try {
        const options = {
            method: 'POST',
              url: '/AssetsRegistry/updateAssetSpecs.json',
              data: objectToFormData(warehouseData),
        }

        const response = await axios(options);
        const {is_success, message} = response.data;

        setShowProgress(false);

        if (is_success) {
          setDoneUpdating((prev) => !prev)
          setDoneUpdate((prev) => !prev)
          setOpenMessageDialog(true);
          setMessage(message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. There's something wrong with the server.`);
    }
  }

  const resetForm = () => {
    setWarehouseData({
      asset_code: '',
      asset_models_array: [],
      asset_model: '',
      acquisition_date: '',
      asset_size: [],
      asset_size_code: '',
    });
  };

  const getAssetModels = async (ignore) => {
    try {
        setLoadingAssetModels(true)
        const options = {
            method: 'POST',
            url: '/AssetModels/getAssetModelsNameAndCode.json',
        }

        const response = await axios(options);
        if (!ignore) {
          setAssetModels(response.data);
          setLoadingAssetModels(false)
        }
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getAssetSizes = async (ignore) => {
    try {

      setLoadingAssetSize(true)
      const options = {
          method: 'POST',
          url: '/AssetSizes/getAssetSizesForRequest.json',
      }
      const response = await axios(options);

      if(!ignore) {
        setAssetSize(response.data);
        setLoadingAssetSize(false)
      }

    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(()=>{
    let ignore = false;

    if (!openAssetModels) return undefined
    getAssetModels(ignore);

    return () => {
      ignore = true;
    }
  }, [openAssetModels]);

  useEffect(()=>{
    let ignore = false;

    if (!openAssetSize) return undefined
    getAssetSizes(ignore);

    return () => {
      ignore = true;
    }
  }, [openAssetSize]);

  useEffect(() => {
    if (open && assetsData) {
      const { equipment_no, asset_model, acquisition_date, acquisition_value, net_book_value, serial_no, asset_type_size} = assetsData;
      setWarehouseData({
        asset_models_array: {'label' : asset_model, value: asset_model},
        asset_model: asset_model,
        asset_code: equipment_no,
        acquisition_date: acquisition_date,
        acquisition_value: acquisition_value,
        net_book_value: net_book_value,
        serial_no: serial_no,
        asset_type_size: asset_type_size,
        asset_size: {'label' : asset_type_size, value: asset_type_size},
      });
    }
    
    

  }, [open, assetsData]);

  const handleAssetModelsChange = (e, selectValue) => {
    if (selectValue) {
      let asset_model = selectValue.asset_model;
      setWarehouseData((prevAccount) => ({
        ...prevAccount, 
        asset_model: asset_model, 
        asset_models_array: selectValue
      }))
    }
  }

  const handleAssetSizeChange = (e, selectValue) => {
    if (selectValue) {
      setWarehouseData((prevData) => ({ ...prevData, asset_size: selectValue, asset_size_code: selectValue.asset_size_code, asset_type_size: selectValue.label}))
    }
  }

  return (
    <div>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Change Asset specs</DialogTitle>
      <DialogContent>
          <Grid container spacing={3} mt={1}>
            <Grid item xs={12}>
              <FormControl fullWidth>
              <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  open={openAssetModels}
                  onOpen={() => {setOpenAssetModels(true);}}
                  onClose={() => {setOpenAssetModels(false);}}
                  loading={loadingAssetModels}
                  onChange={handleAssetModelsChange}
                  options={assetModels}
                  value={warehouseData.asset_models_array}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => (option && value && option.label === value.label)}
                  renderInput={(params) => <TextField {...params} label="Asset Model" />}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker 
                    label="Acquisition Date"
                    value={warehouseData.acquisition_date ? dayjs(warehouseData.acquisition_date) : null}
                    onChange={(newValue) => setWarehouseData({
                      ...warehouseData,
                      acquisition_date: newValue ? dayjs(newValue).format('YYYY-MM-DD') : null
                    })}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="acquisition_value"
                  label="Acquisition Value"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setWarehouseData((prevAccount) => ({ ...prevAccount, acquisition_value: e.target.value }))}
                  value={warehouseData.acquisition_value}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="net_book_value"
                  label="Net Book Value"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setWarehouseData((prevAccount) => ({ ...prevAccount, net_book_value: e.target.value }))}
                  value={warehouseData.net_book_value}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="serial_no"
                  label="Serial No"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setWarehouseData((prevAccount) => ({ ...prevAccount, serial_no: e.target.value }))}
                  value={warehouseData.serial_no}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} mt={2}>
                <FormControl fullWidth>
                    <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    open={openAssetSize}
                    onOpen={() => {setOpenAssetSize(true);}}
                    onClose={() => {setOpenAssetSize(false);}}
                    loading={loadingAssetSize}
                    onChange={handleAssetSizeChange}
                    options={assetSize}
                    value={warehouseData.asset_size}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderInput={(params) => <TextField {...params} label="Asset Type Size*" />}
                    />
                </FormControl>
            </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => {
              setOpenMessageDialog(false)
              setOpen(false)
              }}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default ChangeAssetSpecs