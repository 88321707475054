
const isValidSpecialCharacter = (str) => {
  // Define your logic to validate if a string contains special characters
  // For example, you can use a regular expression
  const specialCharacters = /^[a-zA-Z0-9.\-@&/#$*()%ñÑ+'":;~\\_[\],=|?{} \s]+$/;
  return specialCharacters.test(str);
};

const isValidEmail = (email) => {
  // Define your logic to validate an email address
  // For example, you can use a regular expression
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

const objectToFormData = (object) => {
  const formData = new FormData();
  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      formData.append(key, object[key]);
    }
  }

  return formData;
};

const validateAlphaNumeric = (string) => {
  let pattern = /^[a-zA-Z0-9-]*$/;

  return pattern.test(string);
};

const trimWhitespaces = (string) => {
  return string.replace(/^\s+|\s+$/gm,'').replace(/\s+/g, ' ').trim();
}


export { isValidSpecialCharacter, isValidEmail, objectToFormData, validateAlphaNumeric, trimWhitespaces };
