import React, { createContext, useState } from 'react'

const StoreToStoreContext = createContext({});

export const StoreToStoreProvider = ({children}) => {
  const [doneUpdate, setDoneUpdate] = useState(false);

  return (
      <StoreToStoreContext.Provider value={{ doneUpdate, setDoneUpdate }}>
          {children}
      </StoreToStoreContext.Provider>
  )
}

export default StoreToStoreContext