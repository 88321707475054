import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField } from '@mui/material';
import React, {useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { objectToFormData } from '../GenericFunctions/GenericFunctions';

function ChangePulloutType({open, setOpen, requestDetails, setOnEditOrDelete}) {
  const axios = AxiosCustomConfig();
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [PulloutTypes, setPulloutTypes] = useState([]);
  const [PulloutTypesLoading, setPulloutTypesLoading] = useState(false);
  const [openPulloutTypes, setOpenPulloutTypes] = useState(false);
  const [pulloutTypesData, setPulloutTypesData] = useState({
    request_code: '',
    pullout_type_arr: [],
    pullout_type_code: '', 
    pullout_type_name: ''
  });

  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!pulloutTypesData.pullout_type_code) {
        setOpenMessageDialog(true);
        setMessage('Pullout type is required')
        return
    }
    
    setShowProgress(true);
    try {
        const options = {
            method: 'POST',
              url: '/PulloutRequests/updatePulloutType.json',
              data: objectToFormData(pulloutTypesData),
        }

        const response = await axios(options);
        const {is_success, message} = response.data;

        setShowProgress(false);

        if (is_success) {
          setOpenMessageDialog(true);
          setMessage(message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. There's something wrong with the server.`);
    }
  }

  const resetForm = () => {
    setPulloutTypesData({
      request_code: '',
      pullout_type_arr: [],
      pullout_type_code: '', 
      pullout_type_name: ''
    });
  };

  const getPulloutTypes = async (ignore) => {
    try {
      setPulloutTypesLoading(true)
        const options = {
            method: 'POST',
            url: '/PulloutTypes/getPulloutTypesForRequest.json',
        }

        const response = await axios(options);
        if (!ignore) {
          setPulloutTypes(response.data);
        }
        
      setPulloutTypesLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(() => {
    let ignore = false;
    if (!openPulloutTypes) {
      setPulloutTypes([]);
    }

    if (!openPulloutTypes) return undefined
    getPulloutTypes(ignore)

    return () => {
      ignore = true
    };
  }, [openPulloutTypes]);

  useEffect(() => {
    if (open && requestDetails) {
      const { request_code, pullout_type_name, pullout_type_code } = requestDetails;
      setPulloutTypesData({
        pullout_type_arr: { 'label': pullout_type_name, value: pullout_type_code },
        pullout_type_code: pullout_type_code,
        pullout_type_name: pullout_type_name,
        request_code: request_code,
      });
    }
    
    

  }, [open, requestDetails]);

  const hanldeWarehouseChange = (e, selectValue) => {
    if (selectValue) {
      return setPulloutTypesData(
        (prevAccount) => ({ ...prevAccount, pullout_type_arr: selectValue, pullout_type_code: selectValue.pull_out_type_code, pullout_type_name: selectValue.label})
      )
    }

    setPulloutTypesData(
      (prevAccount) => ({ ...prevAccount, pullout_type_arr: [], pullout_type_code: '', pullout_type_name: ''})
    )
  }

  return (
    <div>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Change Pullout Type</DialogTitle>
      <DialogContent>
          <Grid container spacing={3} mt={1}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  id="combo-box-demo"
                  open={openPulloutTypes}
                  onOpen={() => {setOpenPulloutTypes(true);}}
                  onClose={() => {setOpenPulloutTypes(false);}}
                  loading={PulloutTypesLoading}
                  onChange={hanldeWarehouseChange}
                  options={PulloutTypes}
                  value={requestDetails && pulloutTypesData.pullout_type_arr}
                  noOptionsText={'No Available Data'}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Pullout Type*"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {PulloutTypesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => {
              setOpenMessageDialog(false)
              setOnEditOrDelete((prev) => !prev)
              setOpen(false)
              }}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default ChangePulloutType