import { AppBar, Box, Button, Card, Container, CssBaseline, Paper, Toolbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import { jwtDecode } from 'jwt-decode';
import { AxiosCustomConfigMFA } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';


export default function MFAEmailVerification() {
  const axios = AxiosCustomConfigMFA();
  const navigate = useNavigate();
  const { id, token } = useParams();
  const decodeToken = jwtDecode(token);
  const accessToken = decodeToken.accessToken;  

  const [showProgress, setShowProgress] = useState(false);
  const [showDisplayMsg, setDisplayMsg] = useState('');
  const [displayBtn, setDisplayBtn] = useState(false);


  const verifyEmail = () => {
    setShowProgress(true);
    axios({
      url: 'ApiSettings/verifyEmailMFA.json',
      method: 'POST',
      data: {
        id: id,
        token: token
      },
      headers: {
        'Bypass-Tunnel-Reminder': 'true',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + String(accessToken),
      },
    }).then((res) => {
      const responseData = res.data;
      const message = responseData.message;
      const success = responseData.success;

      if (success) {
        setDisplayBtn('login');
      } else {
        if (message === "Your email address has already been verified.") {
          setDisplayBtn('login');
        } else {
          setDisplayBtn('resend');
        }
      }

      setShowProgress(false);
      setDisplayMsg(message);
    }).catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    
    verifyEmail();
    
  },[]);

  const handleProceedToLogin = () => {
    //  check If Another User Login in other browser
    //  checkUser Already Login in the browser

    navigate('/login');
  }

  const handleResendVerifyEmail = () => {
    // resend email verification
  }

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
          <Typography component="h4" variant="h3" color="inherit" sx={{textAlign: "center"}}>
            Assettrakker
          </Typography>
          <Paper 
            variant="outlined" 
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
            align="center">
              <Typography component="h5" variant="h5">
                Email Verification Confirmation
              </Typography>
              <Typography variant="subtitle1" sx={{m: 1}}>
                {showDisplayMsg}
              </Typography>
              {
                displayBtn === 'login' ? (
                  <Button
                    variant='contained'
                    align="center"
                    onClick={handleProceedToLogin}>
                    Proceed to Login
                  </Button>
                ) : (
                  <Button
                    variant='contained'
                    align="center"
                    onClick={handleResendVerifyEmail}>
                    Resend Email Verification
                  </Button>
                )
              }
          </Paper>
        </Box>
        <ProcessDialog 
            setShowProgress={showProgress}
            ContentText="Verifying Email Please Wait...."
        />
      </Container>
    </>
  )
}
