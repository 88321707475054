import { Alert, Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField, Typography } from '@mui/material';
import React, {useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';
import styled from '@emotion/styled';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';


const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);


function CancelRequest({open, setOpen, requestDetails, setOnEditOrDelete}) {
  const axios = AxiosCustomConfig();
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [warehouseLoading, setWarehouseLoading] = useState(false);
  const [openWarehouse, setOpenWarehouse] = useState(false);
  const [updateData, setUpdateData] = useState({
    request_code: '',
    remarks: ''
  });

  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!updateData.remarks || updateData.remarks === '') {
        setOpenMessageDialog(true);
        setMessage('Remarks is required')
        return
    }


    setShowProgress(true);
    try {
        const options = {
            method: 'POST',
              url: '/PulloutRequests/cancelRequest.json',
              data: objectToFormData(updateData),
        }

        const response = await axios(options);
        const {is_success, message} = response.data;

        setShowProgress(false);

        if (is_success) {
          setOnEditOrDelete((prev) => !prev)
          setOpenMessageDialog(true);
          setMessage(message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. There's something wrong with the server.`);
    }
  }

  const resetForm = () => {
    setUpdateData({
      request_code: '',
      remarks: ''
    });
  };

  const getWarehouses = async(ignore) => {
    try {
      setWarehouseLoading(true)
        const options = {
            method: 'POST',
            url: '/Warehouses/getWarehousesForAutocomplete.json',
        }
        const response = await axios(options);
        if (!ignore) {
          setWarehouses(response.data);
        }
        setWarehouseLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(() => {
    let ignore = false;
    if (!openWarehouse) {
      setWarehouses([]);
    }

    if (!openWarehouse) return undefined
    getWarehouses(ignore)

    return () => {
      ignore = true
    };
  }, [openWarehouse]);

  useEffect(() => {
    if (open && requestDetails) {
      const { remarks, request_code } = requestDetails;
      setUpdateData({
        remarks: remarks,
        request_code: request_code,
      });
    }
    
    

  }, [open, requestDetails]);


  return (
    <div>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Cancel Request</DialogTitle>
      <DialogContent>
          <Grid container spacing={3} mt={1}>
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <Typography>*Remarks:</Typography>
                <Textarea 
                  aria-label="minimum height" 
                  minRows={3} 
                  placeholder="Remarks" 
                  onChange={(e) => setUpdateData((prevAccount) => ({ ...prevAccount, remarks: e.target.value }))}
                  value={updateData.remarks}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => {
              setOpenMessageDialog(false)
              setOpen(false)
              }}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default CancelRequest