import { Box, Chip, Grid, StyledEngineProvider, Typography } from '@mui/material'
import { DataGrid, gridClasses, GridFooterContainer, GridPagination } from '@mui/x-data-grid'
import React, { useEffect } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { useState } from 'react';
import MessageDialog from '../Utils/MessageDialog';
// import AddJobOrder from './AddJobOrder';
import { Link } from 'react-router-dom';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import AddRepairRequest from './AddRepairRequest';
import useAuth from '../../hooks/UseAuth';
import useFetchColumns from '../../hooks/UseFetchColumns';
import useFilteredAndOrderedColumns from '../../hooks/UseFilteredAndOrderedColumns';
import ColumnSettingsButton from '../Utils/ColumnSettingsButton';
import CustomToolbar from '../Utils/CustomToolbar';
import CustomPagination from '../Utils/CustomPagination';
import useFetchPageLength from '../../hooks/UseFetchPageLength';
import useSortModel from '../../hooks/UseSortModel';


const CustomFooter = ({ totalRepairCost, selectedRows}) => {
  const formattedTotal = new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(totalRepairCost);
  
  return (
    <GridFooterContainer>
      {/* Your custom footer content */}
      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'space-between', padding: '8px', backgroundColor: '#f5f5f5' }}>
        {selectedRows > 0 ? (
          <Typography variant="subtitle1">
            {selectedRows} {selectedRows === 1 ? 'row' : 'rows'} selected
          </Typography>
        ) : (
          <Typography variant="subtitle1">
          </Typography>
        )}

        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          Total Repair Cost: {formattedTotal}
        </Typography>
      </Box>
      
      {/* Default pagination footer */}
      <CustomPagination /> 
      {/* <GridPagination/> */}
    </GridFooterContainer>
  );
};

const ShopRepair = () => {
  const {auth} = useAuth();
  const axios = AxiosCustomConfig();
  const [onEditOrDelete, setOnEditOrDelete] = useState(false);
  const [shopRepairsData, setShopRepairsData] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [onSaveColumns, setOnSaveColumns] = useState(false);
  const functionName = 'shop_repair';
  const modelTable = 'ShopRepairs';
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [totalRepairCost, setTotalRepairCost] = useState(0);

  const { paginationModel, setPaginationModel, pageSizeOptions } = useFetchPageLength(functionName, setOpenMessageDialog, setMessage, onSaveColumns);
  
  const [rowCount, setRowCount] = useState(0);
  const [filterModel, setFilterModel] = useState({
    items: [],
  });

  const header_columns = [
    { field: 'id', headerName: 'Id', minWidth: 50, wrap: true,
    },
    { field: 'request_code', headerName: 'SR No.',flex: 1, minWidth: 150, wrap: true,
      renderCell: (params) => {
          const requestCode = params.value; // Get the value of request_code for the current row
          // Render the request_code as a clickable link using an anchor tag
          return (
              <Link to={`/shopRepair/${requestCode}`}>
                  {requestCode}
              </Link>
          );
      }  
    },
    { 
      field: 'status', 
      headerName: 'Request Status',
      flex: 1, 
      minWidth: 100, 
      wrap: true,
      renderCell: (params) => {
        
          const statusName = params.value;
          if (statusName === 0) {
            return <Chip label='For Approval' sx={{ backgroundColor: '#35A7CA' }} />;
          } else if (statusName === 1) {
            return <Chip label='Processing' sx={{ backgroundColor: '#D7D6D4'}} />;
          } else if (statusName === 2) {
            return <Chip label='Sr form for completion' sx={{backgroundColor: '#F8FD6A', color: '#000'}} />;
          } else if (statusName === 3) {
            return <Chip label='Asset for scrap' sx={{backgroundColor: '#DD1F00', color: '#fff' }} />;
          } else if (statusName === 4) {
            return <Chip label='Rejected' sx={{ backgroundColor: '#000001', color: '#fff' }} />;
          } else if (statusName === 5) {
            return <Chip label='Closed' color='success' />;
          } else if (statusName === 6) {
            return <Chip label='Cancelled' sx={{ backgroundColor: '#D2893C' }} />;
          } else if (statusName === 7) {
            return <Chip label='For Billing'  sx={{ backgroundColor: '#2174AE', color: '#fff' }} />;
          } else if (statusName === 8) {
            return <Chip label='Invoiced' sx={{ backgroundColor: '#661B66', color: '#fff' }} />;
          } else if (statusName === 9) {
            return <Chip label='Repair on-hold' sx={{backgroundColor: '#F8FD6A', color: '#000'}} />;
          } else if (statusName === 10) {
            return <Chip label='Repaired at shop' sx={{ backgroundColor: '#3AD658' }} />;
          }
          
      }
  },
    { field: 'warehouse_name', headerName: 'Warehouse name',flex: 1, minWidth: 150,},
    { field: 'services', headerName: 'Services',flex: 1, minWidth: 150,
        renderCell: (params) => {
          if(params.value) {
            const services = params.value.split(',').map(service => service.trim());
            return (
                <>
                  <Grid container direction='column'>
                    {services.map((service, index) => (
                      <Grid key={index}>
                        {service}
                      </Grid>
                    ))}
                  </Grid>
                </>
            );
          }
          return null
      },
    },
    { field: 'asset_code', headerName: 'Asset for Evaluation',flex: 1, minWidth: 150,},
    { field: 'requestor_name', headerName: 'Requested by',flex: 1, minWidth: 150,},
    { field: 'remarks', headerName: 'Remarks',flex: 1, minWidth: 150,},
    { field: 'repair_cost', headerName: 'Repair Cost',flex: 1, minWidth: 150,},
  ];

  const columns = useFetchColumns(functionName, onSaveColumns, setOpenMessageDialog, setMessage);
  const filteredAndOrderedColumns = useFilteredAndOrderedColumns(columns, header_columns);
  const [sortModel, setSortModel] = useSortModel(columns);

  const filteredColumnsForRemarks = filteredAndOrderedColumns.filter(column => {
    if (Number(auth.role) !== 2 && column.field === 'remarks') {
      return false;
    }
    return true;
  });

  useEffect(() => {
    const getAllData = async (paginationModel, filterModel, sortModel) => {
      setLoading(true)
      try {
        const options = {
          'method': 'POST',
          'url' : '/ShopRepairs/getAllShopRepairRequest.json',
          'data': {
            page: paginationModel.page, 
            pageSize: paginationModel.pageSize,
            filters: filterModel.items,
            sort: sortModel,
          }
        }
        const response = await axios(options);
        const { data, count } = response.data;

        setShopRepairsData(data)
        setRowCount(count)
        setLoading(false)
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Please try again and make sure you have internet connection.`);
      }
    }

    getAllData(paginationModel, filterModel, sortModel);
  }, [onEditOrDelete, paginationModel, filterModel, sortModel]);

  const handleClickAllData = async () => {
    if(!selectAll) {
      try {
        setLoading(true);
        const options = {
          method: 'POST',
          url: '/ShopRepairs/getAllShopRepairRequestId.json',
        };
        const response = await axios(options);
        
        const allDataID = response.data.map((row) => row.id);
        
        setRowSelectionModel(allDataID);
        setSelectAll(true);
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(`${error.toJSON().message}. Please try again and make sure you have an internet connection.`);
      } finally {
        setLoading(false);
      }
    } else {
      setRowSelectionModel([]);
      setSelectAll(false);
    }
  }

  useEffect(() => {
    const getTotalRepairCost = async () => {
      try {
        const options = {
          'method': 'POST',
          'url' : '/ShopRepairs/getTotalRepairCost.json',
        }
        const response = await axios(options);
        const { totalRepairCost } = response.data;

        setTotalRepairCost(totalRepairCost)
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Please try again and make sure you have internet connection.`);
      }
    }

    getTotalRepairCost();
  }, []);

  const handleFilterChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
  };

  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  };

  return (
    <>
    <Box sx={{ marginBottom: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center'  }}>
      <Box sx={{ display: 'flex', gap: '10px' }}>
      <AddRepairRequest setOnEditOrDelete={setOnEditOrDelete}/>
      </Box>
      
      {/* Spacer */}
      <Box sx={{ flexGrow: 1 }} />

      {/* Settings Icon and Menu */}
      <Box sx={{ position: 'relative' }}>
        <ColumnSettingsButton
          columns={columns}
          setOnSaveColumns={setOnSaveColumns}
          functionName={functionName}
          paginationModel={paginationModel}
        />
      </Box>
    </Box>
    <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
      <DataGrid
        keepNonExistentRowsSelected 
        rows={shopRepairsData}
        columns={filteredColumnsForRemarks}
        getRowHeight={() => 'auto'}
        pageSizeOptions={pageSizeOptions}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          toolbar: () => <CustomToolbar 
            selectedRows={rowSelectionModel} 
            columns={columns}
            setOnSaveColumns={setOnSaveColumns}
            functionName={functionName}
            modelTable={modelTable}
            handleClickAllData={handleClickAllData}
            selectAll={selectAll}
          />,
          footer: () => <CustomFooter totalRepairCost={totalRepairCost} selectedRows={rowSelectionModel.length}/>
        }}
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        disableRowSelectionOnClick
        autoHeight
        loading={loading}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            py: '15px',
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            py: '22px',
          },
          '& .MuiDataGrid-columnHeaderCheckbox': {
            opacity: 0.5, 
            pointerEvents: 'none',
            cursor: 'not-allowed'
          }
        }}
        slotProps={{
          footer: { totalRepairCost },  // Pass the total repair cost to the footer component
        }}
        pagination
        paginationMode="server"
        filterMode="server"
        sortingMode="server"
        rowCount={rowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        filterModel={filterModel}
        onFilterModelChange={handleFilterChange}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        sortingOrder={['asc', 'desc']}
      />
    </Box>
    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </>
  )
}

export default ShopRepair