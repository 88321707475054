import { Backdrop, Box, CssBaseline, Fade, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import { teal } from '@mui/material/colors';
import AddPrincipal from './AddPrincipal';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { format } from 'date-fns';
import EditPrincipal from './EditPrincipal';
import DeleteUsers from '../Users/DeleteUsers';




export const PrincipalAccounts = () => {
  const axios = AxiosCustomConfig();
  const [accounts, setAccounts] = useState([]);
  const [onEditOrDelete, setOnEditOrDelete] = useState(false);

  const columns = [
    { field: 'company_id', headerName: 'Company id', flex: 1, minWidth: 150},
    { field: 'username', headerName: 'Username',flex: 1, minWidth: 150},
    { field: 'email_address', headerName: 'Email address',flex: 1, minWidth: 150},
    { field: 'first_name', headerName: 'First name',flex: 1, minWidth: 150},
    { field: 'last_name', headerName: 'last name',flex: 1, minWidth: 150},
    { field: 'created', headerName: 'Created',flex: 1, 
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }, minWidth: 150
    },
    { field: 'modified', headerName: 'Modified',flex: 1, 
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }, minWidth: 150
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <>
          <EditPrincipal usersData={params.row} setOnEditOrDelete={setOnEditOrDelete}/>
          <DeleteUsers usersData={params.row} setOnEditOrDelete={setOnEditOrDelete}/>
        </>
      ),
    },
  ];
  useEffect(() => {
    const getAccounts = async () => {
      try {
        const options = {
          'medthod': 'POST',
          'url' : '/ApiUsers/getPrincipalAccounts.json',
        }
        const response = await axios(options);
        const data = response.data.result;
        setAccounts(data)
      } catch (error) {
        console.log(error);
      }
    }

    getAccounts();
  }, [onEditOrDelete]);


  return (
    <>
    <Box sx={{ marginBottom: '10px' }}>
      {/* add principal button */}
      <AddPrincipal setOnEditOrDelete={setOnEditOrDelete}/>
    </Box>
    <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
      <DataGrid
        rows={accounts}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        // pageSizeOptions={[5, 10]}
      />
    </Box>
    </>
  )
}
