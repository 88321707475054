import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useAuth from '../hooks/UseAuth';

const ProtectedRoutes = ({ allowedRoles }) => {
    const {auth } = useAuth();

    return (
        auth.token ? (
            allowedRoles.includes(Number(auth.role)) ? <Outlet /> : <Navigate to="/unauthorized" replace/>
        ) : (
            <Navigate to="/login" />
        )
    )
}

export default ProtectedRoutes