import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import axios from 'axios';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Paper,
  TextField,
  Button,
  CircularProgress,
  TableSortLabel,
  TableFooter,
  Box,
} from '@mui/material';
import { AxiosCustomConfig } from '../../../config/AxiosCustomConfig';
import ProcessDialog from '../../Utils/ProcessDialog';
import MessageDialog from '../../Utils/MessageDialog';
import CustomModal from '../../Utils/CustomModal';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';

export default forwardRef(function MFASettingsServerSideTable(props, ref) {
  const axios = AxiosCustomConfig();
  const token = localStorage.getItem('token');
  
  const [viewUserMFA, setViewUserMFA] = useState({
    system_username: '',
    email_username: '',
    old_email_username: '',
    id: ''
  });
  const [deleteUserMFA, setDeleteUserMFA] = useState({
    system_username: '',
    id: ''
  })

  const [showProgress, setShowProgress] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [openCustomModal, setOpenCustomModal] = useState(false);
  const [openDeleteCustomModal, setOpenDeleteCustomModal] = useState(false);
  const [unauthorizedMessageDialog, setUnauthorizedMessageDialog] = useState('');
  
  // Datatable State
  const [loading, setLoading] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [rowsTotalCount, setRowsTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState({ field: 'id', order: 'asc' });


  const columnsData = [
    {
      id: "email_username",
      headerName: "User Email Address",
    },
    {
      id: "system_username",
      headerName: "Username",
    },
    {
      id: "verified",
      headerName: "Verified",
    },
    {
      id: "settings",
      headerName: "Settings",
    },
    {
      id: "modified",
      headerName: "Modified",
    },
    {
      id: "''",
      headerName: "Action",
    }
  ];

  useEffect(() => {
    // Create an AbortController instance
    let isFetchingData = false;
    
    getMFASettingsDatatable(isFetchingData);

    return () => {
      isFetchingData = true;
    };
  },[page, rowsPerPage, sort.field, sort.order, searchValue]);

  // visible all the need methods for parent component
  useImperativeHandle(ref, () => {
    return {
      getMFASettingsDatatable: getMFASettingsDatatable,
    };
  }, []);

  const getMFASettingsDatatable = (isFetchingData) => {
    setLoading(true);

    axios({
      url: 'ApiSettings/getMFAEmailDatatable.json',
      method: 'POST',
      data: {
        page: page,
        limit: rowsPerPage,
        search: searchValue,
        sort: {
          field: sort.field,
          order: sort.order
        }
      },
    }).then((res) => {
      if (!isFetchingData) {
        setLoading(false);
        setRowsData(res.data.data);
        setRowsTotalCount(res.data.recordsTotal);
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value)
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSortChange = (field) => {
    const newOrder = sort.field === field && sort.order === 'asc' ? 'desc' : 'asc';
    setSort({ field, order: newOrder });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSendEmailVerification = (id, email) => {
    setShowProgress(true);

    axios({
      url: 'ApiSettings/sendUserEmailVerifyMFA.json',
      method: 'POST',
      data: {
        id: id,
        email_username: email,
        token: token
      }
    }).then((res) => {
      const responseData = res.data;
      const message = responseData.message;

      setShowProgress(false);

      setModalMessage(message);
      setIsDialogOpen(true);
      getMFASettingsDatatable();
    }).catch((err) => {
      setShowProgress(false);

      console.log(err);

      /* if (err.response.status !== undefined && err.response.status === 500) {
        setModalMessage(err.response.statusText);
        setIsDialogOpen(true);
      } */
    });
  }

  const enableMFASettings = (id, settings) => {
    const newPost = {
        id: id,
        settings: settings === "ON" ? 0 : 1
    };

    setShowProgress(true);

    axios({
        method: 'POST',
        url: 'ApiSettings/enableMFASettings.json',
        data: newPost
    }).then(function (res) {
        const message = res.data.message;
        const success = res.data.success;

        setShowProgress(false);

        if (res.status === 200 && success) {
            setIsDialogOpen(true);
            setModalMessage(message);
            getMFASettingsDatatable();
        }
    }).catch(error => {
        console.log(error.response.data);
    });
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(prev => !prev);
  };

  const handleModalClose = () => {
    setOpenCustomModal(false);
  }

  const handleViewUserMFA = (id, email, username) => {
    setOpenCustomModal(true);
    setViewUserMFA({
      system_username: username,
      email_username: email,
      id: id,
      old_email_username: email
    });
  }

  const handleUpdateChangeUserMFA = (e) => {
    const { value } = e.target;

    setViewUserMFA((prevState) => ({...prevState, email_username: value}));
  }

  const handleUpdateUserMFA = () => {

    setShowProgress(true);

    axios({
      url: 'ApiSettings/updateUserMFA.json',
      method: 'POST',
      data: viewUserMFA
    }).then((res) => {
        const message = res.data.message;
        const success = res.data.success;

        setShowProgress(false);

        if (res.status === 200 && success) {
            setIsDialogOpen(true);
            setModalMessage(message);
            getMFASettingsDatatable();
            
            setViewUserMFA({
              system_username: '',
              email_username: '',
              old_email_username: '',
              id: ''
            });

            setOpenCustomModal(false);
        }
    }).catch((err) => {

    });
  }

  const handleAlertDeleteUserMFA = (id, username) => {
    setOpenDeleteCustomModal(true);

    setDeleteUserMFA({
      system_username: username,
      id: id
    })
  }

  const handleAlertDeleteCloseModal = () => {
    setOpenDeleteCustomModal(false);
  }
  
  const handleDeleteUserMFA = () => {
    setShowProgress(true);

    axios({
      url: 'ApiSettings/deleteUserMFA.json',
      method: 'POST',
      data: deleteUserMFA
    }).then((res) => {
        const message = res.data.message;
        const success = res.data.success;

        setShowProgress(false);

        if (res.status === 200 && success) {
            setIsDialogOpen(true);
            setModalMessage(message);
            getMFASettingsDatatable();
            
            setDeleteUserMFA({
              system_username: '',
              id: ''
            })

            setOpenDeleteCustomModal(false);
        }
    }).catch((err) => {

    });
  }

  const formElementsUpdate = [
      <TextField 
          name="system_username" 
          label="Username" 
          type="text"
          fullWidth 
          required
          value={viewUserMFA.system_username}
          disabled
      />,
      <TextField 
          name="email_username" 
          label="User Email Address" 
          type="text" 
          fullWidth
          required
          value={viewUserMFA.email_username}
          onChange={handleUpdateChangeUserMFA}
      />
  ];

  return (
    <Paper sx={{ overflow: "auto" }}>
        <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={searchValue}
            onChange={handleSearchChange}
            sx={
              {m: 1}
            }
          />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {columnsData.map((column) => (
                    <TableCell key={column.id}>
                      <TableSortLabel
                        active={sort.field === column.id}
                        direction={sort.field === column.id ? sort.order : 'asc'}
                        onClick={() => handleSortChange(column.id)}
                      >
                        {column.headerName}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                    <TableRow>
                      <TableCell colSpan={columnsData.length + 1} style={{ textAlign: 'center' }}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  ) : (
                    rowsData.length > 0 ? ( 
                      rowsData.map((item, i) => (
                        <TableRow key={i}>
                          <TableCell>{item.email_username}</TableCell>
                          <TableCell>{item.system_username}</TableCell>
                          <TableCell>{item.verified}</TableCell>
                          <TableCell>{item.settings}</TableCell>
                          <TableCell>{item.modified}</TableCell>
                          <TableCell>
                            {
                              item.verified === "Yes" ? (
                                <>
                                  <Button
                                    variant="outlined"
                                    color="secondary" 
                                    onClick={() => handleViewUserMFA(item.id, item.email_username, item.system_username)}
                                    sx={{m:1}}
                                    size="medium">
                                      Update Email
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    color={item.settings === "ON" ? "error" : "success"} 
                                    onClick={() => enableMFASettings(item.id, item.settings)}
                                    sx={{m:1}}
                                    size="medium">
                                    {item.settings === "ON" ? "Disable" : "Enable"} MFA
                                  </Button>
                                </>
                              ) : (
                                <Button
                                  variant="outlined"
                                  color="success" 
                                  onClick={() => handleSendEmailVerification(item.id, item.email_username)}
                                  sx={{m:1}}
                                  size="medium">
                                    Send Email Verification
                                </Button>
                              )
                            }
                            <Button
                              variant="outlined"
                              color="error" 
                              onClick={() => handleAlertDeleteUserMFA(item.id, item.system_username)}
                              sx={{m:1}}
                              size="medium">
                                Delete
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={columnsData.length + 1}>
                          No data available
                        </TableCell>
                      </TableRow>
                    )
                  )
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    colSpan={6}
                    count={rowsTotalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          <CustomModal
             open={openCustomModal}
             handleClose={handleModalClose}
             title="Update MFA Email Address"
             formElements={formElementsUpdate}
             isForm={true}
             onSubmit={handleUpdateUserMFA}
          />
          <CustomModal
             open={openDeleteCustomModal}
             handleClose={handleAlertDeleteCloseModal}
             contentText="Are you sure you want to Delete ?"
             isForm={false}
             onSubmit={handleDeleteUserMFA}
             submitText="Yes"
          />
          <ProcessDialog
              showProgress={showProgress}
              ContentText="Verifying User Email Please Wait..."
          />
          <MessageDialog
            open={isDialogOpen}
            message={modalMessage}
            handleClose={handleCloseDialog}
          />
      </Box>
    </Paper>
  )
});
