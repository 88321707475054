import { Alert, Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField, Typography } from '@mui/material';
import React, {useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';
import styled from '@emotion/styled';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';

function SetSpBranch({open, setOpen, requestDetails, setDoneUpdateRequest}) {
  const axios = AxiosCustomConfig();
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [warehouseLoading, setWarehouseLoading] = useState(false);
  const [openWarehouse, setOpenWarehouse] = useState(false);
  const [warehouseData, setWarehouseData] = useState({
    request_code: '',
    warehouse: [],
    warehouse_unique_code: '', 
    warehouse_name: ''
  });

  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!warehouseData.warehouse_unique_code) {
        setOpenMessageDialog(true);
        setMessage('Warehouse branch is required')
        return
    }


    setShowProgress(true);
    try {
        const options = {
            method: 'POST',
              url: '/StoreToStoreTransfers/setSpBranchCentigen.json',
              data: objectToFormData(warehouseData),
        }

        const response = await axios(options);
        const {is_success, message} = response.data;

        setShowProgress(false);

        if (is_success) {
          setDoneUpdateRequest((prev) => !prev)
          setOpenMessageDialog(true);
          setMessage(message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. There's something wrong with the server.`);
    }
  }

  const resetForm = () => {
    setWarehouseData({
      equipment_no: '',
      warehouse: [],
      warehouse_unique_code: '', 
      warehouse_name: ''
    });
  };

  const getWarehouses = async(ignore) => {
    try {
      setWarehouseLoading(true)
        const options = {
            method: 'POST',
            url: '/Warehouses/getWarehousesForAutocomplete.json',
        }
        const response = await axios(options);
        if (!ignore) {
          setWarehouses(response.data);
        }
        setWarehouseLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(() => {
    let ignore = false;
    if (!openWarehouse) {
      setWarehouses([]);
    }

    if (!openWarehouse) return undefined
    getWarehouses(ignore)

    return () => {
      ignore = true
    };
  }, [openWarehouse]);

  useEffect(() => {
    if (open && requestDetails) {
      const { asset_code, warehouse_name, warehouse_unique_code, request_code } = requestDetails;
      setWarehouseData({
        warehouse: (warehouse_name && warehouse_unique_code) 
        ? { label: `${warehouse_name} - (${warehouse_unique_code})`, value: warehouse_unique_code }
        : {},
        warehouse_unique_code: warehouse_unique_code,
        warehouse_name: warehouse_name,
        asset_code: asset_code,
        request_code: request_code,
      });
    }

  }, [open, requestDetails]);

  const hanldeWarehouseChange = (e, selectValue) => {
    if (selectValue) {
      return setWarehouseData(
        (prevAccount) => ({ ...prevAccount, warehouse: selectValue, warehouse_unique_code: selectValue.warehouse_unique_code, warehouse_name: selectValue.warehouse_name})
      )
    }

    setWarehouseData(
      (prevAccount) => ({ ...prevAccount, warehouse: [], warehouse_unique_code: '', warehouse_name: ''})
    )
  }

  return (
    <div>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Set SP Branch</DialogTitle>
      <DialogContent>
        <Alert severity="warning">
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant="body2" color="initial">Siguraduhin tama ang filter at ang mga na select na Request.Ang lahat ng na select na request ay ma-aasign sa pipiliin na SP branch. Mahirap mag correct pag nagkamali</Typography>

          </Box>
        </Alert>
          <Grid container spacing={3} mt={1}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  id="combo-box-demo"
                  open={openWarehouse}
                  onOpen={() => {setOpenWarehouse(true);}}
                  onClose={() => {setOpenWarehouse(false);}}
                  loading={warehouseLoading}
                  onChange={hanldeWarehouseChange}
                  options={warehouses}
                  value={requestDetails && warehouseData.warehouse}
                  noOptionsText={'No Available Data'}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Warehouse Branch"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {warehouseLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => {
              setOpenMessageDialog(false)
              setOpen(false)
              }}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default SetSpBranch