import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography, IconButton, Menu, MenuItem } from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AddIcon from '@mui/icons-material/Add';
import ColumnSettingsButton from "../Utils/ColumnSettingsButton";
import { useEffect, useState } from "react";
import { AddBox, ArrowForwardIos, Delete } from "@mui/icons-material";
import AddFilter from "./AddFilter";
import { AxiosCustomConfig } from "../../config/AxiosCustomConfig";

export default function AssetFilters({ filterData, setOnEditOrDelete, columns, setOnSaveColumns, functionName, paginationModel }) {
  const axios = AxiosCustomConfig();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openFilter, setOpenFilter] = useState(false);
  const [localFilterData, setLocalFilterData] = useState([]);

  useEffect(() => {
    let ignore = true;
  
    const groupByFilterName = (data) => {
      return data.reduce((acc, curr) => {
        // Initialize the filter_name if it doesn't exist
        if (!acc[curr.filter_name]) {
          acc[curr.filter_name] = { filter_condition: curr.filter_condition, value: null };
        }

        // If it's a date range filter, merge from_date and to_date into one value
        if (curr.name === "from_date") {
          acc[curr.filter_name].value = `${curr.value}`; // Initialize with from_date
        } else if (curr.name === "to_date" && acc[curr.filter_name].value) {
          acc[curr.filter_name].value = `${acc[curr.filter_name].value} - ${curr.value}`;
        } else if (curr.name !== "from_date" && curr.name !== "to_date") {
          // If it's not a date range, store the single value
          acc[curr.filter_name].value = curr.value;
        }
    
        return acc;
      }, {});
    };
  
    if (ignore && filterData) {
      const groupedData = groupByFilterName(filterData);
      setLocalFilterData(groupedData);
    }
  
    return () => {
      ignore = false;
    };
  }, [filterData]);
  
  const handleMenuClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleButtonClick = (event) => {
    event.stopPropagation(); // Prevents the click from propagating to AccordionSummary
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
    setAnchorEl(null);
  }

  const handleRemoveAllfilter = async () => {
    try {
          
      const options = {
          method: 'POST',
            url: '/FilterSettings/removeAllFilter.json',
            data: {function_name: functionName},
      }

      const response = await axios(options);
      if (response.data.is_success) {
        setOnEditOrDelete((prev) => !prev)
      }

    } catch (error) {
      console.log(` ${error.toJSON().message}. There's an error from the server. Please contact your developer.`);
    }
  }

  const handleRemoveFilter = async (filterName) => {
    try {
          
      const options = {
          method: 'POST',
            url: '/FilterSettings/removeFilter.json',
            data: {function_name: functionName, filter_name: filterName},
      }

      const response = await axios(options);
      if (response.data.is_success) {
        setOnEditOrDelete((prev) => !prev)
      }

    } catch (error) {
      console.log(` ${error.toJSON().message}. There's an error from the server. Please contact your developer.`);
    }
  };
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box sx={{ position: 'relative' }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ paddingRight: '50px', position: 'relative', display: 'flex', alignItems: 'center' }}
            >
              {/* Add Icon Button */}
              <IconButton
                // sx={{ position: 'absolute', top: '50%', left: -40, transform: 'translateY(-50%)' }}
                onClick={handleMenuClick}
              >
                <AddBox fontSize="small" />
              </IconButton>

              {/* Menu */}
              {/* Accordion content with Filters text */}
              <Typography variant="body1" paddingTop={0.6}>Filters</Typography>

              {/* Position ColumnSettingsButton absolutely within the AccordionSummary */}
              <Box sx={{ position: 'absolute', top: '50%', right: 0, transform: 'translateY(-50%)', zIndex: 1 }} onClick={handleButtonClick}>
                <ColumnSettingsButton
                  columns={columns}
                  setOnSaveColumns={setOnSaveColumns}
                  functionName={functionName}
                  paginationModel={paginationModel}
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {Object.keys(localFilterData).length > 0 && (
                <Box display="flex" alignItems="center" gap={1}>
                  <IconButton 
                    title="Remove All Filter" 
                    color="error" 
                    sx={{ marginLeft: 2 }}
                    onClick={handleRemoveAllfilter}
                  >
                    <Delete fontSize="small"/>
                  </IconButton>
                  {Object.entries(localFilterData).map(([filterName, filterDetails]) => (
                    <Box key={filterName}>
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{ cursor: 'pointer', border: '1px solid #ccc', borderRadius: '8px', padding: '3px', paddingLeft: '8px' }}
                        title="Edit"
                      >
                        <Typography variant="body2" fontWeight="bold" sx={{ fontSize: '0.875rem' }}>
                          {filterName.replace(/_/g, ' ')}
                        </Typography>
                        <ArrowForwardIos fontSize="small" />
                        <Typography variant="body2" sx={{ marginLeft: 1, fontSize: '0.75rem' }}>
                        {filterName === 'system_status' 
                            ? `${filterDetails.filter_condition ? `${filterDetails.filter_condition} : ` : ''}${{
                                  '0': 'Active',
                                  '1': 'Deleted',
                                  '2': 'Inactive',
                                  '3': 'Write-off',
                              }[filterDetails.value] || filterDetails.value}`
                            : `${filterDetails.filter_condition ? `${filterDetails.filter_condition.replace(/_/g, ' ')} : ` : ''}${filterDetails.value}`}

                        </Typography>
                        <IconButton title="Remove One Filter" sx={{ marginLeft: 2 }} onClick={() => handleRemoveFilter(filterName)}>
                          <Delete fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        </Box>


        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            sx={{ '& .MuiMenu-paper': { padding: 0 } }}
            >
            <MenuItem onClick={handleOpenFilter}>Add Filter</MenuItem>
        </Menu>

        {openFilter && <AddFilter open={openFilter} setOpen={setOpenFilter} setOnEditOrDelete={setOnEditOrDelete}/>}
      </Grid>
    </Grid>
  );
}
