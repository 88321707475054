import { Box, Button, StyledEngineProvider } from '@mui/material'
import { DataGrid, gridClasses } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../../config/AxiosCustomConfig';
import CustomNoRowsOverlay from '../../Utils/CustomNoRowsOverlay';
import MessageDialog from '../../Utils/MessageDialog';
import { format } from 'date-fns';
import AddTapsBtn from './AddTapsBtn';


const UploadBlankTaps = () => {
  const axios = AxiosCustomConfig();
  const [fetchData, setFetchData] = useState([]);
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [doneUpload, setDoneUpload] = useState(false);

  const header_columns = [
    { field: 'id', headerName: 'Id', flex: 1, minWidth: 150 },
    { field: 'file_name', headerName: 'File name', flex: 1, minWidth: 150 },
    { field: 'file_code', headerName: 'File code', flex: 1, minWidth: 150 },
    { field: 'created', headerName: 'Created',flex: 1, minWidth: 150,
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }, wrap: true 
    },
    { field: 'modified', headerName: 'Modified',flex: 1, minWidth: 200,  
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }, wrap: true 
    },
  ];

  useEffect(() => {
    const getTapsData = async () => {
      setLoading(true);
      try {
        const options = {
          method: 'POST',
          url: '/RequestBlankFiles/getAllFiles.json',
          data: {file_name : 'blank taps file'}
        };
        const response = await axios(options);
        const data = response.data;
        
        setFetchData(data);
        setLoading(false);
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(`${error.toJSON().message}. Please try again and make sure you have an internet connection.`);
      }
    };

    getTapsData();
  }, [doneUpload]);


  return (
    <>
      <Box sx={{ marginBottom: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <AddTapsBtn setDoneUpload={setDoneUpload}/>
      </Box>
      <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
        <DataGrid
          keepNonExistentRowsSelected
          rows={fetchData}
          columns={header_columns}
          getRowHeight={() => 'auto'}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10]}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          disableRowSelectionOnClick
          autoHeight
          loading={loading}
          sx={{
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '15px',
            },
            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
              py: '22px',
            },
            '& .MuiDataGrid-columnHeaderCheckbox': {
              opacity: 0.5, 
              pointerEvents: 'none',
              cursor: 'not-allowed'
            }
          }}
        />
      </Box>

      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose={() => setOpenMessageDialog(false)}
          message={message}
        />
      </StyledEngineProvider>
    </>
  );
};

export default UploadBlankTaps;
