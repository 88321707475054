import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { ListItemIcon, useTheme } from '@mui/material';
import { Check, Construction, DoNotDisturb, Edit, ExpandMore, ToggleOn } from '@mui/icons-material';
import ChangeDeploymentType from './ChangeDeploymentType';
import ChangeStatus from './ChangeStatus';
import SetSpBranch from './SetSpBranch';
import CancelRequest from './CancelRequest';

export default function MoreOptionBtn({requestDetails, setOnEditOrDelete}) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openChangeDepType, setOpenChangeDepType] = useState(false);
  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [openSetSPBranch, setOpenSetSPBranch] = useState(false);
  const [openCancelRequest, setOpenCancelRequest] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const hanldeOpenDeployementType = () => {
    setOpenChangeDepType(true);
    setAnchorEl(null);
  }

  const handleOpenSetSPBranch = () => {
    setOpenSetSPBranch(true);
    setAnchorEl(null);
  }

  const handleOpenChangeStatus = () => {
    setOpenChangeStatus(true);
    setAnchorEl(null);
  }
  const handleOpenCancelRequest = () => {
    setOpenCancelRequest(true);
    setAnchorEl(null);
  }

  return (
    <div>
      <Button
        variant="outlined" 
        size="small" 
        sx={{color: theme.palette.secondary.dark}} 
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        More actions <ExpandMore fontSize='small'/>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={hanldeOpenDeployementType}>
          <ListItemIcon>
            <Edit fontSize="small"/>
          </ListItemIcon> Change Deployment type
        </MenuItem>
        <MenuItem onClick={handleOpenChangeStatus}>
          <ListItemIcon>
            <Construction fontSize="small"/>
          </ListItemIcon>Change Status
        </MenuItem>
        <MenuItem onClick={handleOpenSetSPBranch}>
          <ListItemIcon>
            <Construction fontSize="small"/>
          </ListItemIcon>Set SP Branch
        </MenuItem>
        <MenuItem sx={{ color: '#f6bf26' }} onClick={handleOpenCancelRequest}>
          <ListItemIcon>
            <DoNotDisturb fontSize="small" sx={{ color: '#f6bf26' }} />
          </ListItemIcon>Cancel Request
        </MenuItem>
      </Menu>

      {openChangeDepType && <ChangeDeploymentType open={openChangeDepType} setOpen={setOpenChangeDepType} requestDetails={requestDetails} setOnEditOrDelete={setOnEditOrDelete}/>}
      {openChangeStatus && <ChangeStatus open={openChangeStatus} setOpen={setOpenChangeStatus} requestDetails={requestDetails} setOnEditOrDelete={setOnEditOrDelete}/>}
      {openSetSPBranch && <SetSpBranch open={openSetSPBranch} setOpen={setOpenSetSPBranch} requestDetails={requestDetails} setOnEditOrDelete={setOnEditOrDelete}/>}
      {openCancelRequest && <CancelRequest open={openCancelRequest} setOpen={setOpenCancelRequest} requestDetails={requestDetails} setOnEditOrDelete={setOnEditOrDelete}/>}
    </div>
  );
}