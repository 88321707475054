import { Autocomplete, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField, Box, styled, Typography, CircularProgress } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData, validateAlphaNumeric } from '../GenericFunctions/GenericFunctions';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import useAuth from '../../hooks/UseAuth';
import { CloudUpload } from '@mui/icons-material';

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function AddRequest({setOnEditOrDelete}) {
  const {auth} = useAuth();
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [requests, setRequests] = useState({
    delivery_date: [],
    deployment_type: [],
    asset_size: [],
    asset_type: [],
    store_account: [],
    store_contact_no: '',
    store_contact_name: '',
    remarks: '',
    signature_image: '',
    talaf_file: '',
    account_code: '',
    asset_size_code: '',
    unique_code: '',
    asset_type_code: '',
    warehouseSelected: [], 
    warehouse: [],
    warehouse_unique_code: '', 
    warehouse_name: ''
  });
  const [deploymentTypes, setDeploymentTypes] = useState([]);
  const [assetTypes, setAssetTypes] = useState([]);
  const [assetSize, setAssetSize] = useState([]);
  const [stores, setStores] = useState([]);
  const [loadingStore, setLoadingStore] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [warehouseLoading, setWarehouseLoading] = useState(false);
  const [openWarehouse, setOpenWarehouse] = useState(false);

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateRequiredFields()) return

    if (!isValidSpecialCharacter(requests.store_contact_name)) {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in Store Contact Name.')
      return
    }
    if (!isValidSpecialCharacter(requests.remarks) && requests.remarks !== '') {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in Remarks.')
      return
    }

    if (!requests.talaf_file) {
      setOpenMessageDialog(true);
      setMessage('Please upload TALAF documents')
      return
    }

    setShowProgress(true);
    try {
        const options = {
            method: 'POST',
              url: '/DeploymentRequests/addRequest.json',
              data: objectToFormData(requests),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          resetForm();
          setOnEditOrDelete((prev) => !prev)
          setOpen(false)
          setOpenMessageDialog(true);
          setMessage(response.data.message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Please try again and make sure you have internet connection.`);
    }
  }


  const resetForm = () => {
    setRequests({
      delivery_date: null,
      deployment_type: null,
      asset_size: null,
      asset_type: null,
      store_account: null,
      store_contact_no: '',
      store_contact_name: '',
      talaf_file: '',
      account_code: '',
      asset_size_code: '',
      unique_code: '',
      asset_type_code: '',
      warehouseSelected: [], 
      warehouse: [],
      warehouse_unique_code: '', 
      warehouse_name: ''
    });
  };

  const getAssetSizes = async (ignore) => {
    try {
        const options = {
            method: 'POST',
            url: '/AssetSizes/getAssetSizesForRequest.json',
        }

        const response = await axios(options);

        if(!ignore) {
          setAssetSize(response.data);
        }

    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getDeploymentTypes = async (ignore) => {
    try {
        const options = {
            method: 'POST',
            url: '/DeploymentTypes/getDeploymentTypesForRequest.json',
        }

        const response = await axios(options);
        if (!ignore) {
          setDeploymentTypes(response.data);
        }
        

    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getAssetTypes = async (ignore) => {
    try {
        
        const options = {
            method: 'POST',
            url: '/AssetTypes/getAssetTypeNameAndCode.json',
        }

        const response = await axios(options);
        if (!ignore) {
          setAssetTypes(response.data);
        }
        

    } catch (error) {
      console.log('Error info: ', error);
    }
  }
  const getStores = async (ignore) => {
    try {
      setLoadingStore(true)
        const options = {
            method: 'POST',
            url: '/ApiAccounts/getAllStoresForRequest.json',
        }

        const response = await axios(options);
        if (!ignore) {
          setStores(response.data);
        }
        
        setLoadingStore(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getWarehouses = async(ignore) => {
    try {
      setWarehouseLoading(true)
        const options = {
            method: 'POST',
            url: '/Warehouses/getWarehousesForAutocomplete.json',
        }
        const response = await axios(options);
        if (!ignore) {
          setWarehouses(response.data);
        }
        setWarehouseLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }


  const handleDelpoymentTypeChange = (e, selectValue) => {
    if (selectValue) {
      setRequests((prevData) => ({ ...prevData, deployment_type: selectValue, unique_code: selectValue.unique_code}))
    }
  }

  const handleAssetSizeChange = (e, selectValue) => {
    if (selectValue) {
      setRequests((prevData) => ({ ...prevData, asset_size: selectValue, asset_size_code: selectValue.asset_size_code}))
    }
  }

  const handleAssetTpyesChange = (e, selectValue) => {
    if (selectValue) {
      setRequests((prevData) => ({ ...prevData, asset_type: selectValue, asset_type_code: selectValue.asset_type_code}))
    }
  }
  const handleStoreAccontsChange = (e, selectValue) => {
    if (selectValue) {
      setRequests((prevData) => ({ ...prevData, store_account: selectValue, account_code: selectValue.account_code}))
    }
  }

  const hanldeWarehouseChange = (e, selectValue) => {
    if (selectValue) {
      return setRequests(
        (prevAccount) => ({ ...prevAccount, warehouseSelected: selectValue, warehouse_unique_code: selectValue.warehouse_unique_code, warehouse_name: selectValue.warehouse_name})
      )
    } 

    setRequests((prevAccount) => ({ ...prevAccount, warehouseSelected: [], warehouse: []}))
  }


  const validateRequiredFields = () => {
    const requiredFields = ['delivery_date', 'deployment_type', 'asset_size', 'asset_type', 'store_account', 'store_contact_no'];

    for (const field of requiredFields) {
      if (!requests[field] || (Array.isArray(requests[field]) && requests[field].length === 0)) {
        setOpenMessageDialog(true);
        setMessage('All Fields with asterisks (*) are required');
        return false;
      }

    }

    return true;
  };
  useEffect(()=>{
    let ignore = false;

    getAssetSizes(ignore);
    getDeploymentTypes(ignore)
    getAssetTypes(ignore)
    getStores(ignore)

    return () => {
      ignore = true;
    }
  }, []);

  const handleTALAFChange = (event) => {
    const file = event.target.files[0];
    setRequests((prev) => ({...prev, talaf_file: file}));
  };

  useEffect(() => {
    let ignore = false;
    if (!openWarehouse) {
      setWarehouses([]);
    }

    if (!openWarehouse) return undefined
    getWarehouses(ignore)

    return () => {
      ignore = true
    };
  }, [openWarehouse]);

  return (
    <div>
    <Button disabled = {Number(auth.role) === 6 ? true : false} variant="contained" size="small" onClick={handleOpen}>Create Request</Button>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Add New Request</DialogTitle>
      <DialogContent>
        <Box sx={{marginBottom: 2 }}>
            <Chip label="Asset Details" size="small" color="secondary" />
        </Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                            label="Expected Delivery Date*"
                            value={requests.delivery_date}
                            onChange={(newValue) => setRequests({...requests, delivery_date: dayjs(newValue).format('YYYY-MM-DD')})}
                            minDate={dayjs()}
                        />
                    </LocalizationProvider>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    onChange={handleAssetTpyesChange}
                    options={assetTypes}
                    value={assetTypes.asset_type_code}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderInput={(params) => <TextField {...params} label="Asset Type*" />}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    onChange={handleDelpoymentTypeChange}
                    options={deploymentTypes}
                    value={deploymentTypes.unique_code}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderInput={(params) => <TextField {...params} label="Deployment Type*" />}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    onChange={handleAssetSizeChange}
                    options={assetSize}
                    value={assetSize.assets_code}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderInput={(params) => <TextField {...params} label="Asset Size*" />}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                id="combo-box-demo"
                open={openWarehouse}
                onOpen={() => {setOpenWarehouse(true);}}
                onClose={() => {setOpenWarehouse(false);}}
                loading={warehouseLoading}
                onChange={hanldeWarehouseChange}
                options={warehouses}
                value={warehouses.label}
                noOptionsText={'No Available Data'}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Warehouse Branch*"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {warehouseLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          </Grid>
            <Box sx={{marginBottom: 2, marginTop: 2}}>
                <Chip label="Customer Details and Agreement" size="small" color="secondary" />
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        onChange={handleStoreAccontsChange}
                        options={stores}
                        loading={loadingStore}
                        value={stores.account_code}
                        getOptionLabel={(option) => option.label || ''}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Store*"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loadingStore ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                        />
                    </FormControl>
                </Grid>
                <Grid container item xs={12} alignItems="center" spacing={2} >
                  <Grid item>
                    <Button component="label" variant="contained" startIcon={<CloudUpload />}>
                      Upload TALAF
                      <VisuallyHiddenInput type="file" accept="application/pdf, image/*" onChange={handleTALAFChange}/>
                    </Button>
                  </Grid>
                  <Grid item>
                    {requests.talaf_file && (
                      <p>{requests.talaf_file.name}</p>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                <TextField
                    name="name"
                    label="Store Contact Person*"
                    fullWidth
                    variant="standard"
                    onChange={(e) => setRequests((prevAccount) => ({ ...prevAccount, store_contact_name: e.target.value }))}
                    value={requests.store_contact_name}
                />
                </Grid>
                <Grid item xs={12}>
                <TextField
                    name="name"
                    label="Store contact no."
                    fullWidth
                    variant="standard"
                    onChange={(e) => setRequests((prevAccount) => ({ ...prevAccount, store_contact_no: e.target.value }))}
                    value={requests.store_contact_no}
                />
                </Grid>
                
            </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default AddRequest