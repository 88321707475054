import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Autocomplete, Backdrop, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fade, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Modal, NativeSelect, Select, StyledEngineProvider, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidEmail, isValidSpecialCharacter } from '../GenericFunctions/GenericFunctions';


function AddUsers({setOnEditOrDelete}) {
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [newAccount, setNewAccount] = useState({
    username: '',
    email_address: '',
    password: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    mobile_number: '',
    role_id: 3,
    warehouse_unique_code: ''
  });
  const [showConfirmPassword, setshowConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState('');
  const [showProgress,setShowProgress] = useState(false)
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);


  const objectToFormData = (object) => {
    const formData = new FormData();
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        formData.append(key, object[key]);
      }
    }

    return formData;
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setshowConfirmPassword((prev) => !prev);
  };

  const handleSelectRoleChange = (event, newValue) => {
    setNewAccount((prevAccount) => ({ ...prevAccount, role_id: event.target.value }))
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validation checks
    if (!validateRequiredFields()) return;
    if (!validateSpecialCharacters()) return;
    if (!validateEmails()) return;
    if (!validatePasswordsMatch()) return;

    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/ApiUsers/addUsers.json',
              data: objectToFormData(newAccount),
        }

        const responseUserMaxed = await isUserMaxedOut();
        if(responseUserMaxed.data.result.message === 'maximum_users_reached') {
          setShowProgress(false);
          setOpenMessageDialog(true);
          setMessage('The current number of active users has already reach the limit. Please contact Tradynamics admin for more info.')
        } else {
          const response = await axios(options);
          setShowProgress(false);
          if (response.data.is_success) {
            setOnEditOrDelete((prevOnEdit) => !prevOnEdit)
            setOpen(false)
            setOpenMessageDialog(true);
            setMessage(response.data.message)
            resetForm();
          } else {
            setOpenMessageDialog(true);
            setMessage(response.data.message)
          }
        } 
        
    } catch (error) {
      console.log(error);
    }

  };

  const validateRequiredFields = () => {
    const requiredFields = ['username', 'first_name', 'middle_name', 'last_name', 'mobile_number', 'email_address', 'password'];

    for (const field of requiredFields) {
      if (!newAccount[field]) {
        setOpenMessageDialog(true);
        setMessage('All Fields are required');
        return false;
      }
    }

    return true;
  };

  const validateSpecialCharacters = () => {
    const fieldsWithSpecialCharacters = ['first_name', 'middle_name', 'last_name'];

    for (const field of fieldsWithSpecialCharacters) {
      if (!isValidSpecialCharacter(newAccount[field])) {
        setOpenMessageDialog(true);
        setMessage(`Invalid Special Characters found in ${field === 'first_name' ? 'First' : field === 'middle_name' ? 'Middle' : 'Last'} Name.`);
        return false;
      }
    }

    return true;
  };

  const validateEmails = () => {
    if (!isValidEmail(newAccount.username) || !isValidEmail(newAccount.email_address)) {
      setOpenMessageDialog(true);
      setMessage('Invalid Username or Email Address. Use Vali email format.');
      return false;
    }

    return true;
  };

  const validatePasswordsMatch = () => {
    if (newAccount.password !== confirmPassword) {
      setOpenMessageDialog(true);
      setMessage('Passwords do not match.');
      return false;
    }

    return true;
  };

  const resetForm = () => {
    setNewAccount({
      username: '',
      email_address: '',
      password: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      mobile_number: '',
      role_id: 3,
      maximum_users: '',
    });
    setConfirmPassword('');
    setShowPassword('');
  };

  function isUserMaxedOut () {
    const options = {
        method: 'GET',
        url: '/ApiUsers/isUserMaxedOut.json'
    }

    const response = axios(options);

    return response
  }

  const getWarehouses = async () => {
    try {
        const options = {
            method: 'POST',
            url: '/Warehouses/getWarehousesForAutocomplete.json',
        }
        const response = await axios(options);
        setWarehouses(response.data);
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const handleWarehouseChange = (e, selectValue) => {
    if (selectValue) {
      setNewAccount((prevAccount) => ({ ...prevAccount, warehouse_unique_code: selectValue.warehouse_unique_code}))
    }
}

  useEffect(() => {
    if (newAccount.role_id === '6') {
      getWarehouses();
    }
  
  }, [newAccount.role_id]);

  return (
    <div>
      <Button variant="contained" size="small" onClick={handleOpen}>Create new user</Button>
      <Dialog
        open={open}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
      >
        <DialogTitle>Create new user</DialogTitle>
        <DialogContent>
          <Box sx={{marginBottom: 2 }}>
          <Chip label="Credentials" size="small" color="secondary" />
          </Box>
          <Grid container spacing={1}>
              <Grid item xs={12}>
              <TextField
                  required
                  id="username"
                  name="username"
                  label="Username"
                  fullWidth
                  variant="standard"
                  value={newAccount.username}
                  onChange={(e) => setNewAccount((prevAccount) => ({ ...prevAccount, username: e.target.value }))}
              />
              </Grid>
              <Grid item xs={12}>
              <TextField
                  required
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  fullWidth
                  variant="standard"
                  value={newAccount.password}
                  onChange={(e) => setNewAccount((prevAccount) => ({ ...prevAccount, password: e.target.value }))}
                  InputProps={{
                      endAdornment: (
                      <InputAdornment position="end">
                          <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleTogglePasswordVisibility}
                          >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                      </InputAdornment>
                      ),
                  }}
              />
              </Grid>
              <Grid item xs={12}>
              <TextField
                  required
                  name="confirmPassword"
                  label="Confirm Password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  InputProps={{
                      endAdornment: (
                      <InputAdornment position="end">
                          <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleToggleConfirmPasswordVisibility}
                          >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                      </InputAdornment>
                      ),
                  }}
                  value={confirmPassword}
              />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth sx={{marginTop: 2}}>
                  <InputLabel variant="standard">
                  Role
                  </InputLabel>
                  <NativeSelect
                      defaultValue={newAccount.role_id}
                      inputProps={{
                          name: 'role',
                          id: 'role',
                      }}
                      onChange={handleSelectRoleChange}
                  >
                    <option value={3}>Admin</option>
                    <option value={4}>Distributor</option>
                    <option value={5}>Approver</option>
                    <option value={6}>Hauler</option>
                  </NativeSelect>
                </FormControl>
              </Grid>

              {newAccount.role_id === '6' && (
                <Grid item xs={12} marginTop={2}>
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    onChange={handleWarehouseChange}
                    options={warehouses}
                    value={warehouses.label}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderInput={(params) => <TextField {...params} label="Select Warehouse" />}
                  />
                </FormControl>
              </Grid>
              )}
          </Grid>
          <Box sx={{ marginTop: 5, marginBottom: 2 }}>
          <Chip label="Personal Information" size="small" color="secondary" />
          </Box>
          <Grid container spacing={1}>
              <Grid item xs={12}>
              <TextField
                  required
                  id="firstName"
                  name="firstName"
                  label="First name"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setNewAccount((prevAccount) => ({ ...prevAccount, first_name: e.target.value }))}
                  value={newAccount.first_name}
              />
              </Grid>
              <Grid item xs={12}>
              <TextField
                  required
                  id="middleName"
                  name="middleName"
                  label="Middle name"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setNewAccount((prevAccount) => ({ ...prevAccount, middle_name: e.target.value }))}
                  value={newAccount.middle_name}
              />
              </Grid>
              <Grid item xs={12}>
              <TextField
                  required
                  id="lastName"
                  name="lastName"
                  label="Last name"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setNewAccount((prevAccount) => ({ ...prevAccount, last_name: e.target.value }))}
                  value={newAccount.last_name}
              />
              </Grid>
          </Grid>
          <Box sx={{ marginTop: 5, marginBottom: 2 }}>
            <Chip label="Contacts" size="small" color="secondary"/>
          </Box>
          <Grid container spacing={1}>
              <Grid item xs={12}>
              <TextField
                  required
                  id="mobile"
                  name="mobile"
                  label="Mobile Number"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setNewAccount((prevAccount) => ({ ...prevAccount, mobile_number: e.target.value }))}
                  value={newAccount.mobile_number}
              />
              </Grid>
              <Grid item xs={12}>
              <TextField
                  required
                  id="emailAddress"
                  name="emailAddress"
                  label="Email Address"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setNewAccount((prevAccount) => ({ ...prevAccount, email_address: e.target.value }))}
                  value={newAccount.email_address}
              />
              </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button onClick={handleClose}>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>
    </div>
  );
}

export default AddUsers