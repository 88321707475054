import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';


function EditModels({modelData, setOnEditOrDelete}) {
  const theme = useTheme();
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState({
    model: '',
    asset_type_size: '',
    asset_brand: '',
    external_dimensions: '',
    internal_dimensions: '',
    capacity_gal: '',
    gross_capacity: '',
    net_capacity: '',
    capacity_in_cubic_ft: '',
    asset_area: '',
    id: ''
  });
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
    const { model, asset_type_size, asset_brand, id, external_dimensions, internal_dimensions, capacity_gal, gross_capacity, net_capacity, capacity_in_cubic_ft, asset_area} = modelData;
    setEditData((prevData) => ({ 
      ...prevData, 
      model: model || prevData.model, 
      asset_type_size: asset_type_size || prevData.asset_type_size, 
      asset_brand: asset_brand || prevData.asset_brand, 
      id: id || prevData.id,
      external_dimensions: external_dimensions || prevData.external_dimensions,
      internal_dimensions: internal_dimensions || prevData.internal_dimensions,
      capacity_gal: capacity_gal || prevData.capacity_gal,
      gross_capacity: gross_capacity || prevData.gross_capacity,
      net_capacity: net_capacity || prevData.net_capacity,
      capacity_in_cubic_ft: capacity_in_cubic_ft || prevData.capacity_in_cubic_ft,
      asset_area: asset_area || prevData.capacity_in_cubic_ft,
    }))
  };

  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const resetForm = () => {
    setEditData({
      name: '',
      description: '',
      id: ''
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!editData.model) {
        setOpenMessageDialog(true);
        setMessage('Model name is required')
        return
    }

    if (!isValidSpecialCharacter(editData.model)) {
        setOpenMessageDialog(true);
        setMessage('Invalid Special Characters found in Model Name.')
        return
    }


    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/AssetModels/updateAssetModels.json',
              data: objectToFormData(editData),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          setOpenMessageDialog(true);
          setMessage(response.data.message)
          resetForm();
          setOnEditOrDelete((prev) => !prev)
          setOpen(false)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}.`);
    }
  }

  return (
    <div>
      <Button 
        variant="outlined" 
        size="small" 
        sx={{color: theme.palette.secondary.dark, marginRight: '10px'}} 
        onClick={(e) => {handleOpen(e)}}>
      Edit
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
        maxWidth={'md'}
        fullWidth={true}
      >
        <DialogTitle>Edit Model</DialogTitle>
        <DialogContent>
        <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                  name="model"
                  label="Model Name*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, model: e.target.value }))}
                  value={editData.model}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="asset_type_size"
                  label="Asset type size"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, asset_type_size: e.target.value }))}
                  value={editData.asset_type_size}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="asset_brand"
                  label="Asset brand"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, asset_brand: e.target.value }))}
                  value={editData.asset_brand}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="external_dimensions"
                  label="External dimensions"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, external_dimensions: e.target.value }))}
                  value={editData.external_dimensions}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="internal_dimensions"
                  label="Internal dimensions"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, internal_dimensions: e.target.value }))}
                  value={editData.internal_dimensions}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="Capacity (gal)"
                  label="Capacity (gal)"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, capacity_gal: e.target.value }))}
                  value={editData.capacity_gal}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="gross_capacity"
                  label="Gross capacity"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                      const input = e.target.value;
                      // Regular expression to allow numbers with optional decimal point
                      if (/^\d*\.?\d*$/.test(input)) {
                          const numericValue = parseFloat(input);
                          // Check if the numeric value is within the range [0, 1000000]
                          if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 1000000) {
                            setEditData((prevAccount) => ({ ...prevAccount, gross_capacity: input }));
                          }
                      }
                  }}
                  value={editData.gross_capacity}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="net_capacity"
                  label="Net capacity"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                      const input = e.target.value;
                      // Regular expression to allow numbers with optional decimal point
                      if (/^\d*\.?\d*$/.test(input)) {
                          const numericValue = parseFloat(input);
                          // Check if the numeric value is within the range [0, 1000000]
                          if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 1000000) {
                            setEditData((prevAccount) => ({ ...prevAccount, net_capacity: input }));
                          }
                      }
                  }}
                  value={editData.net_capacity}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="capacity_in_cubic_ft"
                  label="Capacity in cubic ft"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                      const input = e.target.value;
                      // Regular expression to allow numbers with optional decimal point
                      if (/^\d*\.?\d*$/.test(input)) {
                          const numericValue = parseFloat(input);
                          // Check if the numeric value is within the range [0, 1000000]
                          if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 1000000) {
                            setEditData((prevAccount) => ({ ...prevAccount, capacity_in_cubic_ft: input }));
                          }
                      }
                  }}
                  value={editData.capacity_in_cubic_ft}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="asset_area"
                  label="Asset area"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                      const input = e.target.value;
                      // Regular expression to allow numbers with optional decimal point
                      if (/^\d*\.?\d*$/.test(input)) {
                          const numericValue = parseFloat(input);
                          // Check if the numeric value is within the range [0, 1000000]
                          if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 1000000) {
                            setEditData((prevAccount) => ({ ...prevAccount, asset_area: input }));
                          }
                      }
                  }}
                  value={editData.asset_area}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>
    </div>
  )
}

export default EditModels