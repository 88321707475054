import { Settings } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);
function UnitForPullout({requestDetails, setDoneUpdate}) {
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [forPulloutData, setForPulloutData] = useState({
    findings: '',
    assign_tech: '',
    request_code: '',
    status: 2
  });

  const handleOpen = () => {
    setOpen(true)
    const {request_code, id} = requestDetails
    setForPulloutData(prev => ({
      ...prev,
      id: id,
      request_code: request_code,
    }))
  }
  const handleClose = () => {
    setOpen(false)
  }
  const resetForm = () => {
    setForPulloutData({
      findings: '',
      assign_tech: '',
      request_code: '',
      status: 2
    })
  }
  const validateRequiredFields = () => {
    const requiredFields = ['findings', 'assign_tech'];

    for (const field of requiredFields) {
      if (!forPulloutData[field]) {
        setOpenMessageDialog(true);
        setMessage('All Fields are required');
        return false;
      }
    }

    return true;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validation checks
    if (!validateRequiredFields()) return;

    if (!isValidSpecialCharacter(forPulloutData.findings)) {
        setOpenMessageDialog(true);
        setMessage('Invalid Special Characters found in Findings.')
        return
    }

    if (!isValidSpecialCharacter(forPulloutData.assign_tech)) {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in Assigned Technician.')
      return
    }

    
    try {
      setLoading(true)
      setShowProgress(true);
      const options = {
        method: 'POST',
          url: '/ServiceRequests/UpdateServiceRequestsStatus.json',
          data: objectToFormData(forPulloutData),
    }

    const response = await axios(options);
    setShowProgress(false);
    if (response.data.is_success) {
      setDoneUpdate(prev => !prev)
      resetForm();
      setOpen(false)
      setOpenMessageDialog(true);
      setMessage(response.data.message)
    } else {
      setShowProgress(false)
      setOpenMessageDialog(true)
      setMessage(response.data.message)
    }
    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Please try again and make sure you have internet connection.`);
    }
  }
  return (
    <>
      <Button 
        variant='contained' 
        size="small" 
        color='primary' 
        startIcon={<Settings />}
        onClick={handleOpen}
        disabled={requestDetails.status === 2 || requestDetails.status === 1 ? true : false}
      >Unit for pullout
      </Button>
      <Dialog 
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
      >
      <DialogTitle>Pullout Request</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" align='center'>
            Are you sure you want to pullout this Asset? <p> <strong> {requestDetails.request_code}</strong>  </p>
          </DialogContentText>
          <Grid container spacing={2} pt={1}>
            <Grid item xs={12}>
              <Typography>Tech findings:</Typography>
              <Textarea 
                aria-label="minimum height" 
                minRows={3} 
                placeholder="Input findings*" 
                onChange={(e) => setForPulloutData((prevAccount) => ({ ...prevAccount, findings: e.target.value }))}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="code"
                label="Assigned Technician*"
                fullWidth
                // variant="standard"
                onChange={(e) => setForPulloutData((prevAccount) => ({ ...prevAccount, assign_tech: e.target.value }))}
                value={forPulloutData.code}
            />
            </Grid>
          </Grid>
          
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, paddingTop: 3}}>
            <FormControl fullWidth>
              <Button variant="outlined" size="small" onClick={handleClose}>Cancel</Button>
            </FormControl>
            <FormControl fullWidth>
              <Button variant="contained" size="small" color='error' autoFocus onClick={handleSubmit}>Submit</Button>
            </FormControl>
        </DialogActions>
        </DialogContent>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => {
            setOpenMessageDialog(false)
            } }
          message={message}
        />
      </StyledEngineProvider>
    </>
  )
}

export default UnitForPullout