import * as React from 'react';
import { Box, Chip, Typography } from '@mui/material';
import AddUsers from './AddUsers';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { useState } from 'react';
import EditUsers from './EditUsers';
import DeleteUsers from './DeleteUsers';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';



export default function Users() {
  const axios = AxiosCustomConfig();
  const [users, setUsers] = useState([]);
  const [onEditOrDelete, setOnEditOrDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const columns = [
    { field: 'username', headerName: 'Username', flex: 2, minWidth: 150 },
    { field: 'email_address', headerName: 'Email address',flex: 1, minWidth: 150 },
    { field: 'first_name', headerName: 'First name',flex: 1, minWidth: 150 },
    { field: 'last_name', headerName: 'last name',flex: 1, minWidth: 150 },
    { 
      field: 'role_id', 
      headerName: 'Role',
      flex: 1, 
      minWidth: 100, 
      wrap: true,
      renderCell: (params) => {
          const roleId = params.value;
          if (roleId === 2) {
            return 'Principal System admin';
          } else if (roleId === 3) {
            return 'Admin';
          } else if (roleId === 4) {
            return 'Distributor';
          }  else if (roleId === 5) {
            return 'Approver';
          }  else if (roleId === 6) {
            return 'Hauler';
          } else {
            return '';
          }
          
      }
  },
    { field: 'created', headerName: 'Created',flex: 1, minWidth: 150,
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }
    },
    { field: 'modified', headerName: 'Modified',flex: 1, minWidth: 150,  
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }
    },
    { field: 'is_inactive', headerName: 'Status', flex: 1,
      valueFormatter: (params) => (params === 0 ? 'Active' : 'Inactive')
    },
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <>
          <EditUsers usersData={params.row} setOnEditOrDelete={setOnEditOrDelete}/>
          <DeleteUsers usersData={params.row} setOnEditOrDelete={setOnEditOrDelete}/>
        </>
      ),
    },
  ];
  
  useEffect(() => {
    const getUsers = async () => {
      setLoading(true)
      try {
        const options = {
          'method': 'POST',
          'url' : '/ApiUsers/getUsers.json',
        }
        const response = await axios(options);
        const data = response.data.result;
        setUsers(data)
        setLoading(false)
      } catch (error) {
        console.log(error);
      }
    }

    getUsers();
  }, [onEditOrDelete]);

  return (
    <>
    <Box sx={{ marginBottom: '10px' }}>
      {/* add users button */}
      <AddUsers setOnEditOrDelete={setOnEditOrDelete}/>
    </Box>
    <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
      <DataGrid
        getRowHeight={() => 'auto'}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        autoHeight
        loading={loading}
        rows={users}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 25]}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
        }}
      />
    </Box>
    </>
  );
}