import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, NativeSelect, Select, styled, StyledEngineProvider, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { objectToFormData } from '../GenericFunctions/GenericFunctions';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { format, subMonths } from 'date-fns';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const generateDateOptions = (startYear, endYear) => {
  const endDate = new Date(endYear, new Date().getMonth(), 1); // Current month of the end year
  const startDate = new Date(startYear, 0, 1); // January 1st of the start year

  const dateOptions = [];
  let currentDate = endDate;

  while (currentDate >= startDate) {
    dateOptions.push({ label: format(currentDate, 'MMM yyyy'), value: format(currentDate, 'MMM yyyy') });
    currentDate = subMonths(currentDate, 1);
  }

  return dateOptions;
};


function AddFilter({open, setOpen, setOnEditOrDelete}) {
  const axios = AxiosCustomConfig();
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [conditionField, setConditionField] = useState('');
  const [selectedField, setSelectedField] = useState('');
  const [currentselected, setCurrentSelected] = useState('');
  const [openUsers, setOpenUsers] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [openAssetModels, setOpenAssetModels] = useState(false);
  const [assetModels, setAssetModels] = useState([]);
  const [assetModelsLoading, setAssetModelsLoading] = useState(false);
  const [openAssetStatus, setOpenAssetStatus] = useState(false);
  const [assetStatus, setAssetStatus] = useState([]);
  const [assetStatusLoading, setAssetStatusLoading] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [openWarehouses, setOpenWarehouses] = useState(false);
  const [loadingWarehouses, setLoadingWarehouses] = useState(false);
  const [storeData, setStoreData] = useState([]);
  const [openStoreData, setOpenStoreData] = useState(false);
  const [loadingStoreData, setLoadingStoreData] = useState(false);

  const [filterData, setFilterData] = useState({
    from_date: '',
    to_date: '',
    filter_condition: '',
    function_name: 'assets',
    selected_field: '',
    created_by: '',
    asset_model: '',
    status_name : '',
    warehouse_branch : '',
    store_name : '',
    system_status : '',
    channel : '',
    contact_no : '',
    distributor_customer_chain : '',
    customer_type : '',
    jde_syspro_customer_no : '',
    store_address : '',
    date_scrapped : '',
    audit_period : '',
    audit_period_array: [],
    latest_update_array: [],
    latest_asset_update: '',
    filter_by_week: '',
    filter_by_month: '',
    filter_by_year: '',
  });

  const latestAssetUpdate = [
    {'label':'-', 'value':''},
    {'label':'Continue selling', 'value':'Continue selling'},
    {'label':'with system pullout request', 'value':'with system pullout request'},
    {'label':'with BO/refund issues', 'value':'with BO/refund issues'},
    {'label':'Closed, store/asset cannot be located', 'value':'Closed, store/asset cannot be located'},
    {'label':'For audit/verification', 'value':'For audit/verification'},
    {'label':'Other issues', 'value':'Other issues'},
  ]
  
  const handleClose =  () => {
    setOpen(false)
  };

  const resetForm = () => {
    setFilterData({
      from_date: '',
      to_date: '',
      filter_condition: '',
      function_name: 'assets',
      selected_field: '',
      created_by: '',
      asset_model: '',
      status_name: '',
      warehouse_branch: '',
      store_name: '',
      system_status: '',
      channel: '',
      contact_no: '',
      distributor_customer_chain: '',
      customer_type: '',
      jde_syspro_customer_no: '',
      store_address: '',
      date_scrapped: '',
      audit_period: '',
      audit_period_array: [],
      latest_update_array: [],
      latest_asset_update: '',
      filter_by_week: '',
      filter_by_month: '',
      filter_by_year: '',
    });
  }

  const dateOptions = generateDateOptions(2020, new Date().getFullYear());

  const handleSubmit = async (e) => {
    e.preventDefault()
    const excludedFields = ['channel', 'contact_no', 'distributor_customer_chain', 'customer_type', 'jde_syspro_customer_no', 'store_address', 'audit_period'];
    const { from_date, to_date, filter_condition, selected_field } = filterData;

    // Check if 'to_date' is earlier than 'from_date'
    if (new Date(to_date) < new Date(from_date)) {
      setOpenMessageDialog(true);
      setMessage("The 'to date' cannot be earlier than the 'from date'.");
      return; 
    }

    if (!excludedFields.includes(selected_field)) {
      if(!filter_condition) {
        setOpenMessageDialog(true);
        setMessage("Please select filter condition");
        return; 
      }
    }
    

    try {
          
      const options = {
          method: 'POST',
            url: '/FilterSettings/addFilter.json',
            data: objectToFormData(filterData),
      }

      const response = await axios(options);
      setShowProgress(false);
      if (response.data.is_success) {
        resetForm()
        setOnEditOrDelete((prev) => !prev)
        setOpen(false)
        setOpenMessageDialog(true);
        setMessage(response.data.message)
      } else {
        setShowProgress(false)
        setOpenMessageDialog(true)
        setMessage(response.data.message)
      }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. There's an error from the server. Please contact your developer.`);
    }
  };

  const handleSelectedFieldChange = (event) => {
    if(currentselected !== event.target.value) {
      resetForm();
      setCurrentSelected('')
    }

    setSelectedField(event.target.value)
    setFilterData((prevData) => ({...prevData, selected_field: event.target.value}));
    setCurrentSelected(event.target.value);

  };

  const handleConditionFieldChange = (event) => {
    setConditionField(event.target.value);
    setFilterData((prevData) => ({...prevData, filter_condition: event.target.value}))
    // resetForm();
  };


  const handleCreatedByChange = (e, selectValue) => {
    if (selectValue) {
      setFilterData((prevData) => ({ ...prevData, created_by: selectValue.value}))
    }
  }

  const handleAssetModelChange = (e, selectValue) => {
    if (selectValue) {
      setFilterData((prevData) => ({ ...prevData, asset_model: selectValue.asset_model}))
    }
  }
  const handleAssetStatusChange = (e, selectValue) => {
    if (selectValue) {
      setFilterData((prevData) => ({ ...prevData, status_name: selectValue.asset_status}))
    }
  }

  const handleWarehouseChange = (e, selectValue) => {
    if (selectValue) {
      setFilterData((prevAccount) => ({ ...prevAccount, warehouse_branch: selectValue.warehouse_name}))
    }
  }

  const handleStoreAccontsChange = (e, selectValue) => {
    if (selectValue) {
      setFilterData((prevData) => ({ ...prevData, store_name: selectValue.account_name}))
    }
  }

  const handleSystemStatusChange = (event) => {
    setFilterData((prevData) => ({ ...prevData, system_status: event.target.value}))
  }


  const handleAuditPeriodChange = (e, selectValue) => {
    if (selectValue) {
      let audit_period = selectValue.label;
      setFilterData((prevAccount) => ({
        ...prevAccount, 
        audit_period: audit_period, 
        audit_period_array: selectValue
      }))
    }
  }

  const handleLatestAssetUpdateChange = (e, selectValue) => {
    if (selectValue) {
      let latest_asset_update = selectValue.label;
      setFilterData((prevAccount) => ({
        ...prevAccount, 
        latest_asset_update: latest_asset_update, 
        latest_update_array: selectValue
      }))
    }
  }

  const getAssetModels = async (ignore) => {
    try {
        setAssetModelsLoading(true)
        const options = {
            method: 'POST',
            url: '/AssetModels/getAssetModelsNameAndCode.json',
        }

        const response = await axios(options);
        if(!ignore){
          setAssetModels(response.data);
          setAssetModelsLoading(false)
        }

    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getUsers = async (ignore) => {
    try {
        setUsersLoading(true)
        const options = {
            method: 'POST',
            url: '/ApiUsers/getUsersForAutocomplete.json',
        }

        const response = await axios(options);
        if(!ignore){
          setUsers(response.data);
          setUsersLoading(false)
        }

    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getAssetStatus = async (ignore) => {
    try {
      setAssetStatusLoading(true)
        const options = {
            method: 'POST',
            url: '/AssetStatus/getAssetStatusNameAndCode.json',
        }

        const response = await axios(options);
        if(!ignore){
          setAssetStatus(response.data);
          setAssetStatusLoading(false)
        }

    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getWarehouses = async (ignore) => {
    try {
        setLoadingWarehouses(true)
        const options = {
            method: 'POST',
            url: '/Warehouses/getWarehousesForAutocomplete.json',
        }

        const response = await axios(options);
        if(!ignore){
          setWarehouses(response.data);
          setLoadingWarehouses(false)
        }

    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getStoreData = async (ignore) => {
    try {
        setLoadingStoreData(true)
        const options = {
            method: 'POST',
            url: '/ApiAccounts/getAllStoresForRequest.json',
        }

        const response = await axios(options);
        if(!ignore){
          setStoreData(response.data);
          setLoadingStoreData(false)
        }

    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(()=>{
    let ignore = false;

    if (!openUsers) return undefined
    getUsers(ignore);

    return () => {
      ignore = true;
    }
  }, [openUsers]);

  useEffect(()=>{
    let ignore = false;

    if (!openAssetModels) return undefined
    getAssetModels(ignore);

    return () => {
      ignore = true;
    }
  }, [openAssetModels]);

  useEffect(()=>{
    let ignore = false;

    if (!openAssetStatus) return undefined
    getAssetStatus(ignore);

    return () => {
      ignore = true;
    }
  }, [openAssetStatus]);

  useEffect(()=>{
    let ignore = false;

    if (!openWarehouses) return undefined
    getWarehouses(ignore);

    return () => {
      ignore = true;
    }
  }, [openWarehouses]);

  useEffect(()=>{
    let ignore = false;

    if (!openStoreData) return undefined
    getStoreData(ignore);

    return () => {
      ignore = true;
    }
  }, [openStoreData]);



  return (
    <>
      <Dialog
        open={open}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
          sx: { height: '400px' }
        }}
        fullWidth={true} 
        maxWidth={'md'}
      >
        <DialogTitle>Filter Info</DialogTitle>
        <DialogContent>
            <Grid container spacing={3}>
            <Grid item xs={12}>
            <FormControl fullWidth sx={{ marginTop: 2 }}>
              <InputLabel id="select-field">Select Field</InputLabel>
              <Select
                labelId='select-field'
                label='Select Field'
                value={selectedField}
                onChange={handleSelectedFieldChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200, // Limit the height of the dropdown
                    },
                  },
                }}
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value="date_added">Date Added</MenuItem>
                <MenuItem value="date_updated">Date Updated</MenuItem>
                <MenuItem value="created_by">Created By</MenuItem>
                <MenuItem value="asset_model">Asset Model</MenuItem>
                <MenuItem value="status_name">Asset Status</MenuItem>
                <MenuItem value="warehouse_branch">Warehouse branch</MenuItem>
                {/* Scrolling will begin from here */}
                <MenuItem value="store_name">Store/Business name</MenuItem>
                <MenuItem value="acquisition_date">Acquisition date</MenuItem>
                <MenuItem value="system_status">JDE system status</MenuItem>
                <MenuItem value="channel">Channel</MenuItem>
                <MenuItem value="contact_no">Contact no</MenuItem>
                <MenuItem value="distributor_customer_chain">Distributor/Customer chain</MenuItem>
                <MenuItem value="customer_type">Customer type</MenuItem>
                <MenuItem value="jde_syspro_customer_no">JDE/Syspro Customer #</MenuItem>
                <MenuItem value="store_address">Store address</MenuItem>
                <MenuItem value="date_scrapped">Date scrapped</MenuItem>
                <MenuItem value="audit_period">Audit period</MenuItem>
                <MenuItem value="latest_asset_update">Latest asset update</MenuItem>
              </Select>
            </FormControl>
              </Grid>
            </Grid>
            {selectedField && (
              <Grid item xs={12}>
              {(selectedField === "date_added" || selectedField === "date_updated" || selectedField === "acquisition_date" || selectedField === "date_scrapped") && (
                <>
                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <NativeSelect
                      value={conditionField}
                      inputProps={{
                        name: 'condition',
                        id: 'condition',
                      }}
                      onChange={handleConditionFieldChange}
                    >
                      <option value="" disabled>Select condition</option>
                      <option value="filter_by_days">Filter By Days</option>
                      <option value="filter_by_week">Filter by Week</option>
                      <option value="filter_by_month">Filter by Month</option>
                      <option value="filter_by_year">Filter by Year</option>
                      {/* <option value="overdue">Overdue dates only</option>
                      <option value="empty_value">Empty Value</option>
                      <option value="not_empty_value">Not empty value</option> */}
                    </NativeSelect>
                  </FormControl>
                </>
              )}


              {(selectedField === "created_by") && (
                <>
                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <NativeSelect
                      value={conditionField}
                      inputProps={{
                        name: 'condition',
                        id: 'condition',
                      }}
                      onChange={handleConditionFieldChange}
                    >
                      <option value="" disabled>Select condition</option>
                      <option value="filter_include">Include</option>
                      <option value="filter_exclude">Exclude</option>
                      {/* <option value="overdue">Overdue dates only</option>
                      <option value="empty_value">Empty Value</option>
                      <option value="not_empty_value">Not empty value</option> */}
                    </NativeSelect>
                  </FormControl>

                  <Grid item xs={12} mt={2}>
                    <FormControl fullWidth>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        open={openUsers}
                        onOpen={() => {setOpenUsers(true);}}
                        onClose={() => {setOpenUsers(false);}}
                        loading={usersLoading}
                        onChange={handleCreatedByChange}
                        options={users}
                        value={users.label}
                        getOptionLabel={(option) => option.label || ''}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                        renderInput={(params) => <TextField {...params} label="Created by" />}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}


              {(selectedField === "asset_model") && (
                <>
                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <NativeSelect
                      value={conditionField}
                      inputProps={{
                        name: 'condition',
                        id: 'condition',
                      }}
                      onChange={handleConditionFieldChange}
                    >
                      <option value="" disabled>Select condition</option>
                      <option value="filter_include">Include</option>
                      <option value="filter_exclude">Exclude</option>
                      {/* <option value="overdue">Overdue dates only</option>
                      <option value="empty_value">Empty Value</option>
                      <option value="not_empty_value">Not empty value</option> */}
                    </NativeSelect>
                  </FormControl>

                  <Grid item xs={12} mt={2}>
                    <FormControl fullWidth>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        open={openAssetModels}
                        onOpen={() => {setOpenAssetModels(true);}}
                        onClose={() => {setOpenAssetModels(false);}}
                        loading={assetModelsLoading}
                        onChange={handleAssetModelChange}
                        options={assetModels}
                        value={assetModels.label}
                        getOptionLabel={(option) => option.label || ''}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                        renderInput={(params) => <TextField {...params} label="Asset Model" />}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}

              {(selectedField === "status_name") && (
                <>
                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <NativeSelect
                      value={conditionField}
                      inputProps={{
                        name: 'condition',
                        id: 'condition',
                      }}
                      onChange={handleConditionFieldChange}
                    >
                      <option value="" disabled>Select condition</option>
                      <option value="filter_include">Include</option>
                      <option value="filter_exclude">Exclude</option>
                      {/* <option value="overdue">Overdue dates only</option>
                      <option value="empty_value">Empty Value</option>
                      <option value="not_empty_value">Not empty value</option> */}
                    </NativeSelect>
                  </FormControl>

                  <Grid item xs={12} mt={2}>
                    <FormControl fullWidth>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        open={openAssetStatus}
                        onOpen={() => {setOpenAssetStatus(true);}}
                        onClose={() => {setOpenAssetStatus(false);}}
                        loading={assetStatusLoading}
                        onChange={handleAssetStatusChange}
                        options={assetStatus}
                        value={assetStatus.label}
                        getOptionLabel={(option) => option.label || ''}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                        renderInput={(params) => <TextField {...params} label="Asset Status" />}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}

              {(selectedField === "warehouse_branch") && (
                <>
                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <NativeSelect
                      value={conditionField}
                      inputProps={{
                        name: 'condition',
                        id: 'condition',
                      }}
                      onChange={handleConditionFieldChange}
                    >
                      <option value="" disabled>Select condition</option>
                      <option value="filter_include">Include</option>
                      <option value="filter_exclude">Exclude</option>
                      {/* <option value="overdue">Overdue dates only</option>
                      <option value="empty_value">Empty Value</option>
                      <option value="not_empty_value">Not empty value</option> */}
                    </NativeSelect>
                  </FormControl>

                  <Grid item xs={12} mt={2}>
                    <FormControl fullWidth>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        open={openWarehouses}
                        onOpen={() => {setOpenWarehouses(true);}}
                        onClose={() => {setOpenWarehouses(false);}}
                        loading={loadingWarehouses}
                        onChange={handleWarehouseChange}
                        options={warehouses}
                        value={warehouses.label}
                        getOptionLabel={(option) => option.label || ''}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                        renderInput={(params) => <TextField {...params} label="Warehouse" />}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}

              {(selectedField === "store_name") && (
                <>
                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <NativeSelect
                      value={conditionField}
                      inputProps={{
                        name: 'condition',
                        id: 'condition',
                      }}
                      onChange={handleConditionFieldChange}
                    >
                      <option value="" disabled>Select condition</option>
                      <option value="filter_include">Include</option>
                      <option value="filter_exclude">Exclude</option>
                      {/* <option value="overdue">Overdue dates only</option>
                      <option value="empty_value">Empty Value</option>
                      <option value="not_empty_value">Not empty value</option> */}
                    </NativeSelect>
                  </FormControl>

                  <Grid item xs={12} mt={2}>
                    <FormControl fullWidth>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        open={openStoreData}
                        onOpen={() => {setOpenStoreData(true);}}
                        onClose={() => {setOpenStoreData(false);}}
                        loading={loadingStoreData}
                        onChange={handleStoreAccontsChange}
                        options={storeData}
                        value={storeData.label}
                        getOptionLabel={(option) => option.label || ''}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Store*"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loadingStoreData ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}

              
              {(selectedField === "system_status") && (
                <>
                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <NativeSelect
                      value={conditionField}
                      inputProps={{
                        name: 'condition',
                        id: 'condition',
                      }}
                      onChange={handleConditionFieldChange}
                    >
                      <option value="" disabled>Select condition</option>
                      <option value="filter_include">Include</option>
                      <option value="filter_exclude">Exclude</option>
                      {/* <option value="overdue">Overdue dates only</option>
                      <option value="empty_value">Empty Value</option>
                      <option value="not_empty_value">Not empty value</option> */}
                    </NativeSelect>
                  </FormControl>

                  <Grid item xs={12} mt={2}>
                    <FormControl fullWidth sx={{marginTop: 2}}>
                      <InputLabel variant="standard">
                      JDE system status
                      </InputLabel>
                      <NativeSelect
                          value={filterData.system_status}
                          inputProps={{
                              name: 'field',
                              id: 'field',
                          }}
                          onChange={handleSystemStatusChange}
                      >
                        <option value=''></option>
                        <option value="0">Active</option>
                        <option value="3">For write-off</option>
                        <option value="1">Deleted</option>
                      </NativeSelect>
                    </FormControl>
                  </Grid>
                </>
              )}

              {(selectedField === "channel") && (
                <>
                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <TextField
                        name="channel"
                        label="Values"
                        fullWidth
                        variant="standard"
                        onChange={(e) => setFilterData((prevAccount) => ({ ...prevAccount, channel: e.target.value }))}
                        value={filterData.channel}
                    />
                  </FormControl>
                </>
              )}
              {(selectedField === "contact_no") && (
                <>
                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <TextField
                        name="contact_no"
                        label="Values"
                        fullWidth
                        variant="standard"
                        onChange={(e) => setFilterData((prevAccount) => ({ ...prevAccount, contact_no: e.target.value }))}
                        value={filterData.contact_no}
                    />
                  </FormControl>
                </>
              )}
              {(selectedField === "distributor_customer_chain") && (
                <>
                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <TextField
                        name="distributor_customer_chain"
                        label="Values"
                        fullWidth
                        variant="standard"
                        onChange={(e) => setFilterData((prevAccount) => ({ ...prevAccount, distributor_customer_chain: e.target.value }))}
                        value={filterData.distributor_customer_chain}
                    />
                  </FormControl>
                </>
              )}

              {(selectedField === "customer_type") && (
                <>
                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <TextField
                        name="customer_type"
                        label="Values"
                        fullWidth
                        variant="standard"
                        onChange={(e) => setFilterData((prevAccount) => ({ ...prevAccount, customer_type: e.target.value }))}
                        value={filterData.customer_type}
                    />
                  </FormControl>
                </>
              )}

              {(selectedField === "jde_syspro_customer_no") && (
                <>
                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <TextField
                        name="jde_syspro_customer_no"
                        label="Values"
                        fullWidth
                        variant="standard"
                        onChange={(e) => setFilterData((prevAccount) => ({ ...prevAccount, jde_syspro_customer_no: e.target.value }))}
                        value={filterData.jde_syspro_customer_no}
                    />
                  </FormControl>
                </>
              )}

              {(selectedField === "store_address") && (
                <>
                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <TextField
                        name="store_address"
                        label="Values"
                        fullWidth
                        variant="standard"
                        onChange={(e) => setFilterData((prevAccount) => ({ ...prevAccount, store_address: e.target.value }))}
                        value={filterData.store_address}
                    />
                  </FormControl>
                </>
              )}


              {(selectedField === "audit_period") && (
                <>
                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <Autocomplete
                      disablePortal
                      onChange={handleAuditPeriodChange}
                      options={dateOptions}
                      getOptionLabel={(option) => option.label || ''}
                      isOptionEqualToValue={(option, value) => (option && value && option.label === value.label)}
                      renderInput={(params) => <TextField {...params} label="Audit Period" />}
                      value={filterData.audit_period_array}
                    />
                  </FormControl>
                </>
              )}


              {(selectedField === "latest_asset_update") && (
                <>

                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <NativeSelect
                      value={conditionField}
                      inputProps={{
                        name: 'condition',
                        id: 'condition',
                      }}
                      onChange={handleConditionFieldChange}
                    >
                      <option value="" disabled>Select condition</option>
                      <option value="filter_include">Include</option>
                      <option value="filter_exclude">Exclude</option>
                      {/* <option value="overdue">Overdue dates only</option>
                      <option value="empty_value">Empty Value</option>
                      <option value="not_empty_value">Not empty value</option> */}
                    </NativeSelect>
                  </FormControl>

                  <Grid item xs={12} mt={2}>
                    <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <Autocomplete
                      id="combo-box-demo"
                      onChange={handleLatestAssetUpdateChange}
                      options={latestAssetUpdate}
                      value={filterData.latest_update_array}
                      getOptionLabel={(option) => option.label || ''}
                      isOptionDisabled={(option) => option.label === '-' || option.label === 'Please Select'}
                      isOptionEqualToValue={(option, value) => (option && value && option.label === value.label)}
                      renderInput={(params) => <TextField {...params} label="Select some options" />}
                    />
                    </FormControl>
                  </Grid>
                </>
              )}


              </Grid>
            )}
            
            {((conditionField && selectedField === "date_added") || (conditionField && selectedField === "date_updated") || (conditionField && selectedField === "acquisition_date") || (conditionField && selectedField === "date_scrapped")) && (
              <Grid item xs={12} mt={2}>
                {conditionField === "filter_by_days" && (
                  <>
                    {/* <FormControl fullWidth>
                      <TextField
                          id="username"
                          name="username"
                          label="Filter By Days"
                          fullWidth
                          variant="standard"
                          value={filterData.username}
                          onChange={(e) => setFilterData((prevAccount) => ({ ...prevAccount, filter_by_days: e.target.value }))}
                      />
                    </FormControl>
                    <Typography variant="body1" color="inherit">You can use "+1" and it means "Today+1". There rule can be "-1". Also you can set several days like "+1&2&3"</Typography> */}

                    <Grid item xs={12} mt={3}>
                      <strong>Filter By Dates</strong>     Set From/To dates.
                    </Grid>

                    <Grid item xs={12} mt={3}>
                      <FormControl fullWidth>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker 
                                  label="From"
                                  value={filterData.delivery_date}
                                  onChange={(newValue) => setFilterData({...filterData, from_date: dayjs(newValue).format('YYYY-MM-DD')})}
                              />
                          </LocalizationProvider>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} mt={1}>
                      <FormControl fullWidth>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker 
                                  label="To"
                                  value={filterData.delivery_date}
                                  onChange={(newValue) => setFilterData({...filterData, to_date: dayjs(newValue).format('YYYY-MM-DD')})}
                              />
                          </LocalizationProvider>
                      </FormControl>
                    </Grid>
                  </>
                )}

                {conditionField === "filter_by_week" && (
                  <FormControl fullWidth>
                    <TextField
                      name="filter_by_week"
                      label="Values"
                      fullWidth
                      variant="standard"
                      onChange={(e) => setFilterData((prevAccount) => ({ ...prevAccount, filter_by_week: e.target.value }))}
                      value={filterData.filter_by_week}
                    />
                    <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
                      Available values: "0" - the current week, "-1" - the previous week, "+1" - next week.
                    </Typography>
                  </FormControl>
                )}

                {conditionField === "filter_by_month" && (
                  <FormControl fullWidth>
                    <TextField
                      name="filter_by_month"
                      label="Values"
                      fullWidth
                      variant="standard"
                      onChange={(e) => setFilterData((prevAccount) => ({ ...prevAccount, filter_by_month: e.target.value }))}
                      value={filterData.filter_by_month}
                    />
                    <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
                      Available values: "0" - the current month, "-1" - the previous month, "+1" - next month.
                    </Typography>
                  </FormControl>
                )}

                {conditionField === "filter_by_year" && (
                  <FormControl fullWidth>
                    <TextField
                      name="filter_by_year"
                      label="Values"
                      fullWidth
                      variant="standard"
                      onChange={(e) => setFilterData((prevAccount) => ({ ...prevAccount, filter_by_year: e.target.value }))}
                      value={filterData.filter_by_year}
                    />
                    <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
                      Available values: "0" - this year, "-1" - the previous year, "1" - next year.
                    </Typography>
                  </FormControl>
                )}

              </Grid>
            )}
            
          </DialogContent>
          <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
            <FormControl fullWidth>
                <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
            </FormControl>
            <FormControl fullWidth>
              <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
            </FormControl>
          </DialogActions>
        </Dialog>

        <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>
    </>
  )
}

export default AddFilter