import { Box, Chip, Grid, StyledEngineProvider, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import MessageDialog from '../Utils/MessageDialog'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import useJobOrdersContext from '../../hooks/UseJobOrdersContext';

function JOPulloutRequests({jorOrderIdunique}) {
  const axios = AxiosCustomConfig();
  const {doneUpdate} = useJobOrdersContext();
  const [onEditOrDelete, setOnEditOrDelete] = useState(false);
  const [pulloutRequests, setPulloutRequests] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);


  const columns = [
    { field: 'request_code', headerName: 'Request code', flex: 1, minWidth: 100, wrap: true,
        renderCell: (params) => {
          const requestCode = params.value; // Get the value of request_code for the current row
          // Render the request_code as a clickable link using an anchor tag
          return (
              <Link to={`/pulloutRequest/${requestCode}`}>
                  {requestCode}
              </Link>
          );
      }
    },
    { 
        field: 'status_name', 
        headerName: 'Request Status',
        flex: 1, 
        minWidth: 100, 
        wrap: true,
        renderCell: (params) => {
          
            const statusName = params.value;
            if (statusName === 'Processing') {
              return <Chip label={statusName} sx={{ backgroundColor: '#D7D6D4'}} />;
            } else if (statusName === 'Pulled out') {
              return <Chip label={statusName} sx={{ backgroundColor: '#3AD658' }} />;
            } else if (statusName === 'Cancelled') {
              return <Chip label={statusName} sx={{ backgroundColor: '#CA863E', color: '#fff' }} />;
            } else if (statusName === 'Unproductive') {
              return <Chip label={statusName} sx={{ backgroundColor: '#E8484F', color: '#fff' }} />;
            } else if (statusName === 'Unproductive & cancelled') {
              return <Chip label={statusName} sx={{ backgroundColor: '#843B10', color: '#fff' }} />;
            } else if (statusName === 'Hold') {
              return <Chip label={statusName} sx={{ backgroundColor: '#F8FD6A' }} />;
            } else if (statusName === 'Missing asset - unpro') {
              return <Chip label={statusName} sx={{ backgroundColor: '#DD1F00', color: '#fff'}} />;
            }else {
              return <Chip label={statusName} color='primary' />;
            }
            
        }
    },
    { field: 'branch_name', headerName: 'Store name',flex: 1, minWidth: 150, wrap: true  },
    { field: 'pullout_type_name', headerName: 'Pullout type',flex: 1, minWidth: 150},
    { field: 'asset_name', headerName: 'Asset',flex: 1, minWidth: 150},
    { field: 'requestor_name', headerName: 'Requested By',flex: 1, minWidth: 150},
  ];

  useEffect(() => {
    let ignore = false;
    const getAssetTypes = async (ignore) => {
      try {
        setLoading(true);
        const options = {
          'method': 'POST',
          'url' : '/JobOrders/getAllJOPulloutRequests.json',
          'data' : {job_order_id : jorOrderIdunique}
        }
        const response = await axios(options);
        const data = response.data;

        if (!ignore) {
            setPulloutRequests(data)
        }
        
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Please try again and make sure you have internet connection.`);
      } finally {
        setLoading(false);
      }

      return () => {
        ignore = true;
        setLoading(false);
      }
    }

    getAssetTypes(ignore);
  }, [onEditOrDelete, doneUpdate]);
  return (
    <>
    <Box p={2}>
      <Typography>Pullout Requests</Typography>
    </Box>
    <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
      <DataGrid
        autoHeight
        keepNonExistentRowsSelected
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        loading={loading}
        rows={pulloutRequests}
        columns={columns}
        getRowHeight={() => 'auto'}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
            py: 1,
          },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            py: '15px',
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            py: '22px',
          },
        }}
      />
    </Box>

    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </>
  )
}

export default JOPulloutRequests