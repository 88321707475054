import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Autocomplete, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, NativeSelect, StyledEngineProvider, TextField, useTheme } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { useEffect } from 'react';


function EditUsers({usersData, setOnEditOrDelete}) {
  
  const theme = useTheme()
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);

  const [editData, setEditData] = useState({
    username: '',
    email_address: '',
    password: 'defaultvalue',
    first_name: '',
    middle_name: '',
    last_name: '',
    mobile_number: '',
    role_id: '',
    warehouse: null,
    warehouse_unique_code: ''
  });
  const [showConfirmPassword, setshowConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState('');
  const [showProgress,setShowProgress] = useState(false)
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('defaultvalue');
  const [warehouses, setWarehouses] = useState([]);

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
    const { username, email_address, first_name, middle_name, warehouse_name, warehouse_unique_code, last_name, mobile_number, role_id, warehouse_code} = usersData;

    setEditData((prevData) => ({ 
      ...prevData, 
      username: username || prevData.username, 
      email_address: email_address || prevData.email_address, 
      first_name: first_name || prevData.first_name, 
      middle_name: middle_name || prevData.middle_name, 
      last_name: last_name || prevData.last_name, 
      mobile_number: mobile_number || prevData.mobile_number, 
      role_id: role_id || prevData.role_id, 
      warehouse_unique_code: warehouse_unique_code || prevData.warehouse_unique_code, 
      warehouse: {'label' : `${warehouse_name} - (${warehouse_code})`, value: warehouse_unique_code},
    }))
  }
  const handleClose = () => {
    resetForm()
    setOpen(false)
  };
  
  const objectToFormData = (object) => {
    const formData = new FormData();
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        formData.append(key, object[key]);
      }
    }

    return formData;
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setshowConfirmPassword((prev) => !prev);
  };

  const handleSelectRoleChange = (event, newValue) => {
    setEditData((prevData) => ({ ...prevData, role_id: event.target.value }))
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!editData.username || !editData.first_name || !editData.middle_name || !editData.last_name || !editData.mobile_number || !editData.email_address || !editData.password) {
        setOpenMessageDialog(true);
        setMessage('All Fields are required')
        return
    }

    if(editData.password !== confirmPassword) {
      setOpenMessageDialog(true);
      setMessage('Password did not match.')
      return
    }

    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/ApiUsers/updateUser.json',
              data: objectToFormData(editData),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          setOpenMessageDialog(true);
          setMessage(response.data.message)
          resetForm();
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage('Failed to Save.')
        }
        
    } catch (error) {
      console.log(error);
    }

  };

  const resetForm = () => {
    setEditData({
      username: '',
      email_address: '',
      password: 'defaultvalue',
      first_name: '',
      middle_name: '',
      last_name: '',
      mobile_number: '',
      role_id: 2,
      warehouse: null,
      warehouse_unique_code: ''
    });
    setConfirmPassword('defaultvalue');
  };

  const getWarehouses = async () => {
    try {
        const options = {
            method: 'POST',
            url: '/Warehouses/getWarehousesForAutocomplete.json',
        }
        const response = await axios(options);
        setWarehouses(response.data);
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const handleWarehouseChange = (e, selectValue) => {
      if (selectValue) {
        setEditData((prevAccount) => ({ ...prevAccount, warehouse_unique_code: selectValue.warehouse_unique_code, warehouse: selectValue}))
      }
  }

  useEffect(() => {
    if (Number(editData.role_id) === 6) {
      getWarehouses();
    }

  }, [editData.role_id]);

  return (
    <div>
      <Button 
        variant="outlined" 
        size="small" 
        sx={{color: theme.palette.secondary.dark, marginRight: '10px'}} 
        onClick={(e) => {handleOpen(e)}}>
      Edit
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
      >
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <Box sx={{marginBottom: 2 }}>
          <Chip label="Credentials" size="small" color="secondary" />
          </Box>
          <Grid container spacing={1}>
              <Grid item xs={12}>
              <TextField
                  required
                  id="username"
                  name="username"
                  label="Username"
                  fullWidth
                  variant="standard"
                  value={editData.username}
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, username: e.target.value }))}
              />
              </Grid>
              <Grid item xs={12}>
              <TextField
                  required
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  fullWidth
                  variant="standard"
                  value={editData.password}
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, password: e.target.value }))}
                  InputProps={{
                      endAdornment: (
                      <InputAdornment position="end">
                          <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleTogglePasswordVisibility}
                          >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                      </InputAdornment>
                      ),
                  }}
              />
              </Grid>
              <Grid item xs={12}>
              <TextField
                  required
                  name="confirmPassword"
                  label="Confirm Password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  InputProps={{
                      endAdornment: (
                      <InputAdornment position="end">
                          <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleToggleConfirmPasswordVisibility}
                          >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                      </InputAdornment>
                      ),
                  }}
                  value={confirmPassword}
              />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth sx={{marginTop: 2}}>
                  <InputLabel variant="standard">
                  Role
                  </InputLabel>
                  <NativeSelect
                      defaultValue={editData.role_id}
                      inputProps={{
                          name: 'role',
                          id: 'role',
                      }}
                      onChange={handleSelectRoleChange}
                  >
                    <option value={3}>Admin</option>
                    <option value={4}>Distributor</option>
                    <option value={6}>Hauler</option>
                    <option value={5}>Approver</option>
                  </NativeSelect>
                </FormControl>
              </Grid>
              {Number(editData.role_id) === 6 && (
                <Grid item xs={12} marginTop={2}>
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    onChange={handleWarehouseChange}
                    options={warehouses}
                    value={editData.warehouse}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderInput={(params) => <TextField {...params} label="Select Warehouse" />}
                  />
                </FormControl>
              </Grid>
              )}
          </Grid>
          <Box sx={{ marginTop: 5, marginBottom: 2 }}>
          <Chip label="Personal Information" size="small" color="secondary" />
          </Box>
          <Grid container spacing={1}>
              <Grid item xs={12}>
              <TextField
                  required
                  id="firstName"
                  name="firstName"
                  label="First name"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, first_name: e.target.value }))}
                  value={editData.first_name}
              />
              </Grid>
              <Grid item xs={12}>
              <TextField
                  required
                  id="middleName"
                  name="middleName"
                  label="Middle name"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, middle_name: e.target.value }))}
                  value={editData.middle_name}
              />
              </Grid>
              <Grid item xs={12}>
              <TextField
                  required
                  id="lastName"
                  name="lastName"
                  label="Last name"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, last_name: e.target.value }))}
                  value={editData.last_name}
              />
              </Grid>
          </Grid>
          <Box sx={{ marginTop: 5, marginBottom: 2 }}>
            <Chip label="Contacts" size="small" color="secondary"/>
          </Box>
          <Grid container spacing={1}>
              <Grid item xs={12}>
              <TextField
                  required
                  id="mobile"
                  name="mobile"
                  label="Mobile Number"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, mobile_number: e.target.value }))}
                  value={editData.mobile_number}
              />
              </Grid>
              <Grid item xs={12}>
              <TextField
                  required
                  id="emailAddress"
                  name="emailAddress"
                  label="Email Address"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, email_address: e.target.value }))}
                  value={editData.email_address}
              />
              </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => {
              setOpenMessageDialog(false)
              setOpen(false)
              setOnEditOrDelete((prev) => !prev)
              }}
            message={message}
          />
      </StyledEngineProvider>
    </div>
  );
}

export default EditUsers