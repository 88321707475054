import { Avatar, Box, Chip, CircularProgress, StyledEngineProvider, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { format } from 'date-fns';
import React, { useEffect } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { useState } from 'react';
import MessageDialog from '../Utils/MessageDialog';
import { Link } from 'react-router-dom';
import AddPulloutRequest from './AddPulloutRequest';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import ColumnSettingsButton from '../Utils/ColumnSettingsButton';
import useFetchColumns from '../../hooks/UseFetchColumns';
import useFilteredAndOrderedColumns from '../../hooks/UseFilteredAndOrderedColumns';
import CustomToolbar from '../Utils/CustomToolbar';
import useFetchPageLength from '../../hooks/UseFetchPageLength';
import CustomGlobalFooter from '../Utils/CustomGlobalFooter';
import useSortModel from '../../hooks/UseSortModel';


const PulloutRequests = () => {
  const axios = AxiosCustomConfig();
  const [onEditOrDelete, setOnEditOrDelete] = useState(false);
  const [pulloutRequests, setPulloutRequests] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [onSaveColumns, setOnSaveColumns] = useState(false);
  const functionName = 'pullout_request';
  const modelTable = 'PulloutRequests';
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const { paginationModel, setPaginationModel, pageSizeOptions } = useFetchPageLength(functionName, setOpenMessageDialog, setMessage, onSaveColumns);

  const header_columns = [
    { field: 'request_code', headerName: 'Request no', flex: 1, minWidth: 100, wrap: true,
        renderCell: (params) => {
          const requestCode = params.value; // Get the value of request_code for the current row
          // Render the request_code as a clickable link using an anchor tag
          return (
              <Link to={`/pulloutRequest/${requestCode}`}>
                  {requestCode}
              </Link>
          );
      }
    },
    { 
        field: 'status_name', 
        headerName: 'Request Status',
        flex: 1, 
        minWidth: 100, 
        wrap: true,
        renderCell: (params) => {
          
            const statusName = params.value;
            if (statusName === 'Processing') {
              return <Chip label={statusName} sx={{ backgroundColor: '#D7D6D4'}} />;
            } else if (statusName === 'Pulled out') {
              return <Chip label={statusName} sx={{ backgroundColor: '#3AD658' }} />;
            } else if (statusName === 'Cancelled') {
              return <Chip label={statusName} sx={{ backgroundColor: '#CA863E', color: '#fff' }} />;
            } else if (statusName === 'Unproductive') {
              return <Chip label={statusName} sx={{ backgroundColor: '#E8484F', color: '#fff' }} />;
            } else if (statusName === 'Unproductive & cancelled') {
              return <Chip label={statusName} sx={{ backgroundColor: '#843B10', color: '#fff' }} />;
            } else if (statusName === 'Hold') {
              return <Chip label={statusName} sx={{ backgroundColor: '#F8FD6A' }} />;
            } else if (statusName === 'Missing asset - unpro') {
              return <Chip label={statusName} sx={{ backgroundColor: '#DD1F00', color: '#fff'}} />;
            }else {
              return <Chip label={statusName} color='primary' />;
            }
            
        }
    },
    { field: 'account_name', headerName: 'Store name',flex: 1, minWidth: 150, wrap: true  },
    { field: 'pullout_type_name', headerName: 'Pullout type',flex: 1, minWidth: 150},
    { field: 'asset_name', headerName: 'Asset',flex: 1, minWidth: 150},
    { field: 'requestor_name', headerName: 'Requested By',flex: 1, minWidth: 150},
  ];

  const columns = useFetchColumns(functionName, onSaveColumns, setOpenMessageDialog, setMessage);
  const filteredAndOrderedColumns = useFilteredAndOrderedColumns(columns, header_columns);
  const [sortModel, setSortModel] = useSortModel(columns);

  useEffect(() => {
    let ignore = false;
    const getAssetTypes = async (ignore) => {
      try {
        setLoading(true);
        const options = {
          'method': 'POST',
          'url' : '/PulloutRequests/getAllPulloutRequests.json',
        }
        const response = await axios(options);
        const data = response.data;

        if (!ignore) {
            setPulloutRequests(data)
        }
        
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Please try again and make sure you have internet connection.`);
      } finally {
        setLoading(false);
      }

      return () => {
        ignore = true;
        setLoading(false);
      }
    }

    getAssetTypes(ignore);
  }, [onEditOrDelete]);

  const handleClickAllData = async () => {
    if(!selectAll) {
      try {
        setLoading(true);
        const options = {
          method: 'POST',
          url: '/PulloutRequests/getAllPulloutRequestsId.json',
        };
        const response = await axios(options);
        
        const allDataID = response.data.map((row) => row.id);
        
        setRowSelectionModel(allDataID);
        setSelectAll(true);
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(`${error.toJSON().message}. Please try again and make sure you have an internet connection.`);
      } finally {
        setLoading(false);
      }
    } else {
      setRowSelectionModel([]);
      setSelectAll(false);
    }
  }
  
  return (
    <div>
    <Box sx={{ marginBottom: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center'  }}>
      <Box sx={{ display: 'flex', gap: '10px' }}>
      <AddPulloutRequest setOnEditOrDelete={setOnEditOrDelete}/>
      </Box>
      {/* Spacer */}
      <Box sx={{ flexGrow: 1 }} />

      {/* Settings Icon and Menu */}
      <Box sx={{ position: 'relative' }}>
        <ColumnSettingsButton
          columns={columns}
          setOnSaveColumns={setOnSaveColumns}
          functionName={functionName}
          paginationModel={paginationModel}
        />
      </Box>
    </Box>
    <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
      <DataGrid
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          toolbar: () => <CustomToolbar 
              selectedRows={rowSelectionModel} 
              columns={columns}
              setOnSaveColumns={setOnSaveColumns}
              functionName={functionName}
              modelTable={modelTable}
              handleClickAllData={handleClickAllData}
              selectAll={selectAll}
            />,
            footer: () => <CustomGlobalFooter selectedRows={rowSelectionModel.length}/>
        }}
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        loading={loading}
        rows={pulloutRequests}
        columns={filteredAndOrderedColumns}
        pageSizeOptions={pageSizeOptions}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        sortingOrder={['asc', 'desc']}
        getRowHeight={() => 'auto'}
        autoHeight
        keepNonExistentRowsSelected
        disableRowSelectionOnClick
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
            py: 1,
          },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            py: '15px',
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            py: '22px',
          },
          '& .MuiDataGrid-columnHeaderCheckbox': {
            opacity: 0.5, 
            pointerEvents: 'none',
            cursor: 'not-allowed'
          }
        }}
      />
    </Box>

    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </div>
  )
}

export default PulloutRequests