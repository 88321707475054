import { Button, Dialog, DialogActions, DialogContent, DialogTitle, StyledEngineProvider, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ProcessDialog from './ProcessDialog';
import MessageDialog from './MessageDialog';
import { PictureAsPdf, Print } from '@mui/icons-material';
import { PDFDocument } from 'pdf-lib';

function TadrPrint({ requestDetails }) {
  const [open, setOpen] = useState(false);
  const [fileType, setFileType] = useState('');
  const [content, setContent] = useState('');
  const [noTadr, setNoTadr] = useState(false);

  useEffect(() => {
    if (requestDetails.tadr_copy_display) {
      const fileExtension = requestDetails.tadr_copy_display.split(';')[0].split('/')[1];
      setFileType(fileExtension === 'pdf' ? 'pdf' : 'image');
      setContent(requestDetails.tadr_copy_display);
      setNoTadr(false);
    } else {
      setContent('');
      setFileType('');
      setNoTadr(true);
    }
  }, [requestDetails.tadr_copy_display]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePrintPDF = async () => {
    if (content) {
      if (fileType === 'pdf') {
        // Directly download the PDF
        const link = document.createElement('a');
        link.href = content;
        link.download = 'tadr.pdf';
        link.click();
      } else if (fileType === 'image') {
        // Convert the image to a PDF and download it
        const pdfDoc = await PDFDocument.create();
        const page = pdfDoc.addPage([600, 600]);
        
        const imageBytes = content.split(',')[1]; // Get the base64 string without the data URL prefix
        const imageBuffer = Uint8Array.from(atob(imageBytes), c => c.charCodeAt(0));
        const image = await pdfDoc.embedPng(imageBuffer);

        const { width, height } = page.getSize();
        page.drawImage(image, {
          x: 0,
          y: 0,
          width,
          height,
        });

        const pdfBytes = await pdfDoc.save();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(new Blob([pdfBytes], { type: 'application/pdf' }));
        link.download = 'tadr.pdf';
        link.click();
      }
    }
  };

  const handlePrintWord = () => {
    if (content) {
      const link = document.createElement('a');
      link.href = content;
      link.download = 'tadr.docx';
      link.click();
    }
  };

  return (
    <div>
      <Button variant="contained" size="small" onClick={handleOpen}>Print TADR</Button>
      <Dialog
        open={open}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle>Print TADR</DialogTitle>
        <DialogContent>
          {noTadr ? (
            <Typography variant="h6">No TADR available</Typography>
          ) : fileType === 'pdf' ? (
            <iframe
              src={content}
              title="PDF"
              width="100%"
              height="500px"
            />
          ) : (
            <img
              src={content}
              alt="tadr"
              width="100%"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handlePrintPDF} 
            color="primary" 
            startIcon={<PictureAsPdf />}
            disabled={noTadr}
          >
            Print as PDF
          </Button>
          <Button 
            onClick={handlePrintWord} 
            color="secondary" 
            startIcon={<Print />}
            disabled={noTadr}
          >
            Print as Word
          </Button>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={false} ContentText="Loading Please Wait..." />
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={false}
          handleClose={() => {}}
          message=""
        />
      </StyledEngineProvider>
    </div>
  );
}

export default TadrPrint;
