import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Backdrop, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Modal, NativeSelect, Select, StyledEngineProvider, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 500,
  maxHeight: '90vh',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function AddPrincipal({setOnEditOrDelete}) {
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [newAccount, setNewAccount] = useState({
    username: '',
    email_address: '',
    password: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    mobile_number: '',
    role_id: 2,
    maximum_users: 1,
  });
  const [showConfirmPassword, setshowConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState('');
  const [showProgress,setShowProgress] = useState(false)
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const objectToFormData = (object) => {
    const formData = new FormData();
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        formData.append(key, object[key]);
      }
    }

    return formData;
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setshowConfirmPassword((prev) => !prev);
  };

  const handleSelectRoleChange = (event) => {
    setNewAccount((prevAccount) => ({ ...prevAccount, role_id: event.target.value }))
  };


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!newAccount.username || !newAccount.first_name || !newAccount.middle_name || !newAccount.last_name || !newAccount.mobile_number || !newAccount.email_address || !newAccount.password) {
        setOpenMessageDialog(true);
        setMessage('All Fields are required')
        return
    }

    if(newAccount.password !== confirmPassword) {
      setOpenMessageDialog(true);
      setMessage('Password did not match.')
      return
    }

    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
            url: '/ApiUsers/addAccount.json',
            data: objectToFormData(newAccount),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.result.is_success) {
          setOnEditOrDelete((prev) => !prev)
          setOpen(false)
          setOpenMessageDialog(true);
          setMessage(response.data.result.message)
          resetForm();
        }
    } catch (error) {
      console.log(error);
    }

  };

  const resetForm = () => {
    setNewAccount({
      username: '',
      email_address: '',
      password: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      mobile_number: '',
      role_id: 2,
      maximum_users: '',
    });
    setConfirmPassword('');
    setShowPassword('');
  };

  return (
    <div>
      <Button variant="contained" size="small" onClick={handleOpen}>Create Principal</Button>
      <Dialog
        open={open}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
      >
        <DialogTitle>Create Principal Account</DialogTitle>
        <DialogContent>
        <Box sx={{marginBottom: 2 }}>
          <Chip label="Credentials" size="small" color="secondary" />
        </Box>
        <Grid container spacing={1}>
            <Grid item xs={12}>
            <TextField
                required
                id="username"
                name="username"
                label="Username"
                fullWidth
                variant="standard"
                value={newAccount.username}
                onChange={(e) => setNewAccount((prevAccount) => ({ ...prevAccount, username: e.target.value }))}
            />
            </Grid>
            <Grid item xs={12}>
            <TextField
                required
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                fullWidth
                variant="standard"
                value={newAccount.password}
                onChange={(e) => setNewAccount((prevAccount) => ({ ...prevAccount, password: e.target.value }))}
                InputProps={{
                    endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePasswordVisibility}
                        >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                    ),
                }}
            />
            </Grid>
            <Grid item xs={12}>
            <TextField
                required
                name="confirmPassword"
                label="Confirm Password"
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirmPassword"
                fullWidth
                variant="standard"
                onChange={(e) => setConfirmPassword(e.target.value)}
                InputProps={{
                    endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleToggleConfirmPasswordVisibility}
                        >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                    ),
                }}
                value={confirmPassword}
            />
            </Grid>
            <Grid item xs={12}>
            <FormControl fullWidth>
            <InputLabel variant="standard">
            Role
            </InputLabel>
            <NativeSelect
                defaultValue={2}
                inputProps={{
                    name: 'role',
                    id: 'role',
                }}
                onChange={handleSelectRoleChange}
            >
            <option value={2}>Principal</option>
            </NativeSelect>
            </FormControl>
            </Grid>
            <Box sx={{ marginTop: 5, marginBottom: 2 }}>
            <Chip label="Personal Information" size="small" color="secondary" />
            </Box>
            <Grid item xs={12}>
            <TextField
                required
                id="firstName"
                name="firstName"
                label="First name"
                fullWidth
                variant="standard"
                onChange={(e) => setNewAccount((prevAccount) => ({ ...prevAccount, first_name: e.target.value }))}
                value={newAccount.first_name}
            />
            </Grid>
            <Grid item xs={12}>
            <TextField
                required
                id="middleName"
                name="middleName"
                label="Middle name"
                fullWidth
                variant="standard"
                onChange={(e) => setNewAccount((prevAccount) => ({ ...prevAccount, middle_name: e.target.value }))}
                value={newAccount.middle_name}
            />
            </Grid>
            <Grid item xs={12}>
            <TextField
                required
                id="lastName"
                name="lastName"
                label="Last name"
                fullWidth
                variant="standard"
                onChange={(e) => setNewAccount((prevAccount) => ({ ...prevAccount, last_name: e.target.value }))}
                value={newAccount.last_name}
            />
            </Grid>
            <Box sx={{ marginTop: 5, marginBottom: 2 }}>
              <Chip label="Contacts" size="small" color="secondary"/>
            </Box>
            <Grid item xs={12}>
            <TextField
                required
                id="mobile"
                name="mobile"
                label="Mobile Number"
                fullWidth
                variant="standard"
                onChange={(e) => setNewAccount((prevAccount) => ({ ...prevAccount, mobile_number: e.target.value }))}
                value={newAccount.mobile_number}
            />
            </Grid>
            <Grid item xs={12}>
            <TextField
                required
                id="emailAddress"
                name="emailAddress"
                label="Email Address"
                fullWidth
                variant="standard"
                onChange={(e) => setNewAccount((prevAccount) => ({ ...prevAccount, email_address: e.target.value }))}
                value={newAccount.email_address}
            />
            </Grid>
            <Box sx={{ marginTop: 5, marginBottom: 2 }}>
            <Chip label="Users Limit" size="small" color="secondary" />
            </Box>
            <Grid item xs={12}>
            <TextField
                required
                id="maxUsers"
                name="maxUsers"
                label="Maximum Users"
                fullWidth
                variant="standard"
                onChange={(e) => setNewAccount((prevAccount) => ({ ...prevAccount, maximum_users: e.target.value }))}
                value={newAccount.maximum_users}
            />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button onClick={handleClose}>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>
    </div>
  );
}