import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, StyledEngineProvider, TextField, Typography } from '@mui/material';
import React, { useState } from 'react'
import useAuth from '../../hooks/UseAuth';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { objectToFormData } from '../GenericFunctions/GenericFunctions';

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);


function UpdateRemarks({requestDetails, setOnEditOrDelete}) {
  const axios = AxiosCustomConfig();
  const {auth} = useAuth();
  const theme = useTheme();

  const [open, setOpen] = useState();
  const [openMessageDialog, setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [editRemarks, setEditRemarks] = useState({
    remarks: '',
    delay_reasons: '',
    request_code: '',
  });

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);

    const {remarks, delay_reasons, request_code} = requestDetails

    const reasonsArray = delay_reasons ? delay_reasons.split(',').map(reason => reason.trim()) : [];

    setSelectedReasons(reasonsArray);
    setEditRemarks({
      remarks: remarks,
      delay_reasons: delay_reasons,
      request_code: request_code,
      });
  }

  const handleClose = () => {
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (selectedReasons.length === 0 || !editRemarks.remarks) {
      setOpenMessageDialog(true);
      setMessage('All Fields with asterisks (*) are required');
      return false;
    }

    setShowProgress(true);
    try {
        const options = {
            method: 'POST',
              url: '/PulloutRequests/updateRemarks.json',
              data: objectToFormData(editRemarks),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          setOpenMessageDialog(true);
          setMessage(response.data.message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. There's something wrong with the server.`);
    }
    
  }

  const handleChange = (event) => {
    const reason = event.target.name;
    const updatedReasons = selectedReasons.includes(reason)
      ? selectedReasons.filter(item => item !== reason)
      : [...selectedReasons, reason];

    setSelectedReasons(updatedReasons);
    
    // Combine the selected reasons into a single string and update the editRemarks state
    setEditRemarks((prevRemarks) => ({
      ...prevRemarks,
      delay_reasons: updatedReasons.join(', '),
    }));
  };

  const reasons = [
    'no available unit',
    'with BO for refund',
    'no store contact',
    'no feedback from ahente - requestor',
    'no tandem',
    'Permit/ document required',
    'other store issues',
    'no truck/driver/hauler'
  ];

  return (
    <div>
      <Button
        disabled={Number(auth.role) === 6}
        sx={{
          backgroundColor: '#A7B11A',
          color: '#000',
          '&:hover': {
            backgroundColor: '#8F9E16', // Custom hover background color
            color: '#fff' // Custom hover text color
          }
        }}
        variant="outlined"
        size="small"
        onClick={handleOpen}
      >
        Update remarks
      </Button>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'sm'}
    >
      <DialogTitle>Update Remarks</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} >
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">*Reason for delay:</FormLabel>
                <FormGroup>
                  {reasons.map((reason) => (
                    <FormControlLabel
                      key={reason}
                      control={
                        <Checkbox
                          checked={selectedReasons.includes(reason)}
                          onChange={handleChange}
                          name={reason}
                        />
                      }
                      label={reason}
                    />
                  ))}
                </FormGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <Typography>*Remarks:</Typography>
              <Textarea 
                aria-label="minimum height" 
                minRows={3} 
                placeholder="Remarks" 
                onChange={(e) => setEditRemarks((prevAccount) => ({ ...prevAccount, remarks: e.target.value }))}
                value={editRemarks.remarks}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => 
          {
            setOnEditOrDelete((prev) => !prev)
            setOpenMessageDialog(false)
            setOpen(false)
          }}
          message={message}
        />
      </StyledEngineProvider>
    </div>
  )
}

export default UpdateRemarks