import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData, validateAlphaNumeric } from '../GenericFunctions/GenericFunctions';
import styled from '@emotion/styled';
import { CloudUpload } from '@mui/icons-material';

function AddStatus({setOnEditOrDelete}) {
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [assetStatus, setAssetStatus] = useState({
    name: '',
    description: '',
  });


  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!assetStatus.name) {
        setOpenMessageDialog(true);
        setMessage('Status Name is required')
        return
    }

    if (!isValidSpecialCharacter(assetStatus.name)) {
        setOpenMessageDialog(true);
        setMessage('Invalid Special Characters found in Account Name.')
        return
    }

    if (!isValidSpecialCharacter(assetStatus.description)) {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in Description.')
      return
    }

    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/AssetStatus/addAssetStatus.json',
              data: objectToFormData(assetStatus),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          resetForm();
          setOnEditOrDelete((prev) => !prev)
          setOpen(false)
          setOpenMessageDialog(true);
          setMessage(response.data.message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Please try again and make sure you have internet connection.`);
    }
  }

  const resetForm = () => {
    setAssetStatus({
      name: '',
      description: '',
    });
  };


  return (
    <div>
    <Button variant="contained" size="small" onClick={handleOpen}>Create Asset Status</Button>
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Add New Status</DialogTitle>
      <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                  name="name"
                  label="Name*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAssetStatus((prevAccount) => ({ ...prevAccount, name: e.target.value }))}
                  value={assetStatus.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="description"
                  label="Description"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAssetStatus((prevAccount) => ({ ...prevAccount, description: e.target.value }))}
                  value={assetStatus.description}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default AddStatus