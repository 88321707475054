import { Avatar, Box, Chip, StyledEngineProvider, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import MessageDialog from '../Utils/MessageDialog'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import useShopRepairContext from '../../hooks/useShopRepairContext';


function AssetDisplay({asset_code}) {
  const axios = AxiosCustomConfig();
  const {doneUpdate} = useShopRepairContext();
  const [assets, setAssets] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const columns = [
    { field: 'code', headerName: 'Code', flex: 1, minWidth: 100, wrap: true,},
    { field: 'name', headerName: 'Name', width: 100,},
    { field: 'status_name', headerName: 'Status', flex: 1, minWidth: 100, wrap: true, },
    { field: 'description', headerName: 'Desciption',flex: 1, minWidth: 100, wrap: true  },
    { field: 'asset_type_mame', headerName: 'Asset Type',flex: 1, minWidth: 100},
  ];
  const getAssets = async (ignore) => {
    try {
      setLoading(true);
      const options = {
        'method': 'POST',
        'url' : '/ShopRepairs/getAssetDetails.json',
        'data' : {asset_code : asset_code}
      }
      const response = await axios(options);
      const data = response.data;
      if(!ignore) {
        setAssets([data])
        setLoading(false);
      }
      
    } catch (error) {
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Please try again and make sure you have internet connection.`);
    } finally {
      if(!ignore) {
        setLoading(false);
      }
      
    }
  }

  useEffect(() => {
    let ignore = false
    getAssets(ignore)
    return ()=>{ignore=true} 
  }, [doneUpdate]);

  return (
    <Box>
    <Box p={1}>
      <Typography>Asset details</Typography>
    </Box>
    <Box sx={{width: '100%', height: 100, display:'table', tableLayout: 'fixed'}} >
    {assets && 
      <DataGrid
        autoHeight
        keepNonExistentRowsSelected
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        loading={loading}
        rows={assets ? assets : []}
        columns={columns}
        getRowHeight={() => 'auto'}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
            py: 1,
          },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            py: '15px',
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            py: '22px',
          },
        }}
        />
      }
    </Box>

    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </Box>
  )
}


export default AssetDisplay