import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Button, Typography, Alert, IconButton } from '@mui/material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from './ProcessDialog';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';

const SortingCustomSettingsModal = ({ open, onClose, columns, setOnSaveColumns, functionName }) => {
  const axios = AxiosCustomConfig();
  const [sortableColumns, setSortableColumns] = useState([]);
  const [nonSortableColumns, setNonSortableColumns] = useState([]);
  const [showProgress, setShowProgress] = useState(false);

  useEffect(() => {
    const sortable = columns.filter(col => col.is_sortable === 0);
    const nonSortable = columns.filter(col => col.is_sortable === 1);
    setSortableColumns(sortable);
    setNonSortableColumns(nonSortable);
  }, [columns]);

  // Toggle sorting between ASC (0) and DESC (1)
  const toggleSortOrder = (key) => {
    setSortableColumns(sortableColumns.map(column => 
      column.key === key
        ? { ...column, sort_order: column.sort_order === 0 ? 1 : 0 } // Toggle between ASC (0) and DESC (1)
        : { ...column, sort_order: 0 } // Set other columns' sort_order to null
    ));
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === 'sortable') {
        const updated = Array.from(sortableColumns);
        const [moved] = updated.splice(source.index, 1);
        updated.splice(destination.index, 0, moved);
        setSortableColumns(updated);
      } else {
        const updated = Array.from(nonSortableColumns);
        const [moved] = updated.splice(source.index, 1);
        updated.splice(destination.index, 0, moved);
        setNonSortableColumns(updated);
      }
    } else {
      let sourceList = source.droppableId === 'sortable' ? sortableColumns : nonSortableColumns;
      let destinationList = destination.droppableId === 'sortable' ? sortableColumns : nonSortableColumns;

      const [moved] = sourceList.splice(source.index, 1);
      moved.is_sortable = destination.droppableId === 'sortable' ? 0 : 1;
      destinationList.splice(destination.index, 0, moved);

      setSortableColumns([...sortableColumns]);
      setNonSortableColumns([...nonSortableColumns]);
    }
  };

  const saveColumns = async () => {
    try {
      setShowProgress(true);
      const updatedColumns = [...sortableColumns, ...nonSortableColumns];
      const options = {
        method: 'POST',
        url: '/CsvColumnSettings/saveSortColumns.json',
        data: { field_list: updatedColumns, function_name: functionName },
      };
      const response = await axios(options);
      const data = response.data;

      if (data) {
        setOnSaveColumns(prev => !prev);
      } else {
        console.error('Failed to save columns:', response.statusText);
      }
      setShowProgress(false);
    } catch (error) {
      console.error('Error saving columns:', error);
    }
  };

  const handleSave = () => {
    onClose();
    saveColumns();
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Sort Column Settings</DialogTitle>
        <DialogContent>
          <Alert severity="info">
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography variant="caption" color="initial">Notes:</Typography>
              <Typography variant="caption" color="initial">Just move fields between the boxes to include or exclude fields for sorting.</Typography>
              <Typography variant="caption" color="initial">The "Action" column is always not sortable and cannot be moved.</Typography>
              <Typography variant="caption" color="initial">Click on arrow icon to change sorting condition.</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ArrowUpward fontSize="small" sx={{ marginRight: 0.5 }} />
                <Typography variant="caption" color="initial">Ascending Order</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ArrowDownward fontSize="small" sx={{ marginRight: 0.5 }} />
                <Typography variant="caption" color="initial">Descending Order</Typography>
              </Box>
            </Box>
          </Alert>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="sortable">
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    sx={{ width: '45%', border: '1px solid #ddd', padding: 1, borderRadius: 1, minHeight: 200 }}
                  >
                    <Typography variant="h6">Fields for sorting</Typography>
                    {sortableColumns.map((column, index) => (
                      <Draggable key={column.key} draggableId={column.key} index={index}>
                        {(provided) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              padding: 1,
                              marginBottom: 1,
                              backgroundColor: '#fff',
                              border: '1px solid #ddd',
                              borderRadius: 1,
                            }}
                          >
                            <IconButton onClick={() => toggleSortOrder(column.key)} size="small" sx={{ marginRight: 1 }}>
                              {column.sort_order === 0 ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />}
                            </IconButton>
                            <Typography variant="body2">{column.value}</Typography>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>

              <Droppable droppableId="nonSortable">
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    sx={{ width: '45%', border: '1px solid #ddd', padding: 1, borderRadius: 1, minHeight: 200 }}
                  >
                    <Typography variant="h6">Exclude from sorting</Typography>
                    {nonSortableColumns.map((column, index) => (
                      <Draggable key={column.key} draggableId={column.key} index={index} isDragDisabled={column.key === 'action'}>
                        {(provided) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              padding: 1,
                              marginBottom: 1,
                              backgroundColor: column.key === 'action' ? '#f0f0f0' : '#fff',
                              border: '1px solid #ddd',
                              borderRadius: 1,
                              opacity: column.key === 'action' ? 0.5 : 1,
                            }}
                          >
                            <Typography variant="body2">{column.value}</Typography>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">Cancel</Button>
          <Button onClick={handleSave} variant="contained" color="primary">Save</Button>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..." />
    </>
  );
};

export default SortingCustomSettingsModal;
