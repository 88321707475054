import { saveAs } from 'file-saver';

export const exportToCsv = (fileName, headers, rows) => {
  const csvContent = [
    headers.map(header => header.label).join(','), // CSV header
    ...rows.map(row =>
      headers.map(header => {
        const cell = row[header.key] !== undefined ? row[header.key] : '';
        return typeof cell === 'string' ? `"${cell.replace(/"/g, '""')}"` : cell;
      }).join(',')
    ),
  ].join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, fileName);
};