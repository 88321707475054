import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { Settings } from '@mui/icons-material';
import ColumnSettingsModal from '../Utils/CustomSettingsModal';
import SortingCustomSettingsModal from './SortingCustomSettingsModal';

const ColumnSettingsButton = ({ columns, setOnSaveColumns, functionName, paginationModel }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [isSortingModalOpen, setIsSortingModalOpen] = useState(false);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenSettingsModal = () => {
    setIsSettingsModalOpen(true);
    handleClose();
  };

  const handleCloseSettingsModal = () => {
    setIsSettingsModalOpen(false);
  };

  const handleOpenSortingModal = () => {
    setIsSortingModalOpen(true);
    handleClose();
  };

  const handleCloseSortingModal = () => {
    setIsSortingModalOpen(false);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton onClick={handleClick} color="primary">
        <Settings />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ marginTop: '8px' }}
      >
        <MenuItem onClick={handleOpenSortingModal}>
          <Typography sx={{ fontSize: '12px' }}>Sort Column Settings</Typography>
        </MenuItem>
        <MenuItem onClick={handleOpenSettingsModal}>
          <Typography sx={{ fontSize: '12px' }}>Listing Column Settings</Typography>
        </MenuItem>
      </Menu>

      <ColumnSettingsModal
        open={isSettingsModalOpen}
        onClose={handleCloseSettingsModal}
        columns={columns}
        setOnSaveColumns={setOnSaveColumns}
        functionName={functionName}
        paginationModel={paginationModel}
      />

      <SortingCustomSettingsModal
        open={isSortingModalOpen}
        onClose={handleCloseSortingModal}
        columns={columns}
        setOnSaveColumns={setOnSaveColumns}
        functionName={functionName}
      />
    </>
  );
};

export default ColumnSettingsButton;