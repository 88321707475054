import React, { useState } from 'react';
import { Grid, Paper, Box, Typography, Button, Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';

function ImageBeforeRepairSlide({ imageAfterData }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [open, setOpen] = useState(false);

  const handleImageClick = (index) => {
    setSelectedImage(imageAfterData[index]);
    setCurrentIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNextImage = () => {
    const nextIndex = (currentIndex + 1) % imageAfterData.length;
    setSelectedImage(imageAfterData[nextIndex]);
    setCurrentIndex(nextIndex);
  };

  const handlePrevImage = () => {
    const prevIndex = (currentIndex - 1 + imageAfterData.length) % imageAfterData.length;
    setSelectedImage(imageAfterData[prevIndex]);
    setCurrentIndex(prevIndex);
  };

  return (
    <>
      <Box>
        <Typography mb={1}>Photos after repair:</Typography>
        <Box display="flex" flexDirection="row" flexWrap="nowrap" sx={{ width: '100%', height: 110 }}>
          {imageAfterData.map((img, index) => (
            <div key={img} style={{ width: 100, height: 100, flex: '0 0 auto', marginLeft: index > 0 ? '5px' : '0' }}>
              <img
                style={{ width: '100%', height: '100%', objectFit: 'cover', display: 'block', cursor: 'pointer' }}
                src={img}
                alt='before repair'
                loading="lazy"
                onClick={() => handleImageClick(index)}
              />
            </div>
          ))}
        </Box>
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          <IconButton aria-label="close" style={{ position: 'absolute', top: 0, right: 0 }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          {selectedImage && (
            <img
              src={selectedImage}
              alt='before repair'
              style={{ maxWidth: '100%', maxHeight: '70vh', objectFit: 'contain' }}
            />
          )}
          {currentIndex > 0 && (
            <IconButton disabled={currentIndex === 0} onClick={handlePrevImage} style={{ position: 'absolute', top: '50%', left: 0, transform: 'translateY(-50%)', backgroundColor: 'rgba(7, 11, 10, 0.5)' }}>
              <NavigateBefore style={{ color: 'white' }}/>
            </IconButton>
          )}
          {currentIndex < imageAfterData.length - 1 && (
            <IconButton disabled={currentIndex === imageAfterData.length - 1} onClick={handleNextImage} style={{ position: 'absolute', top: '50%', right: 0, transform: 'translateY(-50%)', backgroundColor: 'rgba(7, 11, 10, 0.5)' }}>
              <NavigateNext style={{ color: 'white' }}/>
            </IconButton>
          )}
        </Box>
      </Modal>
    </>
  );
}

export default ImageBeforeRepairSlide;
