import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/UseAuth";

const RequireAuth = ({ redirectPath, children }) => {
    const { auth } = useAuth();
    const location = useLocation();

    if (auth?.token) {
        if (location.pathname === '/login') {
            return <Navigate to={redirectPath} replace />;
        }
        return children ? children : <Outlet />;
    }

    return <Navigate to="/login" state={{ from: location }} replace />;
}

export default RequireAuth;