import React, { createContext, useState } from 'react'

const ServiceRequestContext = createContext({});

export const ServiceRequestProvider = ({children}) => {
  const [doneUpdate, setDoneUpdate] = useState(false);

  return (
      <ServiceRequestContext.Provider value={{ doneUpdate, setDoneUpdate }}>
          {children}
      </ServiceRequestContext.Provider>
  )
}

export default ServiceRequestContext