import { Box, StyledEngineProvider, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { format } from 'date-fns';
import React, { useEffect } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { useState } from 'react';
import AddAccounts from './AddAccounts';
import EditAccounts from './EditAccounts';
import DeleteAccounts from './DeleteAccounts';
import MessageDialog from '../Utils/MessageDialog';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import CustomLoadingOverlay from '../Utils/CustomLoadingOverlay';



const Accounts = () => {
  const axios = AxiosCustomConfig();
  const [onEditOrDelete, setOnEditOrDelete] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  
  const columns = [
    { field: 'account_code', headerName: 'Account code',flex: 1, minWidth: 150, wrap: true },
    { field: 'account_name', headerName: 'Account name', flex: 1, minWidth: 150, wrap: true},
    { field: 'branch_code', headerName: 'Branch code',flex: 1, minWidth: 150, wrap: true },
    { field: 'branch_name', headerName: 'Branch name',flex: 1, minWidth: 150, wrap: true },
    { field: 'contact', headerName: 'Contact',flex: 1, minWidth: 150, wrap: true },
    { field: 'created', headerName: 'Created',flex: 1, minWidth: 150,
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }
    },
    { field: 'modified', headerName: 'Modified',flex: 1, minWidth: 150,  
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }
    },
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <>
          <EditAccounts accountsData={params.row} setOnEditOrDelete={setOnEditOrDelete}/>
          <DeleteAccounts accountsData={params.row} setOnEditOrDelete={setOnEditOrDelete}/>
        </>
      ),
    },
  ];


  useEffect(() => {
    const getAccounts = async () => {
      setLoading(true)
      try {
        const options = {
          'method': 'POST',
          'url' : '/ApiAccounts/getAccounts.json',
        }
        const response = await axios(options);
        const data = response.data;

        if (data) {
          setAccounts(data.result)
        }
        setLoading(false)
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Please try again and make sure you have internet connection.`);
      }
    }

    getAccounts();
  }, [onEditOrDelete]);

  
  return (
    <>
    <Box sx={{ marginBottom: '20px' }}>
      {/* add users button */}
      <AddAccounts setOnEditOrDelete={setOnEditOrDelete}/>
    </Box>
    <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
      <DataGrid
        rows={accounts}
        columns={columns}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          loadingOverlay: CustomLoadingOverlay,
        }}
        loading={loading}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        getRowHeight={() => 'auto'}
        autoHeight
        keepNonExistentRowsSelected
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
            py: 1,
          },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            py: '15px',
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            py: '22px',
          },
        }}
      />
    </Box>

    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </>
  )
}

export default Accounts