import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Button, Typography, Checkbox, FormControlLabel, Alert, TextField } from '@mui/material';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { exportToCsv } from './exportToCsv';
import CsvDownloadStatusDialog from './CsvDownloadStatusDialog';

const CustomExportSettingsModal = ({ open, onClose, columns, setOnSaveColumns, functionName, selectedRows, modelTable }) => {
  const axios = AxiosCustomConfig();
  const [localColumns, setLocalColumns] = useState([]);
  const [exportName, setExportName] = useState('');
  const [showDownloadStatusDialog, setShowDownloadStatusDialog] = useState(false);
  const [downloadPercent, setDownloadPercent] = useState(0);

  useEffect(() => {
    setLocalColumns(columns);
  }, [columns]);

  const handleToggleHide = (key) => {
    setLocalColumns(localColumns.map(column =>
      column.key === key ? { ...column, is_disabled: column.is_disabled ? 0 : 1 } : column
    ));
  };

  const handleSave = async () => {
    onClose();
    setShowDownloadStatusDialog(true);
    try {
      const totalRows = selectedRows.length;
      let processedRows = 0;
      let csvRows = []; // Collect rows for CSV export

      const headers = localColumns
        .filter(col => !col.is_disabled)
        .map(col => ({
          key: col.key,
          label: col.value
        }));

      // Process rows in batches of 20
      for (let i = 0; i < totalRows; i += 20) {
        const batch = selectedRows.slice(i, i + 20);

        const options = {
          method: 'POST',
          url: '/CsvColumnSettings/exportColumns.json',
          data: {
            field_list: localColumns,
            function_name: functionName,
            selected_rows: batch,
            file_name: exportName,
            table: modelTable,
          },
        };

        const response = await axios(options);
        if (response.data.data) {
          csvRows.push(...response.data.data); // Add the processed data to the CSV rows
        }

        processedRows += batch.length;
        setDownloadPercent((processedRows / totalRows) * 100);
      }

      // Export to CSV
      exportToCsv(`${exportName || 'export'}.csv`, headers, csvRows);

      setShowDownloadStatusDialog(false);
      setOnSaveColumns(prev => !prev);
    } catch (error) {
      console.error('Error exporting CSV:', error);
      setShowDownloadStatusDialog(false); // Handle case where download fails
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Export</DialogTitle>
        <DialogContent>
          <Alert severity="info">
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography variant="caption" color="initial">Notes:</Typography>
              <Typography variant="caption" color="initial">Click checkbox to include in download data</Typography>
            </Box>
          </Alert>

          <TextField
            name="export_name"
            label="File Name"
            fullWidth
            variant="standard"
            onChange={(e) => setExportName(e.target.value)}
            value={exportName}
            sx={{
              marginBottom: '15px',
              marginTop: '15px'
            }}
          />

          <Box
            sx={{ marginTop: 1, overflowY: 'auto' }}
          >
            {localColumns.map((column, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: 0.5,
                  marginBottom: 1,
                  backgroundColor: '#fff',
                  border: '1px solid #ddd',
                  borderRadius: 1,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!column.is_disabled}
                      onChange={() => handleToggleHide(column.key)}
                    />
                  }
                  label={
                    <Typography variant="body2" sx={{ fontSize: '12px' }}>
                      {column.value}
                    </Typography>
                  }
                />
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">Cancel</Button>
          <Button onClick={handleSave} variant="contained" color="primary">Export</Button>
        </DialogActions>
      </Dialog>

      <CsvDownloadStatusDialog
        modalTitle="Download Progress"
        showDownloadStatusDialog={showDownloadStatusDialog}
        downloadPercent={downloadPercent}
        handleClose={() => setShowDownloadStatusDialog(false)}
      />
    </>
  );
};

export default CustomExportSettingsModal;
