import React, { createContext, useState } from 'react'

const AssetDisposalContext = createContext({});

export const AssetDisposalProvider = ({children}) => {
  const [doneUpdate, setDoneUpdate] = useState(false);

  return (
      <AssetDisposalContext.Provider value={{ doneUpdate, setDoneUpdate }}>
          {children}
      </AssetDisposalContext.Provider>
  )
}

export default AssetDisposalContext