import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';


function EditPulloutTypes({pullOutTypesData, setOnEditOrDelete}) {
  const theme = useTheme();
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState({
    unique_code: '',
    name: '',
    description: '',
    id: ''
  });
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
    const { unique_code, name, description, id} = pullOutTypesData;
    setEditData((prevData) => ({ 
      ...prevData, 
      unique_code: unique_code || prevData.unique_code, 
      name: name || prevData.name, 
      description: description || prevData.description, 
      id: id || prevData.id,
    }))
  };

  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const resetForm = () => {
    setEditData({
      name: '',
      description: '',
      id: ''
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!editData.name) {
        setOpenMessageDialog(true);
        setMessage('Asset Type is required')
        return
    }

    if (!isValidSpecialCharacter(editData.name)) {
        setOpenMessageDialog(true);
        setMessage('Invalid Special Characters found in Asset Type Name.')
        return
    }

    if (!isValidSpecialCharacter(editData.description)) {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in Description.')
      return
    }


    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/PulloutTypes/updateType.json',
              data: objectToFormData(editData),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          setOpenMessageDialog(true);
          setMessage(response.data.message)
          resetForm();
          setOnEditOrDelete((prev) => !prev)
          setOpen(false)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}.`);
    }
  }

  return (
    <div>
      <Button 
        variant="outlined" 
        size="small" 
        sx={{color: theme.palette.secondary.dark, marginRight: '10px'}} 
        onClick={(e) => {handleOpen(e)}}>
      Edit
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
        maxWidth={'md'}
        fullWidth={true}
      >
        <DialogTitle>Edit Type</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                  label="Name*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, name: e.target.value }))}
                  value={editData.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="description"
                  label="Description"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, description: e.target.value }))}
                  value={editData.description}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>
    </div>
  )
}

export default EditPulloutTypes