import { useEffect, useState } from 'react';

const useSortModel = (columns) => {
  const [sortModel, setSortModel] = useState([]);

  useEffect(() => {
    const initialSortModel = columns
      .filter(col => col.sort_order)
      .map(col => ({
        field: col.key,
        sort: col.sort_order === 'asc' ? 'asc' : 'desc',
      }));
    setSortModel(initialSortModel);
  }, [columns]);

  return [sortModel, setSortModel];
};

export default useSortModel;
