import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography } from '@mui/material';
import React from 'react';

const CsvDownloadStatusDialog = ({ modalTitle, showDownloadStatusDialog, downloadPercent, handleClose }) => {
  return (
    <Dialog fullWidth={true} maxWidth={'sm'} open={showDownloadStatusDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle>{modalTitle}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress sx={{ height: 10 }} color="success" variant="determinate" value={downloadPercent} />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">{`${Math.round(downloadPercent)}%`}</Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CsvDownloadStatusDialog;
