// import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig'
import { useLocation, useNavigate } from 'react-router-dom';



const Dashboard = ({setIsLogin}) => {
  const axios = AxiosCustomConfig()
  const [data, setData] = useState('');
  const navigate = useNavigate()
  const location = useLocation();


  return (
    <div>
      Welcome!
    </div>
  )
}

export default Dashboard