import { Box, Breadcrumbs, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, Paper, StyledEngineProvider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import MessageDialog from '../Utils/MessageDialog';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import useAuth from '../../hooks/UseAuth';
import ReactSignatureCanvas from 'react-signature-canvas';
import CustomDatatable from '../Utils/CustomDatatable';
import ProcessDialog from '../Utils/ProcessDialog';
import { Close, CloudUpload } from '@mui/icons-material';
import { objectToFormData } from '../GenericFunctions/GenericFunctions';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import ApproveDeploymentRequest from './ApproveDeploymentRequest';
import useCommentsContext from '../../hooks/UseCommentsContext';
import RejectDeploymentRequest from './RejectDeploymentRequest';
import CancelledRequest from './CancelledRequest';
import UproductiveDelivery from './UproductiveDelivery';
import EditRequest from './EditRequest';
import UpdateRemarks from './UpdateRemarks';
import MoreOptionBtn from './MoreOptionBtn';
import TadrPrint from '../Utils/TadrPrint';
import BlankTadr from '../Utils/BlankTadr';


const CustomPaper = styled(Paper)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: 20
}));

const Item = ({ children }) => (
  <CustomPaper elevation={0}>
    {children}
  </CustomPaper>
);



function RequestDetails() {
    const theme = useTheme();
    const {auth} = useAuth();
    const { requestCode } = useParams();
    const axios = AxiosCustomConfig();
    const {setDoneUpdate} = useCommentsContext();
    const tableRef = useRef();
    const url = 'AssetsRegistry/getAssetsForApprovalRequest.json';
    const [openMessageDialog,setOpenMessageDialog] = useState(false)
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [requestDetails, setRequestDetails] = useState({});
    const [openSignatureDialog, setOpenSignatureDialog] = useState(false);
    const signatureRef = useRef();
    const [requests, setRequests] = useState({
      request_code: requestCode,
      assets: [],
      signature_url: '',
      image: '',
      date_served: '',
      name: '',
      asset_code: '',
      asset_images: []
    });
    const [showProgress, setShowProgress] = useState(false);
    const [openAssetDialog, setOpenAssetDialog] = useState(false);
    const [openDeployAssetDialog, setOpenDeployAssetDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [doneApproving, setDoneApproving] = useState(false);
    const [onEditOrDelete, setOnEditOrDelete] = useState(false);

    useEffect(() => {
        let ignore = false;

        const getRequestDetails  = async (ignore) => {
            try {
                setLoading(true);
                const options = {
                    'method': 'POST',
                    'url' : '/DeploymentRequests/getRequestDetails.json',
                    'data': {'request_code': requestCode}
                  }
                  const response = await axios(options);
                  const data = response.data;

                  if (!ignore) {
                    setRequestDetails(data);
                  }
            } catch (error) {
                setOpenMessageDialog(true);
                setMessage(` ${error.toJSON().message}. Please try again and make sure you have internet connection.`);
            } finally {
                setLoading(false);
            }
        }


        getRequestDetails(ignore)
        return () => {
            ignore = true
        };
    }, [requestCode, doneApproving, onEditOrDelete]);

    const renderDetailItem = (title, value) => (
      <Grid container item xs={12} md={12} lg={6}>
        <Typography variant="body1" marginRight={2}>
          <strong>{title} </strong>
        </Typography>
        <Typography variant="body1">
        {title === 'Status:' ? (
          value === "For approval" ? (
            <Chip label={value} sx={{ backgroundColor: '#35A7CA' }} />
          ) : value === "Processing" ? (
            <Chip label={value} sx={{ backgroundColor: '#D7D6D4'}} />
          ) : value === "Deployed" ? (
            <Chip label={value} sx={{ backgroundColor: '#3AD658' }} />
          ) : value === "Rejected" ? (
            <Chip label={value} sx={{ backgroundColor: '#000001', color: '#fff' }} />
          ) : value === "Cancelled" ? (
            <Chip label={value} sx={{ backgroundColor: '#D2893C' }} />
          ) : value === "Unproductive" ? (
            <Chip label={value} sx={{backgroundColor: '#E8484F'}} />
          ) : value === "Unproductive & cancelled" ? (
            <Chip label={value} sx={{backgroundColor: '#DD1F00', color: '#fff'}} />
          ) : value === "Hold" ? (
            <Chip label={value} sx={{backgroundColor: '#F8FD6A'}} />
          ) : value === "Approved" ? (
            <Chip label={value} color='primary' />
          ) : (
            value
          )
        ) : (
          value
        )}
        </Typography>
      </Grid>
    );

    function formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      if (month < 10) {
        month = '0' + month;
      }
      let day = date.getDate();
      if (day < 10) {
        day = '0' + day;
      }
      return `${year}-${month}-${day}`;
    }

    const handleCloseDialog = () => {
      setOpenSignatureDialog(false);
    };
    
    const handleSignButton = () => {
      setOpenSignatureDialog(true);
    };
  
    // const handleSaveSignature = () => {
    //   const dataURL = signatureRef.current.toDataURL('image/png');
    //   if (dataURL) {
    //     setRequests(prevData => {
    //       const updatedData = { ...prevData, signature_url: dataURL };
    //       processAndSendRequest(updatedData);
    //       return updatedData;
    //     });
    //   }
      
    // };
    
    const handleConfirmButton = async () => { 
      if (!selectedRow) {
        setOpenMessageDialog(true);
        setMessage(`Please select asset.`);
        return
      }

      if (requests.asset_images.length === 0) {
        setOpenMessageDialog(true);
        setMessage(`Please select atlest 1 asset image.`);
        return
      }

      if (!requests.tadr_copy) {
        setOpenMessageDialog(true);
        setMessage(`Please select TADR copy.`);
        return
      }

      try{
        setLoading(true);
        setShowProgress(true);

        const formData = new FormData();
        if (requests.asset_images.length >  0) {
          requests.asset_images.forEach((file, index) => {
            formData.append(`asset_images_${index}`, file);
          });
        }

        formData.append('request_code', requests.request_code);
        formData.append('name', requests.name);
        formData.append('asset_code', requests.asset_code);
        formData.append('tadr_copy', requests.tadr_copy);

        const options = {
            'method': 'POST',
            'url' : '/DeploymentRequests/saveAssets.json',
            'data': formData
          }
          const response = await  axios(options);
          const data = response.data;

          if (data.is_success) {
            setOpenAssetDialog(false)
            setShowProgress(false);
            setOpenMessageDialog(true);
            setMessage(data.message);
            resetForm();
            setDoneApproving(true);
          } else {
            setShowProgress(false);
            setOpenMessageDialog(true);
            setMessage(data.message);
          }
          
      } catch (error) {
        console.log(error);
          setShowProgress(false);
          setOpenMessageDialog(true);
          setMessage(`Please try again and make sure you have internet connection.`);
      } finally {
          setLoading(false);
          setShowProgress(false);
      }
    };

    const handleClearSignature = () => {
      signatureRef.current.clear();
    };

    const handleCloseAssetDialog = () => {
      setOpenAssetDialog(false)
    }

    const handleCloseAsset = () => {
      setOpenSignatureDialog(false);
    }

    const handleRowClick = (rowId) => {
      setSelectedRow(rowId === selectedRow ? null : rowId); // Toggle selected row
    };

    const handleSelectedAsset = (selectValue) => {
      if (selectValue) {
        setRequests((prevData) => ({ ...prevData, assets: selectValue, asset_code: selectValue.asset_code, name: selectValue.name}))
      }
    }

    const columnsData = [
        {
            id: "check_box",
            headerName: "Check box",
            render: (params) => {
              return (
                <>
              <Checkbox
                  checked={params.id === selectedRow}
                  onChange={(event) => {
                      // Handle checkbox change
                      const isChecked = event.target.checked;

                      // Update your data accordingly, e.g., params.row.checked = isChecked;
                      if (isChecked) {
                        handleRowClick(params.id)
                        handleSelectedAsset(params)
                      }
                  }}
              />
              </>
          )},
        },
        {
            id: "name",
            headerName: "Name",
        },
    ];

    const resetForm = () => {
      setRequests({
        request_code: requestCode,
        assets: [],
        signature_url: '',
        image: '',
        date_served: '',
        name: '',
        asset_code: '',
      })
    }

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });


  const handleSaveProofOfDelivery = async () => {
    if (!(requests.date_served)) {
      setOpenMessageDialog(true);
      setMessage('Required date served.')
      return
    }

    try{
      setLoading(true);
      setShowProgress(true);
      const options = {
          'method': 'POST',
          'url' : '/DeploymentRequests/saveProofOfDelivery.json',
          'data': objectToFormData(requests)
          
        }
        const response = await axios(options);
        const data = response.data;
        if (data.is_success) {
          setOpenDeployAssetDialog(false)
          setShowProgress(false);
          setOpenMessageDialog(true);
          setMessage(data.message);
          setDoneApproving(prev => !prev);
          setDoneUpdate(prev => !prev);
          resetForm();
        } else {
          setShowProgress(false);
          setOpenMessageDialog(true);
          setMessage(data.message);
        }
        
    } catch (error) {
        setShowProgress(false);
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Please try again and make sure you have internet connection.`);
    } finally {
        setLoading(false);
        setShowProgress(false);
    }
  }

  const handleCloseDeployAssetDialog = () => {
    setOpenDeployAssetDialog(false)
  }

  const handleAssetImageChange = (event) => {
    const selectedFiles = Array.from(event.target.files);

    if (selectedFiles.length > 5) {
      setOpenMessageDialog(true);
      setMessage('You can only upload a maximum of 5 images');
      return;
    }

    setRequests((prev) => ({ ...prev, asset_images: [...prev.asset_images, ...selectedFiles]}))
  };

  const handleTADRChange = (event) => {
    const file = event.target.files[0];
    setRequests((prev) => ({...prev, tadr_copy: file}));
  };

  const removeImage = (indexToRemove) => {
    // Create a new array excluding the image at the specified index
    const updatedImages = requests.asset_images.filter((_, index) => index !== indexToRemove);
    
    // Update the state with the new array of images
    setRequests(prevState => ({
      ...prevState,
      asset_images: updatedImages
    }));
  };

  return (
    <>
      <Box mb={3}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            color="inherit"
            to="/deploymentRequest"
          >
            Request
          </Link>
          <Typography color="text.primary">Details</Typography>
        </Breadcrumbs>
      </Box>

    <Grid container style={{ height: '100%' }} spacing={3}>
      <Grid item xs={12} md={6} lg={8}>
        <Paper elevation={1} style={{ height: '100%', padding: '8px', display:'flex', gap: '5px' }}>
          {requestDetails && <EditRequest requestDetails={requestDetails} setOnEditOrDelete={setOnEditOrDelete} />}
          {Number(auth.role) === 2 || Number(auth.role) === 1 || Number(auth.role) === 6 ? (
            <>
              <TadrPrint requestDetails={requestDetails} />
              <BlankTadr requestDetails={requestDetails} />
              <UpdateRemarks requestDetails={requestDetails} setOnEditOrDelete={setOnEditOrDelete} />
              <MoreOptionBtn requestDetails={requestDetails} setOnEditOrDelete={setOnEditOrDelete} />
            </>
            ) : ''}
        </Paper>
      </Grid>
      <Grid item xs={12} md={6} lg={8}>
        <Item>
          <Grid container direction="row">
          {renderDetailItem('Account code:', requestDetails.account_code)}
          {renderDetailItem('Account name:', requestDetails.account_name)}
          {renderDetailItem('Branch name:', requestDetails.account_name)}
          {renderDetailItem('Ship to/Business Address:', requestDetails.business_address)}
          {renderDetailItem('Asset name:', requestDetails.asset_name)}
          {renderDetailItem('Asset type:', requestDetails.asset_type_name)}
          {renderDetailItem('Deployment type:', requestDetails.deployment_type_name)}
          {renderDetailItem('Remarks:', requestDetails.remarks)}
          {renderDetailItem('Status:', requestDetails.status_name)}
          {renderDetailItem('Status remarks:', requestDetails.reject_remarks)}
            <Grid item>
              <Typography variant="subtitle2">
                <strong>Signature: </strong>
              </Typography>
              <Paper
                sx={{
                    width: 200
                }}
                >
                <img
                  src={requestDetails.signature_image}
                  alt='Asset img'
                  loading="lazy"
                  style={{ width: "100%" }}
                />
              </Paper>
            </Grid>
          </Grid>
        </Item>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Item>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="body1">
                <strong>Expected delivery date: </strong> {requestDetails.expected_delivery_date && formatDate(requestDetails.expected_delivery_date)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <strong>Requestor name: </strong> {requestDetails.requestor_name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <strong>Requested Date: </strong> {requestDetails.datetime_requested && formatDate(requestDetails.datetime_requested)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <strong>Date Served: </strong> {requestDetails.date_served && formatDate(requestDetails.date_served)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="column">
            { Number(auth.role) === 2 || Number(auth.role) === 3 || Number(auth.role) === 5 ?  (
              <>
                <ApproveDeploymentRequest requestDetails={requestDetails}  setDoneApproving={setDoneApproving} />
                <RejectDeploymentRequest requestDetails={requestDetails}  setDoneApproving={setDoneApproving} />
              </>
            ) : Number(auth.role) === 6 ? (
              <>
                  <Button 
                    disabled={(requestDetails.status_code !== 1) ? true : false}
                    variant="contained" 
                    size="small" 
                    sx={{
                          color: theme.palette.primary.main,
                          bgcolor: '#7acda1',
                          '&:hover': {
                            bgcolor: '#ACE1C3',
                          },
                          marginBottom: '10px'
                        }}
                    onClick={() => setOpenDeployAssetDialog(true)}
                  >Deploy Asset</Button>
                  <UproductiveDelivery requestDetails={requestDetails}  setDoneApproving={setDoneApproving} />
                  <Button
                        disabled={(requestDetails.status_code === 3) ? false : true}
                        variant="contained" 
                        size="small" 
                        sx={{
                          color: theme.palette.primary.main,
                          bgcolor: '#7acda1',
                          '&:hover': {
                            bgcolor: '#ACE1C3',
                          },
                          marginBottom: '10px'
                        }}
                        onClick={() => setOpenAssetDialog(true)}
                      >Select Asset</Button> 
                  <CancelledRequest requestDetails={requestDetails}  setDoneApproving={setDoneApproving} />
              </>
              ) : ''}
          </Grid>
        </Item>
      </Grid>
      <Grid item xs={4}>
        <Item>
          <Typography>Documents</Typography>
            <Grid item>
              <Typography variant="subtitle2">TALAF/Contact/Email: </Typography>
              <a href={requestDetails.talaf_file_url} download>
              {requestDetails.talaf_file}
              </a>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">Asset Photo:</Typography>
              {requestDetails?.asset_images_url?.length > 0 ? (
                requestDetails.asset_images_url.map((image, index) => (
                  <div key={index}>
                    <a href={image.url} download>
                      {image.name}
                    </a>
                  </div>
                ))
              ) : (
                <Typography variant="body2">No asset photos available.</Typography>
              )}
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">TADR copy: </Typography>
              <a href={requestDetails.tadr_copy_url} download>
              {requestDetails.tadr_copy}
              </a>
            </Grid>
        </Item>
      </Grid>
      <Grid item xs={8} >
        <Item>
          <Typography>Upload image</Typography>
          <Grid item>
            <Paper
              sx={{
                  width: 200
              }}
              >
            </Paper>
          </Grid>
        </Item>
      </Grid>
    </Grid>
    <Dialog open={openAssetDialog} onClose={handleCloseAssetDialog}>
        <DialogTitle>Select Asset</DialogTitle>
        <DialogContent>
        <Grid container item xs={12} alignItems="center" spacing={2} >
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="initial">Asset Photo:</Typography>
            <ul>
              {requests?.asset_images?.length > 0 && requests.asset_images.map((file, index) => (
                <li key={index}>
                  {file.name}
                  <IconButton color='error' aria-label="remove" onClick={() => removeImage(index)}>
                    <Close />
                  </IconButton>
                </li>
              ))}
            </ul>
            <FormControl fullWidth>
              <Button component="label" variant="contained"  size="small"  color='primary' >
                Upload asset photo
                <VisuallyHiddenInput type="file" accept="image/*" multiple onChange={handleAssetImageChange}/>
              </Button>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="initial">TADR Copy:   {requests.tadr_copy && requests.tadr_copy.name}</Typography>
            <FormControl fullWidth>
              <Button
                component="label"
                variant="contained" 
                size="small" 
                color='primary'
              >Upload TADR Copy
              <VisuallyHiddenInput type="file" accept="application/pdf, image/*" onChange={handleTADRChange}/>
              </Button> 
            </FormControl>
          </Grid>
          <Grid item>
            <CustomDatatable 
                columnsData={columnsData}
                url={url}
                ref={tableRef}
            />
          </Grid>
        </Grid>
        </DialogContent>

        <DialogActions>
          <FormControl fullWidth>
            <Button variant="contained" size="small" color='info'  onClick={handleConfirmButton}>Confirm</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button onClick={handleCloseAssetDialog} color="error">Close</Button>
          </FormControl>
      </DialogActions>
    </Dialog>

    {/* <Dialog open={openSignatureDialog} onClose={handleCloseDialog}>
      <DialogTitle>Add signature</DialogTitle>
      <DialogContent>
      <ReactSignatureCanvas 
        penColor='black'
        canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} 
        ref={signatureRef}
      />
      </DialogContent>
      <DialogActions>
        <Button 
            onClick={handleSaveSignature} 
            variant='contained'
            sx={{
                  color: theme.palette.primary.main,
                  bgcolor: '#7acda1',
                  '&:hover': {
                    bgcolor: '#ACE1C3',
                  },
                }}  
            >Save & Approve</Button>
        <Button onClick={handleClearSignature} color="primary">clear</Button>
        <Button onClick={handleCloseAsset} color="error">cancel</Button>
      </DialogActions>
    </Dialog> */}

    <Dialog fullWidth={true} maxWidth={'md'} open={openDeployAssetDialog} onClose={handleCloseDeployAssetDialog}>
        <DialogTitle>Deploy Asset</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="initial">Date served:</Typography>
                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                            label="Delivered Date*"
                            value={requests.delivery_date}
                            onChange={(newValue) => setRequests({...requests, date_served: dayjs(newValue).format('YYYY-MM-DD')})}
                        />
                    </LocalizationProvider>
                </FormControl>
            </Grid>
          </Grid>
          
        </DialogContent>

        <DialogActions>
          <FormControl fullWidth>
            <Button variant="contained" size="small" color='success'  onClick={handleSaveProofOfDelivery}>Save</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button onClick={() => setOpenDeployAssetDialog(false)} color="error">Close</Button>
          </FormControl>
      </DialogActions>
    </Dialog>

    <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => {
            setOpenMessageDialog(false)
            if (doneApproving) {
              setOpenAssetDialog(false)
              setOpenSignatureDialog(false);
              setDoneApproving(false)
            }
            }}
          message={message}
        />
    </StyledEngineProvider>
    </>
  )
}

export default RequestDetails