import React, { useEffect, useRef, useState } from 'react'
import { AxiosCustomConfig } from '../../../config/AxiosCustomConfig';
import { Autocomplete, Box, Button, Grid, TextField } from '@mui/material';


import MFASettingsServerSideTable from './MFASettingsServerSideTable';
import CustomModal from '../../Utils/CustomModal';
import ProcessDialog from '../../Utils/ProcessDialog';
import MessageDialog from '../../Utils/MessageDialog';

export default function MFASettings() {
    const axios = AxiosCustomConfig();
    const dataTableRef = useRef(null);

    const [newMFAEmail, setNewMFAEmail] = useState({
        system_username: null,
        email_username: ''
    });

    const [tfaUsers, setTFAUsers] = useState([]);

    const [showProgress, setShowProgress] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [openCustomModal, setOpenCustomModal] = useState(false);
    
    const handleModalClose = () => setOpenCustomModal(false);
    const handleCloseDialog = () => setIsDialogOpen(false);

    const handleNewMFAChange = (e, newValue) => {
        if (newValue) {
            setNewMFAEmail({
                ...newMFAEmail,
                system_username: newValue,
            })
        } else {
            const { name, value } = e.target;
            setNewMFAEmail((setPrevEmail) => ({...setPrevEmail, [name]: value}))
        }
    }

    const handleAddMFAEmail= () => {
        if (newMFAEmail.system_username === null) {
            setIsDialogOpen(true);
            setModalMessage("Username is required");
            return false;
        }

        if (newMFAEmail.email_username === "") {
            setIsDialogOpen(true);
            setModalMessage("User Email Address is required");
            return false;
        }

        const newPost = {
            system_username: newMFAEmail.system_username.value,
            email_username: newMFAEmail.email_username
        };

        setShowProgress(true);

        axios({
            url: 'ApiSettings/addMFAEmail.json',
            method: 'POST',
            data: newPost
        }).then((res) => {
            const message = res.data.message;
            const success = res.data.success;

            setShowProgress(false);

            if (res.status === 200 && success) {
                dataTableRef.current.getMFASettingsDatatable();
                setOpenCustomModal(false);
                setNewMFAEmail({
                    system_username: '',
                    email_username: ''
                })
            }

            setIsDialogOpen(true);
            setModalMessage(message);
            getTFAUsers();
        }).catch(error => {
            console.log(error.response.data);
        });
    };

    const getTFAUsers = () => {
        axios({
            url: 'ApiSettings/getMFAUsers.json',
            method: 'GET',
        }).then((res) => {
            setTFAUsers(res.data);
        }).catch(error => {
            console.log(error.response.data);
        });
    }

    const formElements = [
/*         <TextField 
            name="system_username" 
            label="Username" 
            type="text"
            fullWidth 
            required
            value={newMFAEmail.system_username}
            onChange={handleNewMFAChange}
        />, */
        <Autocomplete
            fullWidth
            value={newMFAEmail.system_username}
            onChange={handleNewMFAChange}
            options={tfaUsers}
            getOptionLabel={(option) => option.label || ''}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            renderInput={(params) => <TextField {...params} label="Username" required />}
        />,
        <TextField 
            name="email_username" 
            label="User Email Address" 
            type="text" 
            fullWidth
            required
            value={newMFAEmail.email_username}
            onChange={(e) => handleNewMFAChange(e, null)}
        />
    ];

    const handleOpenNewMFA = () => {
        setOpenCustomModal(true);
        getTFAUsers();
    }

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={handleOpenNewMFA}>
                        Add
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    {/* <MFASettingsDatatable /> */}
                    <MFASettingsServerSideTable ref={dataTableRef} />
                </Grid>
            </Grid>
            <CustomModal
                open={openCustomModal}
                handleClose={handleModalClose}
                title="Add MFA Email Address"
                isForm={true}
                formElements={formElements}
                onSubmit={handleAddMFAEmail}
            />
            <ProcessDialog
                showProgress={showProgress}
                ContentText="Loading...."
            />
            <MessageDialog
                open={isDialogOpen}
                message={modalMessage}
                handleClose={handleCloseDialog}
            />
        </Box>
    )
}
