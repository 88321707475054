import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { isValidSpecialCharacter, objectToFormData, validateAlphaNumeric } from '../GenericFunctions/GenericFunctions';


function EditWarehouse({warehouseData, setOnEditOrDelete}) {
  const theme = useTheme();
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState({
    code: '',
    name: '',
    location: '',
    unique_code: '',
  });
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
    const { code, name, location, unique_code} = warehouseData;
    setEditData((prevData) => ({ 
      ...prevData, 
      code: code || prevData.code, 
      name: name || prevData.name, 
      location: location || prevData.location, 
      unique_code: unique_code || prevData.unique_code, 
    }))
  };

  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const resetForm = () => {
    setEditData({
      name: '',
      description: '',
      id: ''
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!editData.name || !editData.code) {
        setOpenMessageDialog(true);
        setMessage('Warehouse Name and Warehouse Code are required')
        return
    }

    if (!validateAlphaNumeric(editData.code)) {
        setOpenMessageDialog(true);
        setMessage('Please use AplhaNumeric with Hypen on Warehouse Code.')
        return
    }
    
    if (!isValidSpecialCharacter(editData.name)) {
        setOpenMessageDialog(true);
        setMessage('Invalid Special Characters found in Warehouse Name.')
        return
    }

    if (!isValidSpecialCharacter(editData.location)) {
        setOpenMessageDialog(true);
        setMessage('Invalid Special Characters found in Location.')
        return
    }


    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/Warehouses/updateWarehouse.json',
              data: objectToFormData(editData),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          setOpenMessageDialog(true);
          setMessage(response.data.message)
          resetForm();
          setOpen(false)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage('Failed to Save.')
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}.`);
    }
  }

  return (
    <div>
      <Button 
        variant="outlined" 
        size="small" 
        sx={{color: theme.palette.secondary.dark, marginRight: '10px'}} 
        onClick={(e) => {handleOpen(e)}}>
      Edit
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
        maxWidth={'md'}
        fullWidth={true}
      >
        <DialogTitle>Edit Warehouse</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                  label="Code*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, code: e.target.value }))}
                  value={editData.code}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  label="Name*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, name: e.target.value }))}
                  value={editData.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="location"
                  label="Location"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, location: e.target.value }))}
                  value={editData.location}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => {setOpenMessageDialog(false)
                setOnEditOrDelete((prev) => !prev)}}
            message={message}
          />
      </StyledEngineProvider>
    </div>
  )
}

export default EditWarehouse