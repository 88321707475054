import React, { createContext, useState } from 'react'

const JobOrdersContext = createContext({});

export const JobOrdersProvider = ({children}) => {
  const [doneUpdate, setDoneUpdate] = useState(false);

  return (
      <JobOrdersContext.Provider value={{ doneUpdate, setDoneUpdate }}>
          {children}
      </JobOrdersContext.Provider>
  )
}

export default JobOrdersContext