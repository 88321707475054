import { Box, Button, CircularProgress, IconButton, InputAdornment, InputBase, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography, tableCellClasses } from '@mui/material'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import Paper from '@mui/material/Paper';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import styled from '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';

  const StyledTable = styled(TableContainer)(
    ({ theme }) => `
    th {
        font-weight: bold;
        background-color: #fafafa;
        color: rgba(0, 0, 0, 0.88);        
        font-size: 12px;
        font-weight: 600;
        line-height: 1;
        letter-spacing: 0.5px;
        text-transform: uppercase;
    }
  
    td,th
    {
        border: 1px solid #f4f4f4;
        padding: 12px;
    }
    `,
  );

export default forwardRef(function CustomDatatable(props, ref) {
    const axios = AxiosCustomConfig();

    // Datatable State
    const [loading, setLoading] = useState(false);
    const [rowsData, setRowsData] = useState([]);
    const [rowsTotalCount, setRowsTotalCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState({ field: 'id', order: 'asc' });
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        // cleanup
        let isFetchingData = false;
        
        fetchData(isFetchingData);
    
        return () => {
          isFetchingData = true;
        };
    },[page, rowsPerPage, sort.field, sort.order, searchValue]);

    // visible all the need methods for parent component
    useImperativeHandle(ref, () => {
        return {
            fetchData: fetchData
        };
    }, []);

    const fetchData = (isFetchingData) => {
        setLoading(true);
    
        axios({
          url: props.url,
          method: 'POST',
          data: {
            page: ((page + 1) - 1) * rowsPerPage,
            limit: rowsPerPage,
            search: searchValue,
            sort: {
              field: sort.field,
              order: sort.order
            }
          },
        }).then((res) => {
          if (!isFetchingData) {
            setLoading(false);
            setRowsData(res.data.data);
            setRowsTotalCount(res.data.recordsTotal);
          }
        }).catch((error) => {
            if (error && error.response) {
                if (error.response.status === 400 || error.response.status === 500) {
                    alert(error.response.data.message);
                }
            }
        })
    }

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleSortChange = (field) => {
        const newOrder = sort.field === field && sort.order === 'asc' ? 'desc' : 'asc';
        setSort({ field, order: newOrder });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    

    return (
        <Paper sx={{ overflow: "auto"}}>
            <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                <Box 
                    sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        my: 1, 
                        p: 1,
                        justifyContent: { xs: 'center', lg: 'flex-end' },
                    }}>
                    <Paper
                        component="form"
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: { md: '100%', xl: 'auto' } }}
                        >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'Search' }}
                            value={searchValue}
                            onChange={handleSearchChange}
                        />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </Box>
                <StyledTable>
                    <Table>
                        <TableHead>
                            <TableRow>
                            {props.columnsData.map((column, index) => (
                                <TableCell
                                    key={column.id}
                                    sx={{width: (column.width) ? column.width : 'auto'}}
                                    >
                                    {
                                        column.sorting === false ? (
                                            column.headerName
                                        ) : (
                                            <TableSortLabel
                                                active={sort.field === column.id}
                                                direction={sort.field === column.id ? sort.order : 'asc'}
                                                onClick={() => handleSortChange(column.id)}
                                            >
                                                {column.headerName}
                                            </TableSortLabel>
                                        )
                                    }
                                </TableCell>
                            ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell 
                                    colSpan={props.columnsData.length + 1} 
                                    style={{ textAlign: 'center' }}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>) : (
                            rowsData.length > 0 ? ( 
                                rowsData.map((row, index) => (
                                    <TableRow 
                                        key={index}
                                    >
                                        {props.columnsData.map((column) => (
                                            <TableCell
                                                align={column.align ? column.align : 'left'}
                                                key={column.id}>
                                                {column.render ? (
                                                    column.render(row)
                                                ) : (
                                                    row[column.id]
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={props.columnsData.length + 1} style={{ textAlign: 'center' }}>
                                        No data available
                                    </TableCell>
                                </TableRow>
                            )
                        )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={props.columnsData.length + 1}
                                    count={rowsTotalCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </StyledTable>
            </Box>
        </Paper>
    )
});
