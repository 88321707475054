import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField } from '@mui/material';
import React, { useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';

function AddAssetModels({setOnEditOrDelete}) {
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [assetModels, setAssetModels] = useState({
    model: '',
    asset_type_size: '',
    asset_brand: '',
    external_dimensions: '',
    internal_dimensions: '',
    capacity_gal: '',
    gross_capacity: '',
    net_capacity: '',
    capacity_in_cubic_ft: '',
    asset_area: '',
  });

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!assetModels.model) {
        setOpenMessageDialog(true);
        setMessage('Asset Model Name is required')
        return
    }

    if (!isValidSpecialCharacter(assetModels.model)) {
        setOpenMessageDialog(true);
        setMessage('Invalid Special Characters found in Model Name.')
        return
    }

    if (!isValidSpecialCharacter(assetModels.description) && assetModels.description !== '') {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in Description.')
      return
    }

    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/AssetModels/addAssetModels.json',
              data: objectToFormData(assetModels),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          resetForm();
          setOnEditOrDelete((prev) => !prev)
          setOpen(false)
          setOpenMessageDialog(true);
          setMessage(response.data.message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Please try again and make sure you have internet connection.`);
    }
  }


  const resetForm = () => {
    setAssetModels({
      name: '',
      description: '',
    });
  };


  return (
    <div>
    <Button variant="contained" size="small" onClick={handleOpen}>Create Asset Model</Button>
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Add New Asset Model</DialogTitle>
      <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                  name="account_name"
                  label="Model Name*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAssetModels((prevAccount) => ({ ...prevAccount, model: e.target.value }))}
                  value={assetModels.model}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="asset_type_size"
                  label="Asset type size"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAssetModels((prevAccount) => ({ ...prevAccount, asset_type_size: e.target.value }))}
                  value={assetModels.asset_type_size}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="asset_brand"
                  label="Asset brand"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAssetModels((prevAccount) => ({ ...prevAccount, asset_brand: e.target.value }))}
                  value={assetModels.asset_brand}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="external_dimensions"
                  label="External dimensions"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAssetModels((prevAccount) => ({ ...prevAccount, external_dimensions: e.target.value }))}
                  value={assetModels.external_dimensions}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="internal_dimensions"
                  label="Internal dimensions"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAssetModels((prevAccount) => ({ ...prevAccount, internal_dimensions: e.target.value }))}
                  value={assetModels.internal_dimensions}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="Capacity (gal)"
                  label="Capacity (gal)"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAssetModels((prevAccount) => ({ ...prevAccount, capacity_gal: e.target.value }))}
                  value={assetModels.capacity_gal}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="gross_capacity"
                  label="Gross capacity"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                      const input = e.target.value;
                      // Regular expression to allow numbers with optional decimal point
                      if (/^\d*\.?\d*$/.test(input)) {
                          const numericValue = parseFloat(input);
                          // Check if the numeric value is within the range [0, 1000000]
                          if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 1000000) {
                            setAssetModels((prevAccount) => ({ ...prevAccount, gross_capacity: input }));
                          }
                      }
                  }}
                  value={assetModels.gross_capacity}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="net_capacity"
                  label="Net capacity"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                      const input = e.target.value;
                      // Regular expression to allow numbers with optional decimal point
                      if (/^\d*\.?\d*$/.test(input)) {
                          const numericValue = parseFloat(input);
                          // Check if the numeric value is within the range [0, 1000000]
                          if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 1000000) {
                            setAssetModels((prevAccount) => ({ ...prevAccount, net_capacity: input }));
                          }
                      }
                  }}
                  value={assetModels.net_capacity}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="capacity_in_cubic_ft"
                  label="Capacity in cubic ft"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                      const input = e.target.value;
                      // Regular expression to allow numbers with optional decimal point
                      if (/^\d*\.?\d*$/.test(input)) {
                          const numericValue = parseFloat(input);
                          // Check if the numeric value is within the range [0, 1000000]
                          if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 1000000) {
                            setAssetModels((prevAccount) => ({ ...prevAccount, capacity_in_cubic_ft: input }));
                          }
                      }
                  }}
                  value={assetModels.capacity_in_cubic_ft}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="asset_area"
                  label="Asset area"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                      const input = e.target.value;
                      // Regular expression to allow numbers with optional decimal point
                      if (/^\d*\.?\d*$/.test(input)) {
                          const numericValue = parseFloat(input);
                          // Check if the numeric value is within the range [0, 1000000]
                          if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 1000000) {
                            setAssetModels((prevAccount) => ({ ...prevAccount, asset_area: input }));
                          }
                      }
                  }}
                  value={assetModels.asset_area}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default AddAssetModels