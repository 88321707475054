import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import React from 'react'

function MessageDialog({open, message, handleClose}){
    return(
      <>
       <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={()=>{}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
       
        <DialogContent>
         
        <Typography variant="h6" component="h2" style={{color:"red"}}>
          { "Message" }
        </Typography>
          {
            message        
          }
        </DialogContent>
        <DialogActions>
          <Button 
          onClick={handleClose} 
          color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      </>
    )
  }

export default MessageDialog