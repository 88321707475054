import React, { useState } from 'react';
import { Button, ListItemIcon, Menu, MenuItem, StyledEngineProvider, LinearProgress, Box } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { ExpandMore, FileDownload } from '@mui/icons-material';
import MessageDialog from './MessageDialog';
import CustomExportSettingsModal from './CustomExportSettingsModal';

function CustomToolbar({ selectedRows, columns, setOnSaveColumns, functionName, modelTable, handleClickAllData, selectAll }) {
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [message, setMessage] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [progress, setProgress] = useState(0); // Add progress state
  const [isDownloading, setIsDownloading] = useState(false); // Add downloading state


  const handleOpenSettingsModal = () => {
    if (selectedRows.length === 0) {
      setOpenMessageDialog(true);
      setMessage('No rows selected');
      return;
    }
    setIsSettingsModalOpen(true);
    handleMenuClose();
  };

  const handleCloseSettingsModal = () => {
    setIsSettingsModalOpen(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleHoverLeave = () => {
    setTimeout(() => {
      if (!anchorEl) {
        handleMenuClose();
      }
    }, 200);
  };

  const handleDownloadStart = () => {
    setIsDownloading(true);
    setProgress(0);
  };

  const handleDownloadProgress = (value) => {
    setProgress(value);
  };

  const handleDownloadEnd = () => {
    setIsDownloading(false);
    setProgress(100);
  };

  return (
    <>
      <GridToolbarContainer>
        <Button 
          size="small"
          variant="outlined"
          onClick={handleClickAllData}
          color={selectAll ? 'error' : 'info'}
        >
          {selectAll ? 'Deselect All Assets' : 'Select All Assets'}
        </Button>
        <Button
          variant="outlined"
          size="small"
          onMouseEnter={handleMenuOpen}
          onMouseLeave={handleHoverLeave}
          sx={{ cursor: 'pointer' }}
        >
          with Selected <ExpandMore />
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem fontSize="small" onClick={handleOpenSettingsModal}>
            <ListItemIcon>
              <FileDownload fontSize="small" />
            </ListItemIcon>
            Export CSV
          </MenuItem>
        </Menu>
      </GridToolbarContainer>

      {isDownloading && (
        <Box sx={{ width: '100%', marginTop: 2 }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      )}

      <CustomExportSettingsModal
        open={isSettingsModalOpen}
        onClose={handleCloseSettingsModal}
        columns={columns}
        setOnSaveColumns={setOnSaveColumns}
        functionName={functionName}
        selectedRows={selectedRows}
        modelTable={modelTable}
        onDownloadStart={handleDownloadStart}
        onDownloadProgress={handleDownloadProgress}
        onDownloadEnd={handleDownloadEnd}
      />

      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose={() => setOpenMessageDialog(false)}
          message={message}
        />
      </StyledEngineProvider>
    </>
  );
}

export default CustomToolbar;
