import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, StyledEngineProvider, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';

const DeleteUsers = ({usersData, setOnEditOrDelete}) => {
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [deleteData, setDeleteData] = useState({
    username: '',
    id: '',
  });
  const [showProgress,setShowProgress] = useState(false)
  const [openMessageDialog, setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  
  const objectToFormData = (object) => {
    const formData = new FormData();
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        formData.append(key, object[key]);
      }
    }

    return formData;
  };

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true)
    const {id, username} = usersData;
    setDeleteData({username, id});
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleDelete = async (e) => {
    e.stopPropagation();
    
    setShowProgress(true)
    try {
      const response = await axios({
        method: 'POST',
        url: '/ApiUsers/deleteUser.json',
        data: objectToFormData(deleteData),
      })

      if (response.data.result.is_success) {
        setShowProgress(false)
        setOpenMessageDialog(true)
        setMessage('Successfuly Deleted the User.')
      } else {
        setShowProgress(false)
        setOpenMessageDialog(true)
        setMessage('Failed to Delete the User.')
      }
    } catch (error) {
      console.log('Error log: ', error);
    }
  }


  return (
    <>
      <Button 
        variant="outlined" 
        size="small" 
        color="error" 
        onClick={(e) => {handleOpen(e)}}>
      Delete
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
      >
      <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" align='center'>
            Are you sure you want to delete this user?  {usersData.username}
          </DialogContentText>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, paddingTop: 3}}>
            <FormControl fullWidth>
              <Button variant="outlined" size="small" onClick={handleClose}>Cancel</Button>
            </FormControl>
            <FormControl fullWidth>
              <Button variant="contained" size="small" color='error' autoFocus onClick={handleDelete}>Delete</Button>
            </FormControl>
        </DialogActions>
        </DialogContent>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => {
              setOpenMessageDialog(false)
              setOpen(false)
              setOnEditOrDelete((prev) => !prev)
              }}
            message={message}
          />
      </StyledEngineProvider>
    </>
  )
}

export default DeleteUsers