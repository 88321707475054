import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField } from '@mui/material';
import React, {useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { objectToFormData } from '../GenericFunctions/GenericFunctions';
import useViewAssetContext from '../../hooks/UseViewAssetContext';
import useCommentsContext from '../../hooks/UseCommentsContext';

function UpdateAssetStatus({open, setOpen, assetsData}) {
  const {setDoneUpdating} = useViewAssetContext();
  const {setDoneUpdate} = useCommentsContext();
  const axios = AxiosCustomConfig();
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);

  const [warehouseData, setWarehouseData] = useState({
    asset_code: '',
    latest_update_array: [],
    latest_asset_update: '',
    remarks: '',
  });

  const latestAssetUpdate = [
    {'label':'-', 'value':''},
    {'label':'Please Select', 'value':'Please Select'},
    {'label':'Continue selling', 'value':'Continue selling'},
    {'label':'with system pullout request', 'value':'with system pullout request'},
    {'label':'with BO/refund issues', 'value':'with BO/refund issues'},
    {'label':'Closed, store/asset cannot be located', 'value':'Closed, store/asset cannot be located'},
    {'label':'For audit/verification', 'value':'For audit/verification'},
    {'label':'Other issues', 'value':'Other issues'},
  ]

  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    setShowProgress(true);
    try {
        const options = {
            method: 'POST',
              url: '/AssetsRegistry/latestAssetUpdate.json',
              data: objectToFormData(warehouseData),
        }

        const response = await axios(options);
        const {is_success, message} = response.data;

        setShowProgress(false);

        if (is_success) {
          setDoneUpdating((prev) => !prev)
          setDoneUpdate((prev) => !prev)
          setOpenMessageDialog(true);
          setMessage(message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. There's something wrong with the server.`);
    }
  }

  const resetForm = () => {
    setWarehouseData({
      asset_code: '',
      latest_update_array: [],
      latest_asset_update: '',
      remarks: '',
    });
  };

  useEffect(() => {
    if (open && assetsData) {
      const { equipment_no, remarks, latest_asset_update} = assetsData;
      setWarehouseData({
        asset_code: equipment_no,
        remarks: remarks,
        latest_asset_update: latest_asset_update,
        latest_update_array: {'label' : latest_asset_update, value: latest_asset_update},
      });
    }

  }, [open, assetsData]);

  const handleLatestAssetUpdateChange = (e, selectValue) => {
    if (selectValue) {
      let latest_asset_update = selectValue.label;
      setWarehouseData((prevAccount) => ({
        ...prevAccount, 
        latest_asset_update: latest_asset_update, 
        latest_update_array: selectValue
      }))
    }
  }


  return (
    <div>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Update asset status</DialogTitle>
      <DialogContent>
          <Grid container spacing={3} mt={1}>
            <Grid item xs={12}>
              <FormControl fullWidth>
              <Autocomplete
                  id="combo-box-demo"
                  onChange={handleLatestAssetUpdateChange}
                  options={latestAssetUpdate}
                  value={warehouseData.latest_update_array}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionDisabled={(option) => option.label === '-' || option.label === 'Please Select'}
                  isOptionEqualToValue={(option, value) => (option && value && option.label === value.label)}
                  renderInput={(params) => <TextField {...params} label="Latest asset update" />}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="remarks"
                  label="Remarks"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setWarehouseData((prevAccount) => ({ ...prevAccount, remarks: e.target.value }))}
                  value={warehouseData.remarks}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => {
              setOpenMessageDialog(false)
              setOpen(false)
              }}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default UpdateAssetStatus