import React, { createContext, useState } from 'react'

const CommentsContext = createContext({});

export const CommentsProvider = ({children}) => {
  const [doneUpdate, setDoneUpdate] = useState(false);

  return (
      <CommentsContext.Provider value={{ doneUpdate, setDoneUpdate }}>
          {children}
      </CommentsContext.Provider>
  )
}

export default CommentsContext