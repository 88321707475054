import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'

const CsvStatusDialog = ({modalTitle, showCsvStatusDialog, csvUploadPercent, uploadStatusArray, handleClose}) => {
  return (
    <Dialog fullWidth={true} maxWidth={'md'} open={showCsvStatusDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle>{modalTitle}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress sx={{height: 10}} color="success" variant="determinate" value={csvUploadPercent} />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">{`${Math.round(
              csvUploadPercent,
            )}%`}</Typography>
          </Box>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="caption table" id="status_tbl">
            <TableHead>
              <TableRow>
                <TableCell align="left"><b>Line</b></TableCell>
                <TableCell><b>Message</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {uploadStatusArray.map((value, index) => (
                <TableRow key={index}>
                  <TableCell align="left" component="th" scope="row"><b>{value.line}</b></TableCell>
                  <TableCell>{value.message}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default CsvStatusDialog