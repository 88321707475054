import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, StyledEngineProvider, Typography } from '@mui/material';
import React, { useState } from 'react'
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { objectToFormData } from '../GenericFunctions/GenericFunctions';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { HighlightOff } from '@mui/icons-material';
import styled from '@emotion/styled';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);
function UproductiveDelivery({requestDetails, setDoneApproving}) {
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [changeStatusData, setChangeStatusData] = useState({id: '', code: '', reject_remarks: '', status: 'unproductive'});
  const [showProgress,setShowProgress] = useState(false)
  const [openMessageDialog, setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true)
    const {id, request_code} = requestDetails;
    setChangeStatusData((prevAccount) => ({ ...prevAccount, id: id, request_code: request_code }));
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleReject = async (e) => {
    e.stopPropagation();

    setShowProgress(true)
    try {
      const options = {
        method: 'POST',
        url: '/DeploymentRequests/changeForOtherRequestStatus.json',
        data: objectToFormData(changeStatusData),
      }

      const response = await axios(options);

      setShowProgress(false)

      if (response.data.is_success) {
        setOpenMessageDialog(true);
        setMessage(response.data.message)
        setOpen(false)
      } else {
        setShowProgress(false)
        setOpenMessageDialog(true)
        setMessage(response.data.message)
      }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true)
      setMessage(` ${error.toJSON().message}. Please try again and make sure you have internet connection.`);
    }
  }

  return (
    <>
      <Button 
        disabled={requestDetails.status_code !== 1 ? true : false} 
        variant='contained' 
        size="small" 
        color='secondary' 
        sx={{marginBottom: '10px'}}
        onClick={handleOpen}
        >
        Uproductive Delivery
      </Button> 
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
      >
      <DialogTitle>Uproductive Delivery</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" align='center'>
            Are you sure you want to set this request to unproductive delivery? <p> <strong> {requestDetails.request_code}</strong>  </p>
          </DialogContentText>

          <Typography>Remarks:</Typography>
          <Textarea 
            aria-label="minimum height" 
            minRows={3} 
            placeholder="Input Remarks" 
            onChange={(e) => setChangeStatusData((prevAccount) => ({ ...prevAccount, reject_remarks: e.target.value }))}
          />
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, paddingTop: 3}}>
            <FormControl fullWidth>
              <Button variant="outlined" size="small" onClick={handleClose}>close</Button>
            </FormControl>
            <FormControl fullWidth>
              <Button variant="contained" size="small" color='error' autoFocus onClick={handleReject}>Confirm</Button>
            </FormControl>
        </DialogActions>
        </DialogContent>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => {
            setOpenMessageDialog(false)
            setDoneApproving((prev) => !prev)
            } }
          message={message}
        />
      </StyledEngineProvider>
    </>
  )
}

export default UproductiveDelivery