import { Box, Typography } from "@mui/material";
import { GridFooterContainer } from "@mui/x-data-grid";
import CustomPagination from "./CustomPagination";

const CustomGlobalFooter = ({ selectedRows}) => {

  return (
    <GridFooterContainer>
      {/* Your custom footer content */}
      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'space-between', padding: '8px', backgroundColor: '#f5f5f5' }}>
        {selectedRows > 0 ? (
          <Typography variant="subtitle1">
            {selectedRows} {selectedRows === 1 ? 'row' : 'rows'} selected
          </Typography>
        ) : (
          <Typography variant="subtitle1">
          </Typography>
        )}

      </Box>
      
      {/* Default pagination footer */}
      <CustomPagination /> 
      {/* <GridPagination/> */}
    </GridFooterContainer>
  );
};

export default CustomGlobalFooter;