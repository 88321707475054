import { useTheme } from '@emotion/react';
import { CheckCircleOutline } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, StyledEngineProvider } from '@mui/material'
import React, { useRef } from 'react'
import { useState } from 'react'
import ReactSignatureCanvas from 'react-signature-canvas';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import useStoreToStoreContext from '../../hooks/UseStoreToStoreContext';



function ApproveStoreTransfer({requestDetails, setDoneUpdateRequest}) {
  const theme = useTheme();
  const axios = AxiosCustomConfig();
  const {setDoneUpdate} = useStoreToStoreContext();
  const [openSignatureDialog, setOpenSignatureDialog] = useState(false);
  const signatureRef = useRef();
  const [requests, setRequests] = useState({
    id: '',
    request_code: '',
    signature_url: '',
  });
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showProgress, setShowProgress] = useState(false);

  const handleOpen = () => {
    setOpenSignatureDialog(true)
    const { request_code, id, asset_code} = requestDetails;
    setRequests((prevData) => ({ 
      ...prevData, 
      request_code: request_code, 
      id: id,
      asset_code: asset_code,
    }))

  }
  const handleClearSignature = () => {
    signatureRef.current.clear();
  };

  const handleSaveSignature = () => {
    const dataURL = signatureRef.current.toDataURL('image/png');
    if (dataURL) {
      setRequests(prevData => {
        const updatedData = { ...prevData, signature_url: dataURL };
        processAndSendRequest(updatedData);
        return updatedData;
      });
    }
    
  };

  const processAndSendRequest = async (requestData) => { 
    try{
      setLoading(true);
      setShowProgress(true);
      const options = {
          'method': 'POST',
          'url' : '/StoreToStoreTransfers/approveTransferRequest.json',
          'data': requestData
        }
        const response = await axios(options);
        const data = response.data;
        if (data.is_success) {
          setDoneUpdateRequest(prev => !prev)
          setDoneUpdate(prev => !prev)
          setOpenSignatureDialog(false)
          setShowProgress(false);
          setOpenMessageDialog(true);
          setMessage(data.message);
        } else {
          setShowProgress(false);
          setOpenMessageDialog(true);
          setMessage(data.message);
        }
        
    } catch (error) {
        setShowProgress(false);
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Please try again and make sure you have internet connection.`);
    } finally {
        setLoading(false);
        setShowProgress(false);
    }
  };

  return (
    <>
      <Button 
        disabled={requestDetails.status === 0 ? false : true} 
        variant='contained' 
        size="small" 
        color='primary' 
        startIcon={<CheckCircleOutline />} 
        onClick={handleOpen}>
        Approve transfer
      </Button>

      <Dialog open={openSignatureDialog} onClose={() => setOpenSignatureDialog(false)}>
      <DialogTitle>Add signature</DialogTitle>
      <DialogContent>
      <ReactSignatureCanvas 
        penColor='black'
        canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} 
        ref={signatureRef}
      />
      </DialogContent>
      <DialogActions>
        <Button 
            onClick={handleSaveSignature} 
            variant='contained'
            sx={{
                  color: theme.palette.primary.main,
                  bgcolor: '#7acda1',
                  '&:hover': {
                    bgcolor: '#ACE1C3',
                  },
                }}  
            >Save & Approve</Button>
        <Button onClick={handleClearSignature} color="primary">clear</Button>
        <Button onClick={() => setOpenSignatureDialog(false)} color="error">cancel</Button>
      </DialogActions>
    </Dialog>

    <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => {
            setOpenMessageDialog(false)
          }}
          message={message}
        />
      </StyledEngineProvider>
    </>
  )
}

export default ApproveStoreTransfer