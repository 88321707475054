import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData, validateAlphaNumeric } from '../GenericFunctions/GenericFunctions';
import styled from '@emotion/styled';
import { CloudUpload } from '@mui/icons-material';

function AddAssets({setOnEditOrDelete}) {
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [asset, setAsset] = useState({
    code: '',
    name: '',
    description: '',
    asset_type_code: '',
    warehouse_unique_code: '',
  });

  const [assetTypes, setAssetTypes] = useState([]);
  const [openAssetTypes, setOpenAssetTypes] = useState(false);
  const [loadingAssetType, setLoadingAssetType] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [openWarehouses, setOpenWarehouses] = useState(false);
  const [loadingWarehouses, setLoadingWarehouses] = useState(false);

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!asset.name || !asset.code) {
        setOpenMessageDialog(true);
        setMessage('Asset Name and Asset Code are required')
        return
    }

    if (!validateAlphaNumeric(asset.code)) {
        setOpenMessageDialog(true);
        setMessage('Please use AplhaNumeric with Hypen on Asset Code.')
        return
    }

    if (!isValidSpecialCharacter(asset.name)) {
        setOpenMessageDialog(true);
        setMessage('Invalid Special Characters found in Account Name.')
        return
    }

    if (!isValidSpecialCharacter(asset.description)) {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in Description.')
      return
    }

    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/AssetsRegistry/addAsset.json',
              data: objectToFormData(asset),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          resetForm();
          setOnEditOrDelete((prev) => !prev)
          setOpen(false)
          setOpenMessageDialog(true);
          setMessage(response.data.message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Please try again and make sure you have internet connection.`);
    }
  }

  const getAssetTypes = async (ignore) => {
    try {
        setLoadingAssetType(true)
        const options = {
            method: 'POST',
            url: '/AssetTypes/getAssetTypeNameAndCode.json',
        }

        const response = await axios(options);
        if (!ignore) {
          setAssetTypes(response.data);
          setLoadingAssetType(false)
        }
    } catch (error) {
      console.log('Error info: ', error);
    }
  }
  const getWarehouses = async (ignore) => {
    try {
        setLoadingWarehouses(true)
        const options = {
            method: 'POST',
            url: '/Warehouses/getWarehousesForAutocomplete.json',
        }

        const response = await axios(options);
        if(!ignore){
          setWarehouses(response.data);
          setLoadingWarehouses(false)
        }

    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const resetForm = () => {
    setAsset({
      code: '',
      name: '',
      description: '',
    });
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setAsset((prev) => ({...prev, image: file}));
  };

  const handleAssetTypeChange = (e, selectValue) => {
      if (selectValue) {
        setAsset((prevAccount) => ({ ...prevAccount, asset_type_code: selectValue.asset_type_code}))
      }
  }
  const handleWarehouseChange = (e, selectValue) => {
      if (selectValue) {
        setAsset((prevAccount) => ({ ...prevAccount, warehouse_unique_code: selectValue.warehouse_unique_code}))
      }
  }

  useEffect(()=>{
    let ignore = false;

    if (!openAssetTypes) return undefined
    getAssetTypes(ignore);

    return () => {
      ignore = true;
    }
  }, [openAssetTypes]);

  useEffect(()=>{
    let ignore = false;

    if (!openWarehouses) return undefined
    getWarehouses(ignore);

    return () => {
      ignore = true;
    }
  }, [openWarehouses]);


  return (
    <div>
    <Button variant="contained" size="small" onClick={handleOpen}>Create Asset Registry</Button>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Add New Asset</DialogTitle>
      <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                  name="code"
                  label="Code*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAsset((prevAccount) => ({ ...prevAccount, code: e.target.value }))}
                  value={asset.code}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="name"
                  label="Name*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAsset((prevAccount) => ({ ...prevAccount, name: e.target.value }))}
                  value={asset.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="description"
                  label="Description"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAsset((prevAccount) => ({ ...prevAccount, description: e.target.value }))}
                  value={asset.description}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  open={openAssetTypes}
                  onOpen={() => {setOpenAssetTypes(true);}}
                  onClose={() => {setOpenAssetTypes(false);}}
                  loading={loadingAssetType}
                  onChange={handleAssetTypeChange}
                  options={assetTypes}
                  value={assetTypes.asset_type_code}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.label === value.value}
                  renderInput={(params) => <TextField {...params} label="Asset Type" />}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} mb={2}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  open={openWarehouses}
                  onOpen={() => {setOpenWarehouses(true);}}
                  onClose={() => {setOpenWarehouses(false);}}
                  loading={loadingWarehouses}
                  onChange={handleWarehouseChange}
                  options={warehouses}
                  value={warehouses.label}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => <TextField {...params} label="Warehouse" />}
                />
              </FormControl>
            </Grid>
            <Grid container item xs={12} alignItems="center" spacing={2} >
              <Grid item>
                <Button component="label" variant="contained" startIcon={<CloudUpload />}>
                  Upload Image
                  <VisuallyHiddenInput type="file" accept="image/*" onChange={handleImageChange}/>
                </Button>
              </Grid>
              <Grid item>
                {asset.image && (
                  <p>{asset.image.name}</p>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default AddAssets