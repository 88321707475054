import { Box, Dialog, DialogContent, DialogContentText, LinearProgress } from '@mui/material'
import React from 'react'

function ProcessDialog({showProgress, ContentText}){
    return(
      <>
      <Dialog fullWidth={true} maxWidth={"sm"} open={showProgress}>
        <DialogContent>
          <DialogContentText>{ContentText}</DialogContentText>

          <Box sx={{ width: "100%" }}>
            <LinearProgress variant="query"/>
          </Box>
        </DialogContent>
      </Dialog>
      </>
    )
  }

export default ProcessDialog