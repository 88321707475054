import { Box, StyledEngineProvider, Typography } from '@mui/material'
import { DataGrid, gridClasses } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import MessageDialog from '../Utils/MessageDialog';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import CustomToolbar from '../Utils/CustomToolbar';
import useFetchColumns from '../../hooks/UseFetchColumns';
import useFilteredAndOrderedColumns from '../../hooks/UseFilteredAndOrderedColumns';
import ColumnSettingsButton from '../Utils/ColumnSettingsButton';
import useFetchPageLength from '../../hooks/UseFetchPageLength';
import CustomGlobalFooter from '../Utils/CustomGlobalFooter';
import useSortModel from '../../hooks/UseSortModel';

const StoreAssets = () => {
  const axios = AxiosCustomConfig();
  const [storeAssetsData, setStoreAssetsData] = useState([]);
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [onSaveColumns, setOnSaveColumns] = useState(false);
  const functionName = 'store_assets';
  const modelTable = 'StoreAssets';

  const { paginationModel, setPaginationModel, pageSizeOptions } = useFetchPageLength(functionName, setOpenMessageDialog, setMessage, onSaveColumns);

  const header_columns = [
    { field: 'account_name', headerName: 'Account Name', flex: 1, minWidth: 150 },
    { field: 'branch_name', headerName: 'Branch Name', flex: 1, minWidth: 150 },
    { field: 'asset_name', headerName: 'Asset Name', flex: 1, minWidth: 150 },
    { field: 'asset_type_name', headerName: 'Asset Type', flex: 1, minWidth: 150 },
    { field: 'asset_size_name', headerName: 'Asset Size', flex: 1, minWidth: 150 },
    { field: 'asset_status_name', headerName: 'Asset Status', flex: 1, minWidth: 150 },
    { 
        field: 'status', 
        headerName: 'Status', 
        flex: 1, 
        minWidth: 100, 
        renderCell: (params) => (
            <>
                {params.value === 0 ? (
                    <Typography>In store</Typography>
                ) : (
                    <Typography color='error'>Pulled out</Typography>
                )}
            </>
        ) 
    },
  ];

  useEffect(() => {
    const getStoreAssets = async () => {
      setLoading(true);
      try {
        const options = {
          method: 'POST',
          url: '/StoreAssets/getAllStoreAssets.json',
        };
        const response = await axios(options);
        const data = response.data;
        
        setStoreAssetsData(data);
        setLoading(false);
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(`${error.toJSON().message}. Please try again and make sure you have an internet connection.`);
      }
    };

    getStoreAssets();
  }, []);

  const columns = useFetchColumns(functionName, onSaveColumns, setOpenMessageDialog, setMessage);
  const filteredAndOrderedColumns = useFilteredAndOrderedColumns(columns, header_columns);
  const [sortModel, setSortModel] = useSortModel(columns);

  const handleClickAllData = async () => {
    if (!selectAll) {
      try {
        setLoading(true);
        const options = {
          method: 'POST',
          url: '/StoreAssets/getAllStoreAssetsIds.json',
        };
        const response = await axios(options);
        
        const allDataID = response.data.map((row) => row.id);
        
        setRowSelectionModel(allDataID);
        setSelectAll(true);
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(`${error.toJSON().message}. Please try again and make sure you have an internet connection.`);
      } finally {
        setLoading(false);
      }
    } else {
      setRowSelectionModel([]);
      setSelectAll(false);
    }
  };

  return (
    <>
      <Box sx={{ marginBottom: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {/* Spacer */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Settings Icon and Menu */}
        <Box sx={{ position: 'relative' }}>
          <ColumnSettingsButton
            columns={columns}
            setOnSaveColumns={setOnSaveColumns}
            functionName={functionName}
            paginationModel={paginationModel}
          />
        </Box>
      </Box>
      <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
        <DataGrid
          keepNonExistentRowsSelected
          rows={storeAssetsData}
          columns={filteredAndOrderedColumns}
          getRowHeight={() => 'auto'}
          pageSizeOptions={pageSizeOptions}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          sortingOrder={['asc', 'desc']}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
            toolbar: () => <CustomToolbar
              selectedRows={rowSelectionModel}
              columns={columns}
              setOnSaveColumns={setOnSaveColumns}
              functionName={functionName}
              modelTable={modelTable}
              handleClickAllData={handleClickAllData}
              selectAll={selectAll}
            />,
            footer: () => <CustomGlobalFooter selectedRows={rowSelectionModel.length}/>
          }}
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          disableRowSelectionOnClick
          autoHeight
          loading={loading}
          sx={{
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '15px',
            },
            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
              py: '22px',
            },
            '& .MuiDataGrid-columnHeaderCheckbox': {
              opacity: 0.5, 
              pointerEvents: 'none',
              cursor: 'not-allowed'
            }
          }}
        />
      </Box>

      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose={() => setOpenMessageDialog(false)}
          message={message}
        />
      </StyledEngineProvider>
    </>
  );
};

export default StoreAssets;
