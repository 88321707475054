import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/AuthProvider';
import { CommentsProvider } from './context/CommentsProvider';
import { ViewAssetProvider } from './context/ViewAssetProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <BrowserRouter basename={process.env.PUBLIC_URL}>
   <AuthProvider>
    <CommentsProvider>
      <ViewAssetProvider>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </ViewAssetProvider>
    </CommentsProvider>
   </AuthProvider>
 </BrowserRouter>
</React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
