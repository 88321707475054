import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Button, Typography, Alert } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from './ProcessDialog';

const ColumnSettingsModal = ({ open, onClose, columns, setOnSaveColumns, functionName, paginationModel }) => {
  const axios = AxiosCustomConfig();
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [pageLength, setPageLength] = useState(paginationModel.pageSize);
  const [showProgress, setShowProgress] = useState(false);

  useEffect(() => {
    setPageLength(paginationModel.pageSize);
  }, [paginationModel.pageSize]);

  useEffect(() => {
    // Separate columns into visible and hidden lists based on is_disabled
    const visible = columns.filter(column => !column.is_disabled);
    const hidden = columns.filter(column => column.is_disabled);
    setVisibleColumns(visible);
    setHiddenColumns(hidden);
  }, [columns]);

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;

    let sourceList = source.droppableId === 'visibleColumns' ? visibleColumns : hiddenColumns;
    let destinationList = destination.droppableId === 'visibleColumns' ? visibleColumns : hiddenColumns;

    const [movedColumn] = sourceList.splice(source.index, 1);
    destinationList.splice(destination.index, 0, movedColumn);

    // Update state based on where the column was dropped
    if (source.droppableId === 'visibleColumns' && destination.droppableId === 'hiddenColumns') {
      movedColumn.is_disabled = 1; // Mark as hidden
    } else if (source.droppableId === 'hiddenColumns' && destination.droppableId === 'visibleColumns') {
      movedColumn.is_disabled = 0; // Mark as visible
    }

    setVisibleColumns([...visibleColumns]);
    setHiddenColumns([...hiddenColumns]);
  };

  const handleSave = async () => {
    const updatedColumns = [...visibleColumns, ...hiddenColumns];
    onClose();
    try {
      setShowProgress(true);
      const options = {
        method: 'POST',
        url: '/CsvColumnSettings/saveColumns.json',
        data: {
          field_list: updatedColumns,
          function_name: functionName,
          page_length: pageLength,
        },
      };
      const response = await axios(options);
      const data = response.data;

      if (data) {
        setOnSaveColumns((prev) => !prev);
      } else {
        console.error('Failed to save columns:', response.statusText);
      }
    } catch (error) {
      console.error('Error saving columns:', error);
    } finally {
      setShowProgress(false);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Listing Configuration</DialogTitle>
        <DialogContent>
          <Alert severity="info">
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography variant="caption">Notes:</Typography>
              <Typography variant="caption">Just move fields between the boxes to include or exclude fields in listing</Typography>
            </Box>
          </Alert>

          <Box sx={{ marginTop: 4, marginBottom: 4 }}>
            <Typography variant="caption">Customize Page Length (1-100):</Typography>
            <input
              type="number"
              min="1"
              max="100"
              value={pageLength}
              onChange={(e) => {
                const value = Number(e.target.value);
                if (value >= 1 && value <= 100) {
                  setPageLength(value);
                }
              }}
              style={{ width: '100%', padding: '8px', marginTop: '8px', borderRadius: '4px', border: '1px solid #ddd' }}
            />
          </Box>

          <DragDropContext onDragEnd={onDragEnd}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
              {/* Visible Columns */}
              <Droppable droppableId="visibleColumns">
                {(provided) => (
                  <Box ref={provided.innerRef} {...provided.droppableProps} sx={{ width: '45%', border: '1px solid #ddd', padding: 2 }}>
                    <Typography variant="subtitle1">Fields in listing</Typography>
                    {visibleColumns.map((column, index) => (
                      <Draggable key={column.key} draggableId={column.key} index={index}>
                        {(provided) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              padding: '8px',
                              marginBottom: '8px',
                              backgroundColor: '#fff',
                              border: '1px solid #ddd',
                              borderRadius: '4px',
                              cursor: 'grab',
                            }}
                          >
                            <Typography variant="body2">{column.value}</Typography>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>

              {/* Hidden Columns */}
              <Droppable droppableId="hiddenColumns">
                {(provided) => (
                  <Box ref={provided.innerRef} {...provided.droppableProps} sx={{ width: '45%', border: '1px solid #ddd', padding: 2 }}>
                    <Typography variant="subtitle1">Excluded from listing</Typography>
                    {hiddenColumns.map((column, index) => (
                      <Draggable key={column.key} draggableId={column.key} index={index}>
                        {(provided) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              padding: '8px',
                              marginBottom: '8px',
                              backgroundColor: '#fff',
                              border: '1px solid #ddd',
                              borderRadius: '4px',
                              cursor: 'grab',
                            }}
                          >
                            <Typography variant="body2">{column.value}</Typography>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </Box>
          </DragDropContext>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color="secondary">Cancel</Button>
          <Button onClick={handleSave} variant="contained" color="primary">Save</Button>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..." />
    </>
  );
};

export default ColumnSettingsModal;
