import { useTheme } from '@emotion/react';
import { Notifications } from '@mui/icons-material';
import { Badge, Box, Button, ClickAwayListener, Divider, IconButton, List, ListItemButton, ListItemText, Typography, Grid, Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';

function NotificationsUtil() {
  const axios = AxiosCustomConfig();
  const theme = useTheme();
  const [notifications, setNotifications] = useState(0);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifData, setNotifData] = useState([]);
  const [visibleNotifications, setVisibleNotifications] = useState(3);
  const [isSeen, setIsSeen] = useState(false);

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  const handleClickAway = () => {
    setShowNotifications(false)
  }


  const handleLinktoButton = (e, id, linkto) => {
    e.preventDefault();

    updateIsSeenNotif(id)
    window.open(`${linkto}`, '_blank');
  }

  const updateIsSeenNotif = async (id) => {
    try {
      const options = {
        method: 'POST',
        url: '/Notifications/updateIsSeenNotif.json',
        data: {id}
      };
      const response = await axios(options);
      const resData = response.data;
      if (resData.is_success) {
        setIsSeen(prev => !prev);
      }
    } catch (error) {
      console.log(`${error.toJSON().message}`);
    }
  };

  const loadMoreNotifications = async () => {
    try {
      const options = {
        method: 'POST',
        url: '/Notifications/getNotifications.json',
        data: {
          offset: visibleNotifications,
          limit: 3 // Fetch 3 more notifications
        }
      };
      const response = await axios(options);
      const newData = response.data.data;
      setNotifData([...notifData, ...newData]); // Append new data to existing data
      setVisibleNotifications(visibleNotifications + 3); // Increase visible notifications by 3
    } catch (error) {
      console.log(`${error.toJSON().message}`);
    }
  };

  const markAsReadNotifications = async () => {
    try {
      const options = {
        method: 'POST',
        url: '/Notifications/markAsReadNotif.json',
      };
      const response = await axios(options);
      const resData = response.data;
      if (resData.is_success) {
        setIsSeen(prev => !prev);
      }
    } catch (error) {
      console.log(`${error.toJSON().message}`);
    }
  };

  const getNotification = async (ignore) => {
    try {
      const options = {
        'method': 'POST',
        'url' : '/Notifications/getNotifications.json',
        data: {
          offset: 0,
          limit: 3 // Fetch 3 more notifications
        }
      }
      const response = await axios(options);
      const resData = response.data;
      if (!ignore) {
        setNotifData(resData.data)
        setNotifications(resData.new_notif_count);
      }
      
    } catch (error) {
      console.log(`${error.toJSON().message}`);
    }
  }

  useEffect(() => {
    let ignore = false;
    getNotification(ignore);

    return () => {
      ignore = true;
    }
  }, [isSeen]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div style={{marginLeft: 'auto'}}>
          <IconButton
            color="inherit"
            aria-label="notifications"
            onClick={toggleNotifications}
          >
            <Badge badgeContent={notifications} color="secondary">
              <Notifications />
            </Badge>
          </IconButton>
          {showNotifications && (
            <Box sx={{ 
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                top: '100%',
                right: '0',
                width: '300px', // Adjust width as needed
                backgroundColor: theme.palette.background.paper,
                zIndex: 9999,
                boxShadow: theme.shadows[2],
                mt: 1,
                mr: 1,
              }}
            >
              <Box sx={{
                  overflow: 'auto',
                  maxHeight: '500px', // Adjust max height as needed
                  flexGrow: 1,
                }}>
                <List>
                  {notifData.slice(0, visibleNotifications).map((notification, index) => (
                    <React.Fragment key={index}>
                      <ListItemButton
                        sx={{
                          color: 'black',
                          backgroundColor: notification.is_seen === 0 ? '#ffeaa7' : 'transparent',
                        }}
                        onClick={(event) => handleLinktoButton(event, notification.id, notification.link_to)}
                        style={{ touchAction: 'manipulation' }}
                      >
                        <ListItemText
                          primary={notification.type_name}
                          primaryTypographyProps={{
                            fontSize: 12,
                            fontWeight: 'medium',
                            letterSpacing: 0,
                          }}
                          secondary={
                            <Typography
                              sx={{ display: 'inline', fontSize: 12 }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              {notification.from_user_name} — {notification.action}
                            </Typography>
                          }
                          secondaryTypographyProps={{
                            fontSize: 12,
                            fontWeight: 'medium',
                            letterSpacing: 0,
                          }}
                        />
                      </ListItemButton>
                      <Divider sx={{ color: 'black' }} component="li" />
                    </React.Fragment>
                  ))}
                </List>
              </Box>
              <Box sx={{ textAlign: 'center', mb: 1 }}>
                <Button onClick={loadMoreNotifications}>Load More</Button>
              </Box>
              <Box sx={{ textAlign: 'center', mb: 1 }}>
                <Button onClick={markAsReadNotifications}>Mark as read</Button>
              </Box>
            </Box>
          )}
      </div>
    </ClickAwayListener>
  )
}

export default NotificationsUtil