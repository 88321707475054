import React, { createContext, useState } from 'react'

const ViewAssetContext = createContext({});

export const ViewAssetProvider = ({children}) => {
  const [doneUpdate, setDoneUpdating] = useState(false);

  return (
      <ViewAssetContext.Provider value={{ doneUpdate, setDoneUpdating }}>
          {children}
      </ViewAssetContext.Provider>
  )
}

export default ViewAssetContext