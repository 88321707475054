import { Pagination as MuiPagination } from '@mui/material';
import { useGridApiContext, useGridSelector, gridPageCountSelector, GridPagination } from '@mui/x-data-grid';

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  
  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount || 1}  // Ensure count is at least 1
      page={(page !== undefined ? page : 0) + 1}  // Ensure page is at least 1
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
      siblingCount={4}
      boundaryCount={1}
    />
  );
}

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

export default CustomPagination;
