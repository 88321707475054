import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField, Typography } from '@mui/material';
import React, {useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';
import styled from '@emotion/styled';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import useAuth from '../../hooks/UseAuth';
import useViewAssetContext from '../../hooks/UseViewAssetContext';
import useCommentsContext from '../../hooks/UseCommentsContext';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { format, subMonths, startOfMonth } from 'date-fns';


const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

const generateDateOptions = (startYear, endYear) => {
  const endDate = new Date(endYear, new Date().getMonth(), 1); // Current month of the end year
  const startDate = new Date(startYear, 0, 1); // January 1st of the start year

  const dateOptions = [];
  let currentDate = endDate;

  while (currentDate >= startDate) {
    dateOptions.push({ label: format(currentDate, 'MMM yyyy'), value: format(currentDate, 'MMM yyyy') });
    currentDate = subMonths(currentDate, 1);
  }

  return dateOptions;
};

function SetAuditDate({open, setOpen, assetsData}) {
  const {setDoneUpdating} = useViewAssetContext();
  const {setDoneUpdate} = useCommentsContext();
  const axios = AxiosCustomConfig();
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [assetStatus, setAssetStatus] = useState([]);
  const [openAssetStatus, setOpenAssetStatus] = useState(false);
  const [loadingAssetStatus, setLoadingAssetStatus] = useState(false);
  const [assetSize, setAssetSize] = useState([]);
  const [openAssetSize, setOpenAssetSize] = useState(false);
  const [loadingAssetSize, setLoadingAssetSize] = useState(false);
  const [warehouseData, setWarehouseData] = useState({
    asset_code: '',
    asset_status_array: [],
    status_name: '',
    audit_period_array: [],
    audit_period: '',
    status_unique_code: '',
  });

  const dateOptions = generateDateOptions(2020, new Date().getFullYear());

  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    setShowProgress(true);
    try {
        const options = {
            method: 'POST',
              url: '/AssetsRegistry/updateAuditDate.json',
              data: objectToFormData(warehouseData),
        }

        const response = await axios(options);
        const {is_success, message} = response.data;

        setShowProgress(false);

        if (is_success) {
          setDoneUpdating((prev) => !prev)
          setDoneUpdate((prev) => !prev)
          setOpenMessageDialog(true);
          setMessage(message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. There's something wrong with the server.`);
    }
  }

  const resetForm = () => {
    setWarehouseData({
      asset_code: '',
      asset_status_array: [],
      status_name: '',
      audit_period: '',
      status_unique_code: '',
      audit_period_array: [],
    });
  };

  const getAssetStatus = async (ignore) => {
    try {
        setLoadingAssetStatus(true)
        const options = {
            method: 'POST',
            url: '/AssetStatus/getAssetStatusNameAndCode.json',
        }

        const response = await axios(options);
        if (!ignore) {
          setAssetStatus(response.data);
          setLoadingAssetStatus(false)
        }
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getAssetSizes = async (ignore) => {
    try {

      setLoadingAssetSize(true)
      const options = {
          method: 'POST',
          url: '/AssetSizes/getAssetSizesForRequest.json',
      }
      const response = await axios(options);

      if(!ignore) {
        setAssetSize(response.data);
        setLoadingAssetSize(false)
      }

    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(()=>{
    let ignore = false;

    if (!openAssetStatus) return undefined
    getAssetStatus(ignore);

    return () => {
      ignore = true;
    }
  }, [openAssetStatus]);

  useEffect(()=>{
    let ignore = false;

    if (!openAssetSize) return undefined
    getAssetSizes(ignore);

    return () => {
      ignore = true;
    }
  }, [openAssetSize]);

  useEffect(() => {
    if (open && assetsData) {
      const { equipment_no, asset_status, audit_period, audit_comment, asset_status_unique_code} = assetsData;
      setWarehouseData({
        asset_status_array: {'label' : asset_status, value: asset_status},
        audit_period_array: {'label' : audit_period, value: audit_period},
        asset_code: equipment_no,
        status_name: asset_status,
        audit_period: audit_period,
        audit_comment: audit_comment,
        status_unique_code: asset_status_unique_code,
      });
    }

  }, [open, assetsData]);

  const handleAssetStatusChange = (e, selectValue) => {
    if (selectValue) {
      let asset_status = selectValue.asset_status;
      let asset_status_unique_code = selectValue.asset_status_unique_code;
      setWarehouseData((prevAccount) => ({
        ...prevAccount, 
        status_name: asset_status, 
        status_unique_code: asset_status_unique_code, 
        asset_status_array: selectValue
      }))
    }
  }

  const handleAuditPeriodChange = (e, selectValue) => {
    if (selectValue) {
      let audit_period = selectValue.label;
      setWarehouseData((prevAccount) => ({
        ...prevAccount, 
        audit_period: audit_period, 
        audit_period_array: selectValue
      }))
    }
  }


  return (
    <div>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Set audit date</DialogTitle>
      <DialogContent>
          <Grid container spacing={3} mt={1}>
            <Grid item xs={12}>
              <FormControl fullWidth>
              <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  open={openAssetStatus}
                  onOpen={() => {setOpenAssetStatus(true);}}
                  onClose={() => {setOpenAssetStatus(false);}}
                  loading={loadingAssetStatus}
                  onChange={handleAssetStatusChange}
                  options={assetStatus}
                  value={warehouseData.asset_status_array}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => (option && value && option.label === value.label)}
                  renderInput={(params) => <TextField {...params} label="Asset Status" />}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  onChange={handleAuditPeriodChange}
                  options={dateOptions}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => (option && value && option.label === value.label)}
                  renderInput={(params) => <TextField {...params} label="Audit Period" />}
                  value={warehouseData.audit_period_array}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="audit_comment"
                  label="Audit comment"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setWarehouseData((prevAccount) => ({ ...prevAccount, audit_comment: e.target.value }))}
                  value={warehouseData.audit_comment}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => {
              setOpenMessageDialog(false)
              setOpen(false)
              }}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default SetAuditDate