import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from '@mui/material'
import React from 'react'

export default function CustomModal(props) {
    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                maxWidth={props.maxWidth}
                >
                {props.isForm ? (
                    <>
                        <DialogTitle>{props.title}</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                {props.formElements.map((element, index) => (
                                    <Grid item sx={{mt: 1}} xs={12} key={index}>
                                        {element}
                                    </Grid>
                                ))}
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="contained"
                                onClick={props.handleClose}>
                                Close
                            </Button>
                            <Button
                                color="success"
                                variant="contained"
                                onClick={props.onSubmit}>
                                Save
                            </Button>
                        </DialogActions>
                    </>
                ) : (
                    <>
                        <DialogContent>
                            <Typography variant="h5">
                                { props.contentText }
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                onClick={props.handleClose}>
                                Close
                            </Button>
                            <Button
                                color="error"
                                variant="contained"
                                onClick={props.onSubmit}>
                                { props.submitText }
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </div>
    )
}
