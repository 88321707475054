import { Autocomplete, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, NativeSelect, StyledEngineProvider, TextField, Typography } from '@mui/material';
import React, {useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { objectToFormData } from '../GenericFunctions/GenericFunctions';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import styled from '@emotion/styled';

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

function ChangeStatus({open, setOpen, requestDetails, setDoneUpdate}) {
  const axios = AxiosCustomConfig();
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [changeData, setChangeData] = useState({
    request_code: '',
    shoprepair_status_arr: [],
    status_name: '',
    remarks: '',
  });

  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!changeData.remarks || changeData.remarks === '') {
      setOpenMessageDialog(true);
      setMessage('Remarks is required')
      return
    }
  
    if (!changeData.shoprepair_status_arr.length === 0) {
      setOpenMessageDialog(true);
      setMessage('Asset status is required')
      return
    }



    setShowProgress(true);
    try {
        const options = {
            method: 'POST',
              url: '/ServiceRequests/changeStatus.json',
              data: objectToFormData(changeData),
        }

        const response = await axios(options);
        const {is_success, message} = response.data;

        setShowProgress(false);

        if (is_success) {
          setDoneUpdate((prev) => !prev)
          setOpenMessageDialog(true);
          setMessage(message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. There's something wrong with the server.`);
    }
  }

  const resetForm = () => {
    setChangeData({
      request_code: '',
      shoprepair_status_arr: [],
      status_name: '',
      remarks: '',
    });
  };

  const serviceRequestStatus = [
    { label: 'Processing', id: 0 },
    { label: 'Repaired at site', id: 1 },
    { label: 'Unit for Pullout', id: 2 },
    { label: 'Cancelled', id: 3 },
    { label: 'For Billing', id: 4 },
    { label: 'Invoiced', id: 5 },
  ];


  useEffect(() => {
    if (open && requestDetails) {
      const { request_code, remarks, status } = requestDetails;

      const statusData = serviceRequestStatus.find(item => item.id === status);
      const status_name = statusData ? statusData.label : '';

      setChangeData({
        request_code: request_code,
        remarks: remarks,
        shoprepair_status_arr: { 'label': status_name, id: status },
        status: status,
        status_name: status_name,
      });
    }

  }, [open, requestDetails]);

  const handleStatusChange = (event, newValue) => {
    if (newValue) {
      const { id, label } = newValue;

      // Update the selected status
      setChangeData((prevAccount) => ({
        ...prevAccount,
        shoprepair_status_arr: newValue,
        status: id, 
        status_name: label,
      }))
    }
  };
  
  return (
    <div>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Change Status</DialogTitle>
      <DialogContent>
          <Box sx={{marginBottom: 2, marginTop: 2  }}>
            <Chip label="JOb orders" size="small" color="secondary" />
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <Typography>*Remarks:</Typography>
                <Textarea 
                  aria-label="minimum height" 
                  minRows={3} 
                  placeholder="Remarks" 
                  onChange={(e) => setChangeData((prevAccount) => ({ ...prevAccount, remarks: e.target.value }))}
                  value={changeData.remarks}
                />
              </FormControl>
              {/* <Typography variant="body2" color="initial">Pls provide a reason for unproductive delivery</Typography> */}
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth sx={{ marginTop: 2 }}>
                <Autocomplete
                  disablePortal
                  options={serviceRequestStatus}
                  value={changeData.shoprepair_status_arr}
                  onChange={handleStatusChange}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => <TextField {...params} label="Request Status" />}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => {
              setOpenMessageDialog(false)
              setOpen(false)
              }}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default ChangeStatus