import { useMemo } from 'react';

const useFilteredAndOrderedColumns = (columns, headerColumns) => {
  return useMemo(() => {
    return columns
      .filter(column => column.is_disabled === 0) // Filter out disabled columns
      .sort((a, b) => a.ordering - b.ordering) // Sort by ordering
      .map(col => {
        const headerCol = headerColumns.find(hc => hc.field === col.key);
        if (headerCol) {
          return {
            ...headerCol,
            headerName: col.value,
            sortable: col.is_sortable === 0, // Set sortable based on is_sortable
            sortDirection: col.sort_order === 0 ? 'asc' : 'desc'
          };
        }
        return null; // Handle cases where the header column is not found
      })
      .filter(col => col !== null); // Remove any null values
  }, [columns, headerColumns]);
};

export default useFilteredAndOrderedColumns;
